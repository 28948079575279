import { License } from "./License";

export enum PaymentMethodStatus {
    Active = "Active",
    Inactive = "Inactive",
    Disabled = "Disabled"
}

export class PaymentMethod {
    id:number;
    name: string;
    type: string;
    status: PaymentMethodStatus;
    expiration_date: Date;

    tenant_id: number;
  
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(module){
        this.name = module.name || null;
        this.status = module.status || PaymentMethodStatus.Active;

    }
}