import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Assignment } from '../model/Assignment';
import { Organisation } from '../model/Organisation';
import { Project } from '../model/Project';
import { User } from '../model/User';
import { Task } from '../model/Task';
import { TenantService } from './tenant.service';
import moment, { Moment } from "moment";
import { TimesheetApproval } from '../model/TimesheetApproval';
import { TimeEntry } from '../model/TimeEntry';
import { FavouriteReport } from '../model/FavouriteReport';
import { Report } from '../model/Report';
import { ReportType } from '../model/ReportType';
import { ActiveStatus } from '../model/ActiveStatus';
import { environment } from '../../environments/environment';
import { TimesheetTemplate } from '../model/TimesheetTemplate';
import { TimesheetNotification } from '../model/TimesheetNotification';


const headers = new HttpHeaders({
  'Access-Control-Allow-Origin':'*',
});

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  constructor(private http: HttpClient, public tenantService: TenantService) { }
  //public api_endpoint = environment.apiUrl+ "/" + this.tenantService.encodedTenant + "/"

  public activeStatusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]
  
  public getAllProjects() : Observable<Project[]> {
    return this.http.get<Project[]>(this.tenantService.api_url + "/project",{headers:headers})
  }

  public getProjectsByCriteria(projectFilter:Project): Observable<Project[]> {
    return this.http.post<Project[]>(this.tenantService.api_url + "/project/filter",projectFilter,{headers:headers})
  }

  public createProject(project:Project) : Observable<any> {
    return this.http.post<Project>(this.tenantService.api_url + "/project",project,{headers:headers})
  }

  public updateProject(project:Project) : Observable<any> {
    return this.http.put<Project>(this.tenantService.api_url + "/project",project,{headers:headers})
  }

  public deleteProject(project:Project) : Observable<any> {
    return this.http.delete<Project>(this.tenantService.api_url + "/project/" + project.id,{headers:headers})
  }

  public getAllOrganisations() : Observable<Organisation[]> {
    return this.http.get<Organisation[]>(this.tenantService.api_url + "/organisation",{headers:headers})
  }

  public getOrganisationsByCriteria(organisationFilter:Organisation): Observable<Organisation[]> {
    return this.http.post<Organisation[]>(this.tenantService.api_url + "/organisation/filter",organisationFilter,{headers:headers})
  }

  public createOrganisation(organisation:Organisation) : Observable<any> {
    return this.http.post<Organisation>(this.tenantService.api_url + "/organisation",organisation,{headers:headers})
  }

  public updateOrganisation(organisation:Organisation) : Observable<any> {
    return this.http.put<Organisation>(this.tenantService.api_url + "/organisation",organisation,{headers:headers})
  }

  public deleteOrganisation(organisation:Organisation) : Observable<any> {
    return this.http.delete<Organisation>(this.tenantService.api_url + "/organisation/" + organisation.id,{headers:headers})
  }

  public getAllTasks() : Observable<Task[]> {
    return this.http.get<Task[]>(this.tenantService.api_url + "/task",{headers:headers})
  }

  public getTasksByCriteria(taskFilter:Task): Observable<Task[]> {
    return this.http.post<Task[]>(this.tenantService.api_url + "/task/filter",taskFilter,{headers:headers})
  }

  public createTask(task:Task) : Observable<any> {
    return this.http.post<Task>(this.tenantService.api_url + "/task",task,{headers:headers})
  }

  public updateTask(task:Task) : Observable<any> {
    return this.http.put<Task>(this.tenantService.api_url + "/task",task,{headers:headers})
  }

  public deleteTask(task:Task) : Observable<any> {
    return this.http.delete<Task>(this.tenantService.api_url + "/task/" + task.id,{headers:headers})
  }

  public getAllAssignments() : Observable<Assignment[]> {
    return this.http.get<Assignment[]>(this.tenantService.api_url + "/assignment",{headers:headers})
  }

  public getAssignmentByCriteria(assignmentFilter:Assignment): Observable<Assignment[]> {
    return this.http.post<Assignment[]>(this.tenantService.api_url + "/assignment/filter",assignmentFilter,{headers:headers})
  }

  public createAssignment(assignment:Assignment) : Observable<any> {
    return this.http.post<Assignment>(this.tenantService.api_url + "/assignment",assignment,{headers:headers})
  }

  public updateAssignment(assignment:Assignment) : Observable<any> {
    return this.http.put<Assignment>(this.tenantService.api_url + "/assignment",assignment,{headers:headers})
  }

  public deleteAssignment(assignment:Assignment) : Observable<any> {
    return this.http.delete<Assignment>(this.tenantService.api_url + "/assignment/"+assignment.id,{headers:headers})
  }

  public getAllTimesheets() : Observable<TimesheetApproval[]> {
    return this.http.get<TimesheetApproval[]>(this.tenantService.api_url + "/timesheet-approval",{headers:headers})
  }

  public getTimesheetsByStartDate(startDate: Moment, user_id?:number): Observable<TimesheetApproval[]> {
    return this.http.get<TimesheetApproval[]>(this.tenantService.api_url + `/timesheet-approval/byDate/${startDate.format("YYYY-MM-DD")}${user_id?('/'+user_id):''}`,{headers:headers})
  }

  public deleteTimesheetsByStartDate(startDate: Moment, user_id:number): Observable<any> {
    return this.http.delete<any>(this.tenantService.api_url + `/timesheet-approval/byDate/${startDate.format("YYYY-MM-DD")}/${user_id}`,{headers:headers})
  }

  public getTimesheetsBetweenDates(startDate: Moment, endDate?: Moment, user_id?:number): Observable<TimesheetApproval[]> {
    return this.http.get<TimesheetApproval[]>(this.tenantService.api_url + `/timesheet-approval/betweenDates/${startDate?startDate.format("YYYY-MM-DD"):"none"}/${endDate?(endDate.format("YYYY-MM-DD")):"none"}${user_id?('/'+user_id):''}`,{headers:headers})
  }

  public createTimeEntry(timeEntry:TimeEntry, assignment_id:number) : Observable<any> {
    timeEntry.date.setHours(12)
    return this.http.post<TimeEntry>(this.tenantService.api_url + "/time-entry/"+assignment_id,timeEntry,{headers:headers})
  }

  public deleteTimeEntry(timeEntryId:Number) : Observable<any> {
    return this.http.delete<TimeEntry>(this.tenantService.api_url + "/time-entry/"+timeEntryId,{headers:headers})
  }

  public updateTimeEntry(timeEntry:TimeEntry) : Observable<any> {
    return this.http.put<TimeEntry>(this.tenantService.api_url + "/time-entry",timeEntry,{headers:headers})
  }

  public createTimesheetApproval(timesheetApproval:TimesheetApproval) : Observable<any> {
    return this.http.post<TimesheetApproval>(this.tenantService.api_url + "/timesheet-approval",timesheetApproval,{headers:headers})
  }

  public updateTimesheetApproval(timesheetApproval:TimesheetApproval) : Observable<any> {
    return this.http.put<TimesheetApproval>(this.tenantService.api_url + "/timesheet-approval",timesheetApproval,{headers:headers})
  }

  public getTimesheetApproval(tsAppId:number) : Observable<TimesheetApproval> {
    return this.http.get<TimesheetApproval>(this.tenantService.api_url + "/timesheet-approval/"+tsAppId,{headers:headers})
  }

  public getAllReports() : Observable<Report[]> {
    return this.http.get<Report[]>(this.tenantService.api_url + "/report",{headers:headers})
  }

  public createReport(report:Report) : Observable<any> {
    return this.http.post<Report>(this.tenantService.api_url + "/report",report,{headers:headers})
  }

  public updateReport(report:Report) : Observable<any> {
    return this.http.put<Report>(this.tenantService.api_url + "/report",report,{headers:headers})
  }

  public getAllReportTypes() : Observable<ReportType[]> {
    return this.http.get<ReportType[]>(this.tenantService.api_url + "/report-type",{headers:headers})
  }

  public createReportType(reportType:ReportType) : Observable<any> {
    return this.http.post<ReportType>(this.tenantService.api_url + "/report-type",reportType,{headers:headers})
  }

  public updateReportType(reportType:ReportType) : Observable<any> {
    return this.http.put<ReportType>(this.tenantService.api_url + "/report-type",reportType,{headers:headers})
  }

  public getFavouriteReports(user_id:number) : Observable<FavouriteReport[]> {
    return this.http.get<FavouriteReport[]>(this.tenantService.api_url + "/favorite-report/"+user_id,{headers:headers})
  }

  public createFavouriteReport(favouriteReport:FavouriteReport) : Observable<any> {
    return this.http.post<FavouriteReport>(this.tenantService.api_url + "/favorite-report",favouriteReport,{headers:headers})
  }

  public deleteFavouriteReport(favouriteReport:FavouriteReport) : Observable<any> {
    return this.http.delete<FavouriteReport>(this.tenantService.api_url + "/favorite-report/"+favouriteReport.id,{headers:headers})
  }

  public getAllTemplates() : Observable<TimesheetTemplate[]> {
    return this.http.get<TimesheetTemplate[]>(this.tenantService.api_url + "/timesheet-templates",{headers:headers})
  }

  public getTemplatesByCriteria(templateFilter:TimesheetTemplate): Observable<TimesheetTemplate[]> {
    return this.http.post<TimesheetTemplate[]>(this.tenantService.api_url + "/timesheet-templates/filter",templateFilter,{headers:headers})
  }

  public createTemplate(template:TimesheetTemplate): Observable<TimesheetTemplate> {
    return this.http.post<TimesheetTemplate>(this.tenantService.api_url + "/timesheet-templates",template,{headers:headers})
  }

  public updateTemplate(template:TimesheetTemplate): Observable<TimesheetTemplate> {
    return this.http.put<TimesheetTemplate>(this.tenantService.api_url + "/timesheet-templates",template,{headers:headers})
  }

  public getAllTimesheetNotifications() : Observable<TimesheetNotification[]> {
    return this.http.get<TimesheetNotification[]>(this.tenantService.api_url + "/timesheet-notifications",{headers:headers})
  }

  public getTimesheetNotificationsByApproval(timesheet_approval_id:number) : Observable<TimesheetNotification[]> {
    return this.http.get<TimesheetNotification[]>(this.tenantService.api_url + "/timesheet-notifications/approval/"+timesheet_approval_id,{headers:headers})
  }

  public getTimesheetNotificationsByCriteria(criteria:TimesheetNotification) : Observable<TimesheetNotification[]> {
    return this.http.post<TimesheetNotification[]>(this.tenantService.api_url + "/timesheet-notifications",criteria,{headers:headers})
  }

  public createTimesheetNotification(notification:TimesheetNotification): Observable<TimesheetNotification> {
    return this.http.post<TimesheetNotification>(this.tenantService.api_url + "/timesheet-notifications",notification,{headers:headers})
  }

  public updateTimesheetNotification(notification:TimesheetNotification): Observable<TimesheetNotification> {
    return this.http.put<TimesheetNotification>(this.tenantService.api_url + "/timesheet-notifications",notification,{headers:headers})
  }

  public notifyManagerOfNewSubmission(receivers: User[],link:string, variables:any, sender_id:number, timesheet_approval_id?:number) : Observable<any> {
    let data = {
      receivers:receivers,
      link:link,
      sender_id: sender_id,
      timesheet_approval_id:timesheet_approval_id,
      message: "approval_request",
      timesheetSettings:this.tenantService.timesheetSettings,
      variables: variables
    }
    return this.http.post<any>(this.tenantService.api_url + "/notify",data,{headers:headers})
  }

  public notifyEmployeeOfRejection(receivers: User[], link:string, variables:any, sender_id:number,timesheet_approval_id?:number) : Observable<any> {
    let data = {
      receivers:receivers,
      link:link,
      sender_id: sender_id,
      timesheet_approval_id:timesheet_approval_id,
      message: "rejection_notification",
      timesheetSettings:this.tenantService.timesheetSettings,
      variables:variables
    }
    return this.http.post<any>(this.tenantService.api_url + "/notify",data,{headers:headers})
  }

  public notifySelectedEmployees(receivers: User[],link:string, text, variables:any, sender_id:number,timesheet_approval_id?:number) : Observable<any> {
    let data = {
      receivers:receivers,
      link:link,
      sender_id: sender_id,
      timesheet_approval_id:timesheet_approval_id,
      message:"submission_reminder",
      text,
      timesheetSettings:this.tenantService.timesheetSettings,
      variables:variables
    }
    return this.http.post<any>(this.tenantService.api_url + "/notify", data, {headers:headers})
  }

  public notifySelectedManagers(receivers: User[],link:string, text, variables:any, sender_id:number,timesheet_approval_id?:number) : Observable<any> {
    let data = {
      receivers:receivers,
      link:link,
      sender_id: sender_id,
      timesheet_approval_id:timesheet_approval_id,
      message:"approval_reminder",
      text,
      timesheetSettings:this.tenantService.timesheetSettings,
      variables:variables
    }
    return this.http.post<any>(this.tenantService.api_url + "/notify", data, {headers:headers})
  }
}
