import { ActiveStatus } from "./ActiveStatus";
import { Project } from "./Project";

export class Task {
    id:number;
    task_name:string;
    project_id: number;
    tenant_id: number ;
    notes: string;
    budget_amount:number;
    budget_hours:number;
    chargeable: boolean;
    status: ActiveStatus;

    Project:Project;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(task){
        this.task_name=task.task_name || null;
        this.notes=task.notes || null;
        this.budget_amount = task.budget_amount || null;
        this.budget_hours = task.budget_hours || null;
        this.chargeable = task.chargeable || null;
        this.status = task.status || null;
    }
}