import { Permission } from "./Permission";
import { Role } from "./Role";

export class RolePermission {
    role_id: number;
    permission_id: number;

    Role:Role;
    Permission:Permission;

    can_enable: boolean;
    can_edit: boolean;
    can_add: boolean;
    can_delete: boolean;
    can_view: boolean;
    
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(rolePermission){
        this.role_id = rolePermission.role_id || null;
        this.permission_id = rolePermission.permission_id || null;
        this.can_add = false;
        this.can_delete = false;
        this.can_edit = false;
        this.can_enable = false;
        this.can_view = false;
    }
}