import { License } from "./License";
import { PaymentMethod } from "./PaymentMethod";

export enum BillingRecordStatus {
    Paid = "Paid",
    Pending = "Pending",
    Cancelled = "Cancelled",
    Overdue = "Overdue"
}

export class BillingRecord {
    id:number;
    date:Date;
    invoice_number: string;
    license_id:number;
    License: License;
    document_type:string;
    billed_amount:number;
    due_amount: number;
    status: BillingRecordStatus;
    payment_method_id:number;
    PaymentMethod: PaymentMethod;
    tenant_id: number;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(){
    }
}