import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PaymentMethod } from 'src/app/model/PaymentMethod';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { DialogPaymentMethodDetailsComponent } from '../dialog-payment-method-details/dialog-payment-method-details.component';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  paymentMethods: PaymentMethod[]=[]
  filteredPaymentMethods : PaymentMethod[]=[]
  ref:DynamicDialogRef;
  
  loading: boolean = true;
  
  cols: any[]= [
    { field: 'name', header: 'Name' },
    { field: 'type', header: 'Type Amount' },
    { field: 'status', header: 'Status'},
    { field: 'expiration_date', header: 'Expiration Date' },
  ];

  constructor(private tenantService: TenantService,public messageService:MessageService,
    public dialogService: DialogService, public exportService:ExportService, 
    private breadcrumbService:BreadcrumbService, private userService: UserService){
      this.breadcrumbService.setItems([
        { label: 'Admin'},
        { label: 'Licenses & Billing'},
        { label: 'Payment Methods' }
      ]); }

 ngOnInit(): void {
    this.updatePaymentMethods()
    
  }

  updatePaymentMethods(): void {
    this.loading = true
    this.tenantService.getAllPaymentMethods().subscribe(data=>{
      this.paymentMethods=data;
      this.loading = false;
      ////console.log(data)
    })
  }
  
  addPaymentMethod(){
    this.ref = this.dialogService.open(DialogPaymentMethodDetailsComponent, {
      header: 'Add a PaymentMethod',
      width: '70%'
    });
    this.ref.onClose.subscribe((paymentMethod: PaymentMethod) =>{
      if (paymentMethod) {
        this.paymentMethods.push(paymentMethod)
        this.messageService.add({severity:'info', summary: 'Payment method Created', detail: paymentMethod.name});
      }
    });
  }

  viewDetails(paymentMethod:PaymentMethod){
    this.ref = this.dialogService.open(DialogPaymentMethodDetailsComponent, {
      header: 'Billing Record Details',
      width: '70%',
      data: {
        paymentMethod:paymentMethod
      }
    });
    this.ref.onClose.subscribe((updated_paymentmethod: PaymentMethod) =>{
      if (updated_paymentmethod) {
        var index = this.paymentMethods.indexOf(paymentMethod);
        this.paymentMethods[index]=updated_paymentmethod;
        this.messageService.add({severity:'info', summary: 'PaymentMethod Updated', detail: paymentMethod.name});
      }
    });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.filteredPaymentMethods)
}

  exportExcel() {
    this.exportService.exportExcel(this.filteredPaymentMethods)
  }

  getCompleteFields(){
    return this.cols.map(el=>el.field)
  }
}

