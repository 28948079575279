import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseGuard implements CanActivate {
  constructor(private userService:UserService, private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    let moduleName:string = route.data.module //|| this.router.getCurrentNavigation().extras.state.module
    if (this.userService.checkLicense(moduleName)){
      console.log("license valid")
      return true
    }
    else {
      console.log(`no license (${moduleName})`)
      this.router.navigate(['/unlicensed',{module:moduleName}])
      return false;
    }


  }
  
}
