import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Organisation } from 'src/app/model/Organisation';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { DialogOrganisationDetailsComponent } from '../dialog-organisation-details/dialog-organisation-details.component';
import * as FileSaver from 'file-saver';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportService } from 'src/app/services/export.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { AppComponent } from 'src/app/app.component';
import { UserService } from 'src/app/services/user.service';
import { ActiveStatus } from 'src/app/model/ActiveStatus';


@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss']
})
export class OrganisationsComponent implements OnInit {

  organisations: Organisation[]=[]
  selectedOrganisations: Organisation[]=[]
  filteredOrganisations : Organisation[]=[]
  ref:DynamicDialogRef;

  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  
  loading: boolean = true;
  
  cols: any[]= [
    { field: 'organisation_code', header: 'Organisation Code' },
    { field: 'organisation_name', header: 'Organisation Name' },
    { field: 'organisation_type', header: 'Organisation Type'},
    { field: 'status', header: 'Status' },
  ];
  exportColumns: any[];
  organisationTypesOptions:string[] = ["Suppliers", "Clients", "All"]
  selectedOrganisationType:string = "Clients"

  constructor(private timesheetService: TimesheetService,public messageService:MessageService,
    public dialogService: DialogService, public tenantService: TenantService, public exportService:ExportService, 
    private breadcrumbService:BreadcrumbService, private userService: UserService){
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/dashboard']},
        { label: 'Admin'},
        { label: 'Organisations' }
      ]); }

 ngOnInit(): void {
    this.canEdit = this.userService.checkPermission('Organisations', 'edit')
    this.canAdd = this.userService.checkPermission('Organisations', 'add')
    this.canDelete = this.userService.checkPermission('Organisations', 'delete')

    this.updateOrganisations()
    this.cols.forEach(el=>el.completeField = el.nestedField?el.field+'.'+el.nestedField:el.field)

  }

  filterByOrganisationType(){
    // console.log("selected type",this.selectedOrganisationType)
    if (this.selectedOrganisationType=="Suppliers") {this.filteredOrganisations = this.organisations.filter(cont=>cont.is_supplier==true)}
    else if (this.selectedOrganisationType=="Clients") {this.filteredOrganisations = this.organisations.filter(cont=>cont.is_client==true)}
    else {this.filteredOrganisations = this.organisations}
    // console.log(this.filteredOrganisations)
  }

  updateOrganisations(): void {
    this.loading = true
    this.timesheetService.getAllOrganisations().subscribe(data=>{
      this.organisations=data;
      this.filterByOrganisationType()
      this.loading = false;
      ////console.log(data)
    })
  }
  
  addOrganisation(){
    this.ref = this.dialogService.open(DialogOrganisationDetailsComponent, {
      header: 'Add a Organisation',
      width: '70%'
    });
    this.ref.onClose.subscribe((organisation: Organisation) =>{
      if (organisation) {
        this.organisations.push(organisation)
        this.filterByOrganisationType()
        this.messageService.add({severity:'success', summary: 'Organisation Created', detail: organisation.organisation_name});
      }
    });
  }

  editOrganisation(organisation:Organisation){
    this.ref = this.dialogService.open(DialogOrganisationDetailsComponent, {
      header: 'Organisation Details',
      width: '70%',
      data: {
        organisation:organisation
      }
    });
    this.ref.onClose.subscribe((updated_organisation: Organisation) =>{
      if (updated_organisation) {
        var index = this.organisations.indexOf(organisation);
        this.organisations[index]=updated_organisation;
        this.filterByOrganisationType()
        this.messageService.add({severity:'success', summary: 'Organisation Updated', detail: organisation.organisation_name});
      }
    });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  getOrganisationTypeString(organisation:Organisation):string {
    if (organisation.is_client && organisation.is_supplier) return "Client, Supplier"
    else if (organisation.is_client) return "Client"
    else if (organisation.is_supplier) return "Supplier"
    else return ""
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.filteredOrganisations, "Organisations")
}

  exportExcel() {
    this.exportService.exportExcel(this.filteredOrganisations)
  }

  getCompleteFields(){
    return this.cols.map(el=>el.completeField)
  }

  setAsActive(){
    this.updateStatus(ActiveStatus.Active)
  }
  setAsInactive(){
    this.updateStatus(ActiveStatus.Inactive)
  }

  updateStatus(status:ActiveStatus){
    let promises = this.selectedOrganisations.map(org=>{
      org.status = status
      return new Promise(resolve => this.timesheetService.updateOrganisation(org).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Task(s) successfully updated'})) 
  }

  delete(){
    let promises = this.selectedOrganisations.map(org=>{
      return new Promise(resolve => this.timesheetService.deleteOrganisation(org).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Task(s) successfully deleted'})) 
  }
}
