import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment as env } from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/navigation/header/header.component';
import { ProjectsComponent } from './components/company-admin/projects/projects.component';
import { UsersComponent } from './components/company-admin/users/users.component';
import { TasksComponent } from './components/company-admin/tasks/tasks.component';
import { AssignmentsComponent } from './components/company-admin/assignments/assignments.component';
import { TimesheetComponent } from './components/timesheet/timesheet.component';
import { TenantsComponent } from './components/ecs-admin/tenants/tenants.component';
import { ErrorComponent } from './components/navigation/error/error.component';
import { DialogTenantDetailsComponent } from './components/ecs-admin/dialog-tenant-details/dialog-tenant-details.component';
import { DialogProjectDetailsComponent } from './components/company-admin/dialog-project-details/dialog-project-details.component';
import { DialogUserDetailsComponent } from './components/company-admin/dialog-user-details/dialog-user-details.component';
import { DialogAssignmentDetailsComponent } from './components/company-admin/dialog-assignment-details/dialog-assignment-details.component';
import { DialogTaskDetailsComponent } from './components/company-admin/dialog-task-details/dialog-task-details.component';
import { DialogTimesheetDetailsComponent } from './components/timesheet/dialog-timesheet-details/dialog-timesheet-details.component';
import { OrganisationsComponent } from './components/company-admin/organisations/organisations.component';
import { DialogOrganisationDetailsComponent } from './components/company-admin/dialog-organisation-details/dialog-organisation-details.component';


//NGPrime
import {ToastModule} from 'primeng/toast';
import {TabMenuModule} from 'primeng/tabmenu';
import {TooltipModule} from 'primeng/tooltip';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {InputNumberModule} from 'primeng/inputnumber';
import {MenubarModule} from 'primeng/menubar';
import {DropdownModule} from 'primeng/dropdown';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {CardModule} from 'primeng/card';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AutoCompleteModule} from 'primeng/autocomplete'
import { CalendarModule } from 'primeng/calendar';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {SelectButtonModule} from 'primeng/selectbutton';
import { ConfirmationService, MessageService } from 'primeng/api';
import {MultiSelectModule} from 'primeng/multiselect'
import {ToolbarModule} from 'primeng/toolbar';
import {DialogModule} from 'primeng/dialog';
import {MegaMenuModule} from 'primeng/megamenu';
import {ProgressBarModule} from 'primeng/progressbar';
import {ChartModule} from 'primeng/chart';
import {AccordionModule} from 'primeng/accordion';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {StepsModule} from 'primeng/steps'
import {FileUploadModule} from 'primeng/fileupload';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {DividerModule} from 'primeng/divider';
import {ImageModule} from 'primeng/image';
import {PanelModule} from 'primeng/panel';
import {ToggleButtonModule} from 'primeng/togglebutton'
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MenuModule} from 'primeng/menu'

//Auth
import { AuthHttpInterceptor,AuthModule } from '@auth0/auth0-angular';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { ReportsComponent } from './components/reports/reports.component';
import { UserSettingsComponent } from './components/settings/user-settings/user-settings.component';
import { ReportPreviewComponent } from './components/reports/report-preview/report-preview.component';
import { GeneralComponent } from './components/settings/tenant-settings/general/general.component';
import { TimeEntryComponent } from './components/timesheet/timesheet-settings/time-entry/time-entry.component';
import { CheckTimesheetsComponent } from './components/timesheet/check-timesheets/check-timesheets.component';
import { CheckManagersComponent } from './components/timesheet/check-managers/check-managers.component';
import { TimesheetApprovalComponent } from './components/timesheet/timesheet-approval/timesheet-approval.component';
import { AdminDashboardComponent } from './components/company-admin/admin-dashboard/admin-dashboard.component';
import { FooterComponent } from './components/navigation/footer/footer.component';
import { CustomizeReportComponent } from './components/reports/customize-report/customize-report.component';
import { SystemComponent } from './components/settings/tenant-settings/system/system.component';
import { AddReportComponent } from './components/reports/add-report/add-report.component';
import { BreadcrumbComponent } from './components/navigation/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './services/breadcrumb.service';
import { ImportComponent } from './components/timesheet/import/import.component';
import { RolesComponent } from './components/settings/tenant-settings/roles/roles.component';
import { TitleCasePipe } from '@angular/common';
import { PaymentMethodsComponent } from './components/settings/tenant-settings/account-billing/payment-methods/payment-methods.component';
import { BillingNotificationsComponent } from './components/settings/tenant-settings/account-billing/billing-notifications/billing-notifications.component';
import { BillingHistoryComponent } from './components/settings/tenant-settings/account-billing/billing-history/billing-history.component';
import { LicensesComponent } from './components/settings/tenant-settings/account-billing/licenses/licenses.component';
import { DialogPaymentMethodDetailsComponent } from './components/settings/tenant-settings/account-billing/dialog-payment-method-details/dialog-payment-method-details.component';
import { DialogLicenseDetailsComponent } from './components/settings/tenant-settings/account-billing/dialog-license-details/dialog-license-details.component';
import { DialogBillingRecordDetailsComponent } from './components/settings/tenant-settings/account-billing/dialog-billing-record-details/dialog-billing-record-details.component';
import { TemplatesComponent } from './components/timesheet/timesheet-settings/templates/templates.component';
import { NotificationsComponent } from './components/timesheet/timesheet-settings/notifications/notifications.component';
import { NotificationsHistoryComponent } from './components/timesheet/notifications-history/notifications-history.component';
import { UnauthorizedComponent } from './components/navigation/unauthorized/unauthorized.component';
import { UnlicensedComponent } from './components/navigation/unlicensed/unlicensed.component';
import { DialogTemplateDetailsComponent } from './components/timesheet/timesheet-settings/templates/dialog-template-details/dialog-template-details.component';
import { SideMenuComponent } from './components/navigation/menus/side-menu/side-menu.component';
import { MenuItemComponent } from './components/navigation/menus/menu-item/menu-item.component';
import { CurrenciesComponent } from './components/settings/tenant-settings/currencies/currencies.component';
import { BatchPrintComponent } from './components/timesheet/batch-print/batch-print.component';
import { TimesheetDashboardComponent } from './components/timesheet/timesheet-dashboard/timesheet-dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    ProjectsComponent,
    UsersComponent,
    HeaderComponent,
    TasksComponent,
    AssignmentsComponent,
    TimesheetComponent,
    TenantsComponent,
    ErrorComponent,
    DialogTenantDetailsComponent,
    DialogProjectDetailsComponent,
    DialogUserDetailsComponent,
    DialogAssignmentDetailsComponent,
    DialogTaskDetailsComponent,
    DialogTimesheetDetailsComponent,
    OrganisationsComponent,
    DialogOrganisationDetailsComponent,
    ReportsComponent,
    UserSettingsComponent,
    ReportPreviewComponent,
    GeneralComponent,
    TimeEntryComponent,
    CheckTimesheetsComponent,
    CheckManagersComponent,
    TimesheetApprovalComponent,
    AdminDashboardComponent,
    FooterComponent,
    CustomizeReportComponent,
    SystemComponent,
    AddReportComponent,
    BreadcrumbComponent,
    ImportComponent,
    RolesComponent,
    LicensesComponent,
    BillingHistoryComponent,
    PaymentMethodsComponent,
    BillingNotificationsComponent,
    DialogPaymentMethodDetailsComponent,
    DialogLicenseDetailsComponent,
    DialogBillingRecordDetailsComponent,
    TemplatesComponent,
    NotificationsComponent,
    NotificationsHistoryComponent,
    UnauthorizedComponent,
    UnlicensedComponent,
    DialogTemplateDetailsComponent,
    SideMenuComponent,
    MenuItemComponent,
    CurrenciesComponent,
    BatchPrintComponent,
    TimesheetDashboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule ,
    FormsModule,

    //NGPrime
    TabMenuModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    InputNumberModule,
    MenubarModule,
    DropdownModule,
    DynamicDialogModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    CardModule,
    RadioButtonModule,
    AutoCompleteModule,
    CalendarModule,
    InputTextareaModule,
    SelectButtonModule,
    MultiSelectModule,
    ToolbarModule,
    DialogModule,
    MegaMenuModule,
    ProgressBarModule,
    ChartModule,
    AccordionModule,
    BreadcrumbModule,
    StepsModule,
    FileUploadModule,
    CheckboxModule,
    ChipModule,
    DividerModule,
    ImageModule,
    PanelModule,
    ToggleButtonModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    MenuModule,

    //Auth
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
  ],
  providers: [
    AppComponent,
    DialogService,
    MessageService,
    ConfirmationService,
    BreadcrumbService,
    TitleCasePipe,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  },
  {
    provide: Window,
    useValue: window,
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
