<p-toast></p-toast>
<div class="card">
    <form [formGroup]="createForm" class="formgrid grid" (ngSubmit)="onSubmit(createForm.value)">   
        <div class="field col-12 md:col-5">
            <label>Project: </label>
            <p-dropdown styleClass="w-full" [options]="projects" formControlName="project_id" optionLabel="project_name" optionValue="id" [filter]="true" filterBy="project_name, project_number"
                 placeholder="Select..." name="task_id"></p-dropdown>
            <div *ngIf="project_id.invalid && (project_id.dirty || project_id.touched)" class="alert alert-danger">
                <div *ngIf="project_id.errors?.required">Project is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Task Name: </label>
            <input type="text" pInputText class="w-full" formControlName="task_name" name="task_name"/>
            <div *ngIf="task_name.invalid && (task_name.dirty || task_name.touched)" class="alert alert-danger">
                <div *ngIf="task_name.errors?.required">Task name is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-10">
            <label>Reference: </label>
            <input type="text" pInputText class="w-full" formControlName="reference" name="reference"/>
        </div>
        <div class="field col-12 md:col-5">
            <label>Budget Amount: </label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                <p-inputNumber formControlName="budget_amount" class="w-full" mode="currency" currency="AUD" maxFractionDigits="2"></p-inputNumber>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Budget Hours: </label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-clock"></i></span>
                <p-inputNumber formControlName="budget_hours" mode="decimal" class="w-full" maxFractionDigits="1"></p-inputNumber>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Status: </label>
            <p-selectButton [options]="statusOptions" formControlName="status"></p-selectButton>
        </div>
        <div class="col-12 md:col-5">
            <label>Chargeable: </label>
            <p-toggleButton  formControlName="chargeable" class="w-full" styleClass="mb-4" onLabel="Yes" offLabel="No"></p-toggleButton>
        </div>
        <div class="field col-12 md:col-10">
            <label>Notes: </label>
            <textarea [rows]="2" [cols]="30" pInputTextarea class="w-full" formControlName="notes"></textarea>
        </div>

    </form>
</div>

<button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
<button type="submit" (click)="onSubmit(createForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 