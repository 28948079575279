import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import jsPDFInvoiceTemplate, { OutputType } from "jspdf-invoice-template";
import { TenantService } from './tenant.service';
import jsPDF from "jspdf";
import "jspdf-autotable";

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(public tenantService: TenantService) { }

  exportPdfWithLogo(columns,data, report_name = "Export") {
    const doc = new jsPDF('p','pt');
    doc.setFontSize(25);
    let width = 15
    if (this.tenantService.currentTenant.Logo){
      console.log("adding logo")
      let logo_ratio = this.tenantService.currentTenant.Logo.width/this.tenantService.currentTenant.Logo.height
      width = 80
      let height = width/logo_ratio
      doc.addImage(this.tenantService.currentTenant.Logo.location ,"JPEG", 42, 20, width,height);
    } 
    doc.text(report_name, width + 60, 50);
    doc['autoTable'](columns, data,{startY: 80});
    doc.save(report_name+".pdf");
  }

  exportPdfTemplate(columns,data, report_name) {
    let dataToExport=[]

  // preparing the data
  for (let row of data){
    let newRow=[];
    for (let col of columns){
      if (col.nestedField){
        newRow.push(row[col.field][col.nestedField])
      } else {
        newRow.push(row[col.field])
      }
    }
    dataToExport.push(newRow)
  }
  let colToExport = columns.map(col=>{return {title: col.header};});
  console.log(colToExport)
  console.log(dataToExport)
  
  let props = {
      outputType: OutputType.Save,
      returnJsPDFDocObject: true,
      fileName: report_name,
      orientationLandscape: false,
      compress: true,
      logo: {
          src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/logo.png",
          type: 'PNG', //optional, when src= data:uri (nodejs case)
          width: 53.33, //aspect ratio = width/height
          height: 26.66,
          margin: {
              top: 0, //negative or positive num, from the current position
              left: 0 //negative or positive num, from the current position
          }
      },
      business: {
          name: report_name,
      },
      invoice: {
          headerBorder: true,
          tableBodyBorder: true,
          header: colToExport,
          table: dataToExport
          // additionalRows: [{
          //     col1: 'Total:',
          //     col2: '145,250.50',
          //     col3: 'ALL',
          //     style: {
          //         fontSize: 14 //optional, default 12
          //     }
          // },
          // {
          //     col1: 'VAT:',
          //     col2: '20',
          //     col3: '%',
          //     style: {
          //         fontSize: 10 //optional, default 12
          //     }
          // },
          // {
          //     col1: 'SubTotal:',
          //     col2: '116,199.90',
          //     col3: 'ALL',
          //     style: {
          //         fontSize: 10 //optional, default 12
          //     }
          // }],
        },
      // footer: {
      //     text: "The invoice is created on a computer and is valid without the signature and stamp.",
      // },
      pageEnable: true,
      pageLabel: "Page ",
  };
  const pdfObject = jsPDFInvoiceTemplate(props);
  // pdfObject.jsPDFDocObject.save();

}

  exportExcel(data, filename="download") {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(data);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, filename);
      });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}
}
