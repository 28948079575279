<p-toast></p-toast>
<div class="card">
    <form [formGroup]="createForm" class="formgrid grid" (ngSubmit)="onSubmit(createForm.value)">   
        <div class="field col-12 md:col-5">
            <label>Date: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
                <p-calendar styleClass="w-full" formControlName="date" appendTo="body" dateFormat="dd/mm/yy" [disabled]="!isNew" (onSelect)="filterProjects()"
                [minDate] = "selectedWeek" [maxDate] = "endOfWeek" ></p-calendar>
            </div>
            <div *ngIf="date.invalid && (date.dirty || date.touched)" class="alert alert-danger">
                <div *ngIf="date.errors?.required">Date is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Hours: </label>
            <input pInputText class="w-full" type="number" formControlName="hours">
            <div *ngIf="hours.invalid && (hours.dirty || hours.touched)" class="alert alert-danger">
                <div *ngIf="hours.errors?.required">Number of hours is required.</div>
                <div *ngIf="hours.errors?.invalid">{{hours.getError('invalid')}}</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Project: </label>
            <p-dropdown styleClass="w-full" [options]="projects" [(ngModel)]="selectedProject" [ngModelOptions]="{standalone: true}" [filter]="true" filterBy="project_name, project_number" optionLabel="project_name"
            placeholder="Select..." (onChange)="filterTasks()"></p-dropdown>
        </div>
        <div class="field col-12 md:col-5">
            <label>Task: </label>
            <p-dropdown styleClass="w-full" [options]="filteredTasks" formControlName="task_id" [filter]="true" filterBy="task_name" optionLabel="task_name" optionValue="id"
            placeholder="Select..."></p-dropdown>
            <div *ngIf="task_id.invalid && (task_id.dirty || task_id.touched)" class="alert alert-danger">
                <div *ngIf="task_id.errors?.required">Task is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-10">
            <label>Description: </label>
            <input type="text" pInputText class="w-full" formControlName="description" name="description"/>
        </div>
    </form>
</div>

<button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
<button type="submit" (click)="onSubmit(createForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 