<p-toast></p-toast>
<div class="card">
    <form [formGroup]="createForm" class="formgrid grid" (ngSubmit)="onSubmit(createForm.value)">   
        <div class="field col-12 md:col-5">
            <label>Organisation Name: </label>
            <input type="text" pInputText class="w-full" formControlName="organisation_name" required="true" name="organisation_name"/>
            <div *ngIf="organisation_name.invalid && (organisation_name.dirty || organisation_name.touched)" class="alert alert-danger">
                <div *ngIf="organisation_name.errors?.required">Organisation name is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Organisation Code: </label>
            <input type="text" pInputText class="w-full" formControlName="organisation_code" required="true" name="organisation_code"/>
            <div *ngIf="organisation_code.invalid && (organisation_code.dirty || organisation_code.touched)" class="alert alert-danger">
                <div *ngIf="organisation_code.errors?.required">Organisation code is required.</div>
            </div>
        </div>
        <div class="col-12 md:col-5">
			<h6>Organization Type</h6>
            <div class="grid">
                <div class="field col-12 md:col-5">
                    <label>Supplier: </label>
                    <p-toggleButton formControlName="is_supplier" class="w-full" styleClass="ml-2" onLabel="Yes" offLabel="No"></p-toggleButton>
                </div>
                <div class="field col-12 md:col-5">
                    <label>Client: </label>
                    <p-toggleButton formControlName="is_client" class="w-full" styleClass="ml-2" onLabel="Yes" offLabel="No"></p-toggleButton>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Reference: </label>
            <input type="text" pInputText class="w-full" formControlName="reference" name="reference"/>
        </div>
        <div class="field col-12 md:col-5">
            <label>Notes: </label>
            <input type="text" pInputText class="w-full" formControlName="notes" name="notes"/>
        </div>
        <div class="field col-12 md:col-5">
            <label>Status: </label>
            <p-selectButton [options]="statusOptions" formControlName="status"></p-selectButton>
        </div>
    </form>
</div>

<button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
<button type="submit" (click)="onSubmit(createForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 