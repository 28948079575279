<p-toast></p-toast>
<div class="card">
    <h3 style="padding: 0.75rem 0;">General Settings</h3>
    <form [formGroup]="tenantForm" class="p-fluid">
        <div class="field col-12 md:col-10">
            <label>Company Name: </label>
            <input type="text" pInputText styleClass="w-full" formControlName="company" required="true" name="company"/>
            <div *ngIf="company.invalid && (company.dirty || company.touched)" class="alert alert-danger">
                <div *ngIf="company.errors?.required">Company Name is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-10">
            <label>Company Email: </label>
            <input type="text" pInputText styleClass="w-full" formControlName="company_email" required="true" name="company_email"/>
            <div *ngIf="company_email.invalid && (company_email.dirty || company_email.touched)" class="alert alert-danger">
                <div *ngIf="company_email.errors?.required">Company Email is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-10">
            <label>Owner: </label>
            <p-dropdown [options]="users" styleClass="w-full" formControlName="owner_id" required="true" name="owner_id" optionLabel="name" optionValue="id" [filter]="true" filterBy="name, email"></p-dropdown>
            <div *ngIf="owner_id.invalid && (owner_id.dirty || owner_id.touched)" class="alert alert-danger">
                <div *ngIf="owner_id.errors?.required">Owner is required.</div>
            </div>
        </div>
        <div  class="field col-12 md:col-12 grid">
            <div class="field col-12 md:col-5">
                <label>Logo: </label>
                <p-fileUpload class="fo-14 m-0 p-0" multiple="false" name="logo" accept="image/*"
                        [auto]="true" customUpload="true" chooseLabel="Choose Image" (uploadHandler)="onUpload($event)">
                    <!-- <ng-template let-file pTemplate="file">
                            How do display images which are uploaded in img tag?
                    </ng-template> -->
                </p-fileUpload>                
            </div>
            <div class="field col-12 md:col-5">
                <p-panel *ngIf="tenant.Logo" header = "Preview: ">
                    <ng-template pTemplate="icons">
                        <button (click)="deleteLogo()" class="p-panel-header-icon p-link" pButton icon="pi pi-trash">
                        </button>
                    </ng-template>
                    <p-image preview = "true" [src]="tenant.Logo.location" alt="Company logo" width="50%"></p-image>
                </p-panel>
            </div>
        </div>
    </form>
    <button type="submit" (click)="onSubmit(tenantForm.value)" class="p-button-raised mr-2 mb-2" pButton label="Save" ></button> 
</div>

