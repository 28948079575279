import { ActiveStatus } from "./ActiveStatus";
import { S3Object } from "./S3Object";
import { Settings } from "./Settings";

export class Tenant {
    id:number;
    company:string;
    owner_id:number;
    company_email: string;
    status: ActiveStatus;

    Logo: S3Object;
    logo_id: number;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    //settings
    Settings:Settings[]=[]

    constructor(tenant){
        this.id = tenant.id || null;
        this.company = tenant.company || null;
        this.owner_id = tenant.owner_id|| null;
    }
}