import { Component, OnInit } from '@angular/core';
import { RouteConfigLoadEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@auth0/auth0-spa-js';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { FavouriteReport } from 'src/app/model/FavouriteReport';
import { Project } from 'src/app/model/Project';
import { Report } from 'src/app/model/Report';
import { ReportType } from 'src/app/model/ReportType';
import { UserService } from 'src/app/services/user.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TimesheetService } from 'src/app/services/timesheet.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(public timesheetService:TimesheetService, private router: Router, private breadcrumbService:BreadcrumbService,
    private userService: UserService, public messageService:MessageService,public dialogService: DialogService) {
      this.breadcrumbService.setItems([
        { label: 'Reports' }
      ]);
    }

  reportTypes:ReportType[] = []
  selectedReportType:ReportType;
  selectedReport:Report
  favouriteReports: FavouriteReport[]=[]

  projects:Project[]=[]
  selectedProject:Project = new Project({});

  currentUser:User;

  ngOnInit(): void {
    this.timesheetService.getAllReportTypes().subscribe(data=>this.reportTypes=data)
    this.timesheetService.getAllProjects().subscribe(data=>this.projects=data)

    this.currentUser = this.userService.currentUser
    this.timesheetService.getFavouriteReports(this.currentUser.id).subscribe(data=>this.favouriteReports = data)
  
  }

  addToFavourites(report:Report){
    let favouriteReport = new FavouriteReport({user_id:this.currentUser.sub,report_id:report.id})
    console.log("adding to favorites...", favouriteReport)
    this.timesheetService.createFavouriteReport(favouriteReport).subscribe(data=>{
      console.log("successfully added to favourites")
      data.Report = report
      this.favouriteReports.push(data)
    })
  }

  removeFromFavourites(favouriteReport:FavouriteReport){
    this.timesheetService.deleteFavouriteReport(favouriteReport).subscribe(data=> {
      console.log("successfully removed from favorites")
      let index = this.favouriteReports.indexOf(favouriteReport)
      this.favouriteReports.splice(index,1)
    })
  }

  removeReportFromFavourites(report:Report){
    let favReport = this.favouriteReports.find(el=>el.report_id==report.id)
    this.timesheetService.deleteFavouriteReport(favReport).subscribe(data=> {
      console.log("successfully removed from favorites")
      let index = this.favouriteReports.indexOf(favReport)
      this.favouriteReports.splice(index,1)
    })
  }

  goToReport(report:Report) {
    this.router.navigate(['/report-preview'],{ state: {report:report}})
  }

  isInFavorites(report:Report){
    return this.favouriteReports.findIndex(el=>el.report_id==report.id)>-1
  }
  
}
