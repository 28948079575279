import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table/table';
import { Tenant } from 'src/app/model/Tenant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TenantService } from 'src/app/services/tenant.service';
import { DialogTenantDetailsComponent } from '../dialog-tenant-details/dialog-tenant-details.component';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
  providers: []
})
export class TenantsComponent implements OnInit {

  tenants: Tenant[]=[]
  id_token:string=""

  ref: DynamicDialogRef;

  canAdd: boolean = false;
canEdit: boolean = false;
  loading: boolean = true;

  cols: any[]= [
    { field: 'company', header: 'Company', filter: true},
    { field: 'tier', header: 'Project Number', filter: true },
    { field: 'id', header: 'Id', filter: true }
];

  constructor(private tenantService: TenantService, public dialogService: DialogService,
    public messageService:MessageService, public exportService:ExportService, 
    private breadcrumbService:BreadcrumbService) {
      this.breadcrumbService.setItems([
        { label: 'ECS Admin'},
        { label: 'Tenants' }
      ]); }

  ngOnInit(): void {
      this.updateTenants()    
  }

  addTenant(){
    this.ref = this.dialogService.open(DialogTenantDetailsComponent, {
      header: 'Add a Tenant',
      width: '70%'
    });
    this.ref.onClose.subscribe((tenant: Tenant) =>{
      if (tenant) {
        this.tenants.push(tenant)
        this.messageService.add({severity:'info', summary: 'Tenant Created', detail: tenant.company});
      }
    });
  }

  editTenant(tenant:Tenant){
    this.ref = this.dialogService.open(DialogTenantDetailsComponent, {
      header: 'Tenant Details',
      width: '70%',
      data: {
        tenant:tenant
      }
    });
    this.ref.onClose.subscribe((updated_tenant: Tenant) =>{
      if (updated_tenant) {
        var index = this.tenants.indexOf(tenant);
        this.tenants[index]=updated_tenant;
        this.messageService.add({severity:'info', summary: 'Tenant Updated', detail: tenant.company});
      }
    });
  }

  
  updateTenants(): void {
    this.loading = true;
    this.tenantService.getAllTenants().subscribe(data=>{
      this.tenants=data;
      console.log(data)
      this.loading = false;
    })
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.tenants)
}

  exportExcel() {
    this.exportService.exportExcel(this.tenants)
  }

  clear(table: Table) {
    table.clear();
  }

  getCompleteFields(){
    return this.cols.map(el=>el.field)
  }
}
