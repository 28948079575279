import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Moment } from 'moment';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { Assignment } from 'src/app/model/Assignment';
import { Auth0Role } from 'src/app/model/Auth0Role';
import { Project } from 'src/app/model/Project';
import { Task } from 'src/app/model/Task';
import { TimeEntry } from 'src/app/model/TimeEntry';
import { TimesheetApproval } from 'src/app/model/TimesheetApproval';
import { User } from 'src/app/model/User';
import { TenantService } from 'src/app/services/tenant.service';
import { TimesheetService } from 'src/app/services/timesheet.service';

@Component({
  selector: 'app-dialog-timesheet-details',
  templateUrl: './dialog-timesheet-details.component.html',
  styleUrls: ['./dialog-timesheet-details.component.scss']
})
export class DialogTimesheetDetailsComponent implements OnInit {

  new_timeentry: TimeEntry = new TimeEntry({})
  createForm:FormGroup= this.fb.group({
    'id': [],
    'date':[,Validators.required],
    'task_id':[,Validators.required],
    'hours':[,Validators.required],
    'description':[],
    'employee_id':[]
  })
  isNew: boolean = true
  assignments:Assignment[]=[]
  selectedUser:User;
  selectedWeek:Date;
  endOfWeek:Date;
  projects:Project[]=[]
  selectedProject:Project=new Project({});
  tasks:Task[]=[]
  filteredTasks:Task[]=[]
  // selectedTask:Task = new Task({})
  users:User[]=[]
  // filteredRoles:Role[]=[]
  // selectedRole : Role = new Role({})

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private fb: FormBuilder,
    private messageService: MessageService, public timesheetService:TimesheetService, private tenantService: TenantService) {
      if (config.data && config.data.timeEntry) {
        this.new_timeentry = this.config.data.timeEntry
        this.selectedUser = this.config.data.user
        this.selectedWeek = this.config.data.selectedWeek
        this.endOfWeek = this.config.data.endOfWeek
        if (this.new_timeentry.id) {this.isNew = false}
        this.createForm.patchValue(this.new_timeentry)
      }
    }

    ngOnInit(): void {
      this.timesheetService.getTasksByCriteria(new Task({status:ActiveStatus.Active})).subscribe(data=>{
        this.tasks=data
        this.filteredTasks = this.tasks;
      })
      this.timesheetService.getAssignmentByCriteria(new Assignment({employee_id:this.selectedUser.id})).subscribe(assignments=>{
        this.assignments = assignments       
        this.filterProjects()     
      })
    }

    cancel(){
      this.ref.close();
    }

    filterTasks(){
      this.filteredTasks = this.tasks.filter(el=>el.project_id==this.selectedProject.id)
    }

    filterProjects(){
      this.projects = []
      if (this.createForm.value.date){
        for (let ass of this.assignments){
          if ((!this.projects.some(p=>p.id==ass.project_id)) && new Date(ass.start_date)<= this.createForm.value.date && (!ass.end_date || new Date(ass.end_date)>=this.createForm.value.date)) {this.projects.push(ass.Project)}
        } 
      } else {
        for (let ass of this.assignments){
          if (!this.projects.some(p=>p.id==ass.project_id)) {this.projects.push(ass.Project)}
        }
      }
    }

    // filterRoles(){
    //   this.tasks = this.tasks.filter(el=>el.project_id=this.selectedProject.id)
    // }
  
    onSubmit(form:TimeEntry){
      let allowZeros = this.tenantService.timesheetSettings.settings_json.allow_zero_time
      let allow_negative_time = this.tenantService.timesheetSettings.settings_json.allow_negative_time

      if (this.createForm.value.hours==0 && !allowZeros) this.hours.setErrors({invalid: "Time entries with a null value are not allowed by your administrator"})
      if (this.createForm.value.hours<0 && !allow_negative_time) this.hours.setErrors({invalid: "Time entries a negative value are not allowed by your administrator"})

      if (this.createForm.valid){
        this.new_timeentry = form
        this.new_timeentry.TimesheetApproval = new TimesheetApproval({assignment_id:this.assignments.find(ass=>ass.project_id == this.selectedProject.id && ass.employee_id == this.selectedUser.id).id})
  
        if (this.isNew){
          let assignment_id:number = this.assignments.find(ass=>ass.project_id == this.selectedProject.id).id
          this.timesheetService.createTimeEntry(form,assignment_id).subscribe(data=>{
            console.log("timesheet created")
            this.new_timeentry=data
            this.ref.close(this.new_timeentry);
          })
        }
        else {
          this.timesheetService.updateTimeEntry(this.new_timeentry).subscribe(data=> {
            console.log("timesheet updated");
            this.new_timeentry=data
            this.ref.close(this.new_timeentry);
          })
        }
      }
    }
      // for mandatory fields
      get date() { return this.createForm.get('date'); }
      get description() { return this.createForm.get('description'); }
      get task_id() { return this.createForm.get('task_id'); }
      get hours() { return this.createForm.get('hours'); }
  }