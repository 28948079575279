import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User} from 'src/app/model/User';
import { Permission} from 'src/app/model/Permission';
import { Role} from 'src/app/model/Role';

import { environment } from './../../environments/environment';
import { License } from '../model/License';
import { TenantLicense } from '../model/TenantLicense';
import { TenantService } from './tenant.service';

const headers = new HttpHeaders({
  'Access-Control-Allow-Origin':'*',
});

@Injectable({
  providedIn: 'root'
})

export class UserService {

  public currentUser:User = JSON.parse(localStorage.getItem('user'));
  public permissionList:Permission[]=[]
  public licensesList: TenantLicense[]=[]

  constructor(private http: HttpClient, public tenantService: TenantService) { }

  public setCurrentUser(user) {
      this.currentUser=user
      localStorage.setItem('user',JSON.stringify(this.currentUser))
  }

  public checkPermission(permName:string, permAction:string=""){
    if (this.permissionList.length ==0) {this.cleanPermissions()}
    return this.permissionList.some(perm=>(perm.label==permName&&(permAction!=""?perm.rolePermission[`can_${permAction}`]:true)))
  }

  public checkAtLeastOnePermission(permNames:string[],permAction:string=""){
    if (this.permissionList.length ==0) {this.cleanPermissions()}
    return this.permissionList.some(perm=>(permNames.includes(perm.label) && (permAction!=""?perm.rolePermission[`can_${permAction}`]:true)))
  }

  public checkLicense(licenseName:string){
    if (this.licensesList.length ==0) {this.cleanLicenses()}
    return this.licensesList.some(lic=>(lic.License.Module.name==licenseName))
  }

  public cleanPermissions(){
    let permissionList =[]
    if (this.currentUser && this.currentUser.roles){
      permissionList = this.currentUser.roles.reduce(function(prev, role) {
        return prev.concat(role.permissions)
      }, []);
    
    //keep only permissions that have at least one action enabled
    this.permissionList = permissionList.filter(perm => (perm.rolePermission.can_add || perm.rolePermission.can_delete || perm.rolePermission.can_edit || perm.rolePermission.can_view || perm.rolePermission.can_enable))
    }
    return this.permissionList
  }

  public cleanLicenses(){
    let licensesList = this.currentUser.tenantLicenses
    this.licensesList = licensesList
    return this.licensesList
    // this.licensesList = licensesList.filter(license => {
    //   // sorting valid licenses only
    //   //filter by date
  }

  public getUserByAuth0Id(auth0_id): Observable<User> {
    return this.http.get<User>(this.tenantService.api_url+"/user/auth0/"+auth0_id,{headers:headers})
  }

  public getAllUsers() : Observable<User[]> {
    return this.http.get<User[]>(this.tenantService.api_url+"/user",{headers:headers})
  }

  public getUsersByCriteria(userFilter:User): Observable<User[]> {
    return this.http.post<User[]>(this.tenantService.api_url+"/user/filter",userFilter,{headers:headers})
  }

  public getUserById(id:number): Observable<User> {
    return this.http.get<User>(this.tenantService.api_url+"/user/"+ id,{headers:headers})
  }

  public createUser(user:User) : Observable<User> {
    return this.http.post<User>(this.tenantService.api_url+"/user",user,{headers:headers})
  }

  public updateUser(user:User) : Observable<User> {
    return this.http.put<User>(this.tenantService.api_url+"/user",user,{headers:headers})
  }

  //deletes auth0 user, db user, assigned licenses, user roles, favorite reports 
  public deleteUser(user:User) : Observable<User> {
    return this.http.delete<User>(this.tenantService.api_url+"/user/"+user.id,{headers:headers})
  }

  public createRole(role:Role) : Observable<Role> {
    return this.http.post<Role>(this.tenantService.api_url+"/role",role,{headers:headers})
  }

  public deleteRole(role:Role) : Observable<Role> {
    return this.http.delete<Role>(this.tenantService.api_url+"/role/"+role.id,{headers:headers})
  }

  public updateRole(role:Role) : Observable<Role> {
    return this.http.put<Role>(this.tenantService.api_url+"/role",role,{headers:headers})
  }

  public getAllRoles() : Observable<Role[]> {
    return this.http.get<Role[]>(this.tenantService.api_url+"/role",{headers:headers})
  }

  public getRolesByCriteria(role:Role): Observable<Role[]> {
    return this.http.post<Role[]>(this.tenantService.api_url+"/role/filter",role,{headers:headers})
  }

  public getAllPermissions() : Observable<Permission[]> {
    return this.http.get<Permission[]>(this.tenantService.api_url+"/permission",{headers:headers})
  }

  public sendResetPasswordEmail(user:User): Observable<any> {
    return this.http.post<any>(this.tenantService.api_url+"/reset-password",user,{headers:headers})
  }

    // update the permissions of a specific role
  public updatePermissions(role:Role):Observable<Role>{
    return this.http.put<Role>(this.tenantService.api_url+"/role/permissions",role,{headers:headers})
  }
}

