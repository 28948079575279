<p-toast></p-toast>
<div class="grid">
	<div class="col-12">
		<!-- <div class="card"> -->
            <h3 style="padding: 0.75rem 0;">Notification Texts</h3>
            <form [formGroup]="timesheetSettingsForm" class="formgrid grid">
                <div *ngFor="let attribute of attributes" class="field col-12">
                    <label>{{getAttributeName(attribute)}} <i class="pi pi-info-circle" pTooltip="Tooltip explicatif"></i></label>
                    <textarea [rows]="4" [cols]="30" pInputText class="w-full" [formControlName]="attribute" class="w-full"></textarea>
                </div>
            </form>
            <button type="submit" (click)="onSubmit(timesheetSettingsForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 
        <!-- </div> -->
    </div>
</div>


