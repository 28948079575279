import { templateJitUrl } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { TimesheetApproval, TimesheetApprovalStatus } from 'src/app/model/TimesheetApproval';
import { User } from 'src/app/model/User';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-timesheet-approval',
  templateUrl: './timesheet-approval.component.html',
  styleUrls: ['./timesheet-approval.component.scss']
})
export class TimesheetApprovalComponent implements OnInit {

  timesheetApprovals:TimesheetApproval[]=[]
  //auth0_token:string =""
  employee:User;
  currentUser:User;
  displayRejectionDialog:boolean = false;
  rejectionComment:string;
  weekStart:Date;

  constructor(private route:ActivatedRoute, private timesheetService:TimesheetService, private userService: UserService,
    public messageService:MessageService, public tenantService: TenantService, 
    private breadcrumbService:BreadcrumbService) {
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/view']},
        { label: 'Approval'}
      ]); }

  ngOnInit(): void {
    let employee_id : number = Number(this.route.snapshot.paramMap.get('employee_id'));
    this.userService.getUserById(employee_id).subscribe(data=>this.employee = data)
    // this.userService.getAuth0Token().subscribe(token=>
    //   {
    //     this.auth0_token = token.access_token;
    //     this.userService.getAuth0User(employee_id,this.auth0_token).subscribe(data=>this.employee = data)
    //   })
    let ts_app_ids = this.route.snapshot.paramMap.get('ts_app_ids').split(',');
    console.log(ts_app_ids)
    let promises = []
    for (let ts_app_id of ts_app_ids){
      let promise = new Promise(resolve => 
        this.timesheetService.getTimesheetApproval(Number(ts_app_id)).subscribe(data=>
        {
          if (data.status == TimesheetApprovalStatus.Submitted){
            this.timesheetApprovals.push(data)
            this.weekStart = data.week_start
          }
          console.log(data)
        })
      )
      promises.push(promise)

    }
    this.currentUser = this.userService.currentUser
  }

  approveTimesheet(){
    for (let ts of this.timesheetApprovals){
      ts.status = TimesheetApprovalStatus.Approved
      ts.approved_at = new Date()
      ts.approver_id = this.currentUser.id
      this.timesheetService.updateTimesheetApproval(ts).subscribe(data=> {
        ts = data
        let index = this.timesheetApprovals.indexOf(ts)
        this.timesheetApprovals.splice(index)
      })
    }
  }

  rejectionDialog(){
    this.displayRejectionDialog=true
  }

  rejectTimesheet(){
    for (let ts of this.timesheetApprovals){
      ts.status = TimesheetApprovalStatus.Rejected
      ts.rejected_at = new Date()
      ts.rejecter_id = this.currentUser.id
      ts.rejection_comment = this.rejectionComment
      this.timesheetService.updateTimesheetApproval(ts).subscribe(data=>this.messageService.add({severity:'info', summary: 'Timesheet Rejected'}))     
    }
    let link:string = `${location.origin}/timesheet/view/${this.employee.id}`
    this.timesheetService.notifyEmployeeOfRejection([this.employee],link,{RejectionComment: this.rejectionComment, PeriodStartDate: this.timesheetApprovals[0].week_start}, this.currentUser.id).subscribe(data=>{
      this.messageService.add({severity:'info', summary: 'Employee Notified'})
      this.displayRejectionDialog = false;
    })

    // TODO reject or set to pending other timesheet approvals for this employee??
  }

  calculateHoursTotal(tsApp:TimesheetApproval):number {
    let sum:number = 0;
    console.log(tsApp)
    tsApp.TimeEntries.map(te=>sum+=te.hours)
    return sum
  }
}
