import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MegaMenuItem, MenuItem } from 'primeng/api';
import { take } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { Tenant } from 'src/app/model/Tenant';
import { UserService } from 'src/app/services/user.service';
import { TenantService } from 'src/app/services/tenant.service';
import { Permission } from 'src/app/model/Permission';
import { License } from 'src/app/model/License';
import { TenantLicense } from 'src/app/model/TenantLicense';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('topbarActionPanelAnimation', [
        transition(':enter', [
            style({opacity: 0, transform: 'scaleY(0.8)'}),
            animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
          ]),
          transition(':leave', [
            animate('.1s linear', style({ opacity: 0 }))
          ])
    ])
]
})
export class HeaderComponent implements OnInit, OnChanges {

  items:MegaMenuItem[]=[];
  currentUser:any;
  isCompanyAdmin:boolean = false;
  tenants:Tenant[]=[]
  selectedTenantId:number;
  subMenu:MenuItem[] = [];
  userMenu: MenuItem[]=[]
  @Input() selectedModule: string;

  constructor(
    public auth: AuthService,
    public tenantService: TenantService,
    public app: AppComponent,
    public userService: UserService,
    @Inject(DOCUMENT) private doc: Document,
  ) {
    this.auth.error$.subscribe((error) => console.log("login error",error));
    this.auth.user$.pipe(take(1)).subscribe(userProfile => { 
      console.log(userProfile)
      this.currentUser = userProfile;
      this.selectedTenantId = this.currentUser["http://ecs.app/tenants"][0]
      this.tenantService.setCurrentTenant(this.selectedTenantId)
      this.userService.getUserByAuth0Id(userProfile.sub).subscribe(dbUser =>{
        console.log("db user retrieved: ", dbUser)
        this.userService.setCurrentUser(dbUser)
        this.updateMenu()
      })
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMenu()
  }

  ngOnInit(): void {
    console.log("module",this.selectedModule)
  }  

  updateMenu(){
    this.userMenu = [
      {
        icon: 'pi pi-user',
        items:
          [{
            label: 'User Settings',
            icon: 'pi pi-user-edit',
            routerLink: 'profile'     
          },
          {
            label: 'Account Settings',
            icon: 'pi pi-cog',
            routerLink: 'user-settings',
            visible: (this.userService.checkAtLeastOnePermission(["Users" ,"Roles" ,"Tenant Settings" ,"Billing"])) 
          },
          {
            label: 'Help Center',
            icon: 'pi pi-question',
            routerLink: 'user-settings'     
          },
          {
            separator:true
          },
          {
            label: 'Logout',
            icon: 'pi pi-power-off',
            command: (event) => this.logout()     
          }]
    }
    ]

    switch (this.selectedModule) {
      case 'Account Settings':
        this.subMenu = 
            [
              {
                label:'User Management',
                visible: (this.userService.checkPermission("Users")),
                items:[
                  {
                    label: 'Users',
                    icon: 'pi pi-users',
                    routerLink: 'admin/users',
                    visible: (this.userService.checkPermission("Users")) 
                  },
                  { 
                    label:"Roles",
                    icon:"pi pi-key",
                    routerLink: 'admin/roles',
                    visible: (this.userService.checkPermission("Roles")) 
                  },
                ]
              },
              { 
                label: 'Settings',
                visible: (this.userService.checkAtLeastOnePermission(["Tenant Settings", "Roles", "Billing"])),
                items: [
                 
                  { 
                    label:"General",
                    icon:"pi pi-desktop",
                    routerLink: 'admin/tenant-settings/general',
                    visible: (this.userService.checkPermission("Tenant Settings")) 
                  },
                  {
                    label:"Notifications",
                    icon:"pi pi-cog",
                    routerLink: 'admin/tenant-settings/system',
                    visible: (this.userService.checkPermission("Tenant Settings")) 
                  },
                  {
                    label:"Currencies",
                    icon:"pi pi-dollar",
                    routerLink: 'admin/tenant-settings/currencies',
                    visible: (this.userService.checkPermission("Tenant Settings")) 
                  }
                ]
              },
              { 
                label:"Licenses & Billing",
                // icon:"pi pi-credit-card",
                visible: (this.userService.checkPermission("Billing")),
                items: [
                  {
                    label:"Licenses",
                    icon:"pi pi-ticket",
                    routerLink: 'admin/account/licenses',
                    visible: (this.userService.checkPermission("Billing")),
                  },
                  {
                    label:"Billing history",
                    icon:"pi pi-money-bill",
                    routerLink: 'admin/account/billing-history',
                    visible: (this.userService.checkPermission("Billing")),
                  },
                  {
                    label:"Payment methods",
                    icon:"pi pi-credit-card",
                    routerLink: 'admin/account/payment-methods',
                    visible: (this.userService.checkPermission("Billing")),
                  },
                  {
                    label:"Billing notifications",
                    icon:"pi pi-envelope",
                    routerLink: 'admin/account/billing-notifications',
                    visible: (this.userService.checkPermission("Billing")),
                  }
                ] 
              }
            ]
        break;
      case 'Time Tracking':
        this.subMenu = 
            [ 
              {
                label: 'Admin',
                visible: (this.userService.checkAtLeastOnePermission(["Organisations","Projects", "Tasks", "Assignments"])),
                items:[
                  {
                    label: 'Organisations',
                    icon: 'pi pi-briefcase',
                    routerLink: 'timesheet/organisations',
                    visible: (this.userService.checkPermission("Organisations"))     
                  },
                  {
                    label: 'Projects',
                    icon: 'pi pi-folder',
                    routerLink: 'timesheet/projects',
                    visible: (this.userService.checkPermission("Projects"))      
                  },
                  {
                    label: 'Tasks',
                    icon: 'pi pi-list',
                    routerLink: 'timesheet/tasks',
                    visible: (this.userService.checkPermission("Tasks"))      
                  },
                  {
                    label: 'Assignments',
                    icon: 'pi pi-link',
                    routerLink: 'timesheet/assignments',
                    visible: (this.userService.checkPermission("Assignments"))      
                  }
                ]
              },
              {
                label: 'Timesheet',
                routerLink: 'timesheet/view',
                visible: (this.userService.checkPermission('Own Timesheets - Time Entries')) 
                // [
                //   // {
                //   //   label: 'View timesheets',
                //   //   icon: 'pi pi-clock',
                //   //   routerLink: 'timesheet/view',
                //   //   visible: (this.userService.checkPermission('Own Timesheets - Time Entries')) 
                //   // }
                //   // ,{
                //   //   label: 'Batch Print',
                //   //   icon: 'pi pi-print',
                //   //   routerLink: 'timesheet/batch-print',
                //   //   visible: (this.userService.checkPermission("Reports")) 
                //   // }
                // ]        
              },
              {
                label: 'Check',
                items:
                [
                  {
                    label: 'Check timesheets',
                    icon: 'pi pi-check-square',
                    routerLink: 'timesheet/check-timesheets',
                    visible: (this.userService.checkAtLeastOnePermission(['Supervised Timesheets - Time Entries','Own Timesheets - Time Entries'])) 
                  },
                  {
                    label: 'Check managers',
                    icon: 'pi pi-user',
                    routerLink: 'timesheet/check-managers',
                    visible: (this.userService.checkAtLeastOnePermission(['Supervised Timesheets - Time Entries','Own Timesheets - Time Entries'])) 
                  },
                  {
                    label: 'Notifications History',
                    icon: 'pi pi-calendar',
                    routerLink: 'timesheet/notifications',
                    visible: (this.userService.checkPermission('Own Timesheets - Time Entries')) 
                  }
              ]},
              {
                label: 'Reports',
                visible: (this.userService.checkAtLeastOnePermission(['Supervised Timesheets - Time Entries', 'Own Timesheets - Time Entries',"Reports"])),
                items:[
                  {
                    label:'View All',
                    icon: 'pi pi-list',
                    routerLink: 'reports',
                    visible: (this.userService.checkPermission("Reports")) 
                  },
                  {
                    label: 'Customize',
                    icon: 'pi pi-palette',
                    routerLink: 'customize-report',
                    visible: (this.userService.checkPermission("Reports")) 
                  },
                  {
                    label: 'Scheduled',
                    icon: 'pi pi-clock',
                    routerLink: 'customize-report',
                    visible: (this.userService.checkPermission("Reports")) 
                  }
                ]
              },
              {
                label:'Imports',
                routerLink: 'timesheet/import',
                visible: (this.userService.checkAtLeastOnePermission(["Organisations", "Projects","Tasks", "Assignments", "Supervised Timesheets - Time Entries", 'All Timesheets - Time Entries'], "add")) 
              },
              {
                label: 'Settings',
                visible: (this.userService.checkPermission("Timesheet Settings")),
                items:
                [
                  {
                    label:"Templates",
                    icon:"pi pi-bars",
                    routerLink: 'timesheet/timesheet-settings/templates',
                    visible: (this.userService.checkPermission("Timesheet Settings")) 
                  },
                  {
                    label:"Notifications",
                    icon:"pi pi-envelope",
                    routerLink: 'timesheet/timesheet-settings/notifications',
                    visible: (this.userService.checkPermission("Timesheet Settings")) 
                  },
                  { 
                    label:"Period Settings",
                    icon:"pi pi-clock",
                    routerLink: 'timesheet/timesheet-settings/time-entry',
                    visible: (this.userService.checkPermission("Timesheet Settings")) 
                  },
              ]}
            ]
        break;
      default:
        console.log("incorrect module")
        break;
    }


  }

  logout(): void {
    this.auth.logout({ returnTo: this.doc.location.origin });
  }

  loginWithRedirect(): void {
    this.auth.loginWithRedirect({ screen_hint: 'login' });
    this.auth.user$.subscribe(userProfile => {
      console.log(userProfile.name, "succesfulley logged in")
      this.userService.setCurrentUser(userProfile)
      this.currentUser = userProfile
    });
  }
}
