import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Assignment } from 'src/app/model/Assignment';

import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { DialogAssignmentDetailsComponent } from '../dialog-assignment-details/dialog-assignment-details.component';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { AppComponent } from 'src/app/app.component';
import { User } from 'src/app/model/User';
import { UserService } from 'src/app/services/user.service';
const rangeMoment = extendMoment(moment);

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent implements OnInit {

  assignments: Assignment[]=[]
  selectedAssignments: Assignment[]=[]
  canEdit: boolean = false;
  canDelete: boolean = false;

  id_token:string=""
  ref:DynamicDialogRef;
  canAdd: boolean = false;
  loading: boolean = true;
  //auth0ext_token:string;
  statusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]

  cols: any[]= [
    { field: 'Employee', header: 'Employee', nestedField:'name' },
    { field: 'position_name', header: 'Position'},
    { field: 'Project', header: 'Project', nestedField:'project_name' },
    { field: 'charge_rate', header: 'Charge Rate'},
    { field: 'cost_rate', header: 'Cost Rate'},
    { field: 'status', header: 'Status'},
    { field: 'start_date', header: 'Start Date', isDate:true},
    { field: 'end_date', header: 'End Date', isDate:true}
  ];
  
  constructor(private timesheetService: TimesheetService, public tenantService: TenantService,
    public messageService:MessageService, public dialogService: DialogService, public exportService:ExportService,
    private breadcrumbService:BreadcrumbService, private userService: UserService) {
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/dashboard']},
        { label: 'Admin'},
        { label: 'Assignments' }
      ]);
    }

  ngOnInit(): void {
    this.canAdd = this.userService.checkPermission('Assignments', 'add')
    this.canEdit = this.userService.checkPermission('Assignments', 'edit')
    this.canDelete = this.userService.checkPermission('Assignments', 'delete')
    
    this.updateAssignments(); 
  }

  updateAssignments(){
    this.loading = true
    this.timesheetService.getAllAssignments().subscribe(data=>{
      this.assignments=data;
      //console.log(data)
      this.loading = false;
    })
  }
  
  addAssignment(){
    this.ref = this.dialogService.open(DialogAssignmentDetailsComponent, {
      header: 'Add a Assignment',
      width: '70%'
    });
    this.ref.onClose.subscribe((assignment: Assignment) =>{
      if (assignment) {
        this.messageService.add({severity:'success', summary: 'Assignment Created'});
        this.checkForRangeOverlap(assignment, this.assignments)
 
        this.assignments.push(assignment)
      }
    });
  }

  checkForRangeOverlap(new_assignment:Assignment, old_assignments:Assignment[]): void{
    //check if the new range overlaps with any other assignment
    if (new_assignment.status==ActiveStatus.Active){
      let new_range = rangeMoment.range(new_assignment.start_date,new_assignment.end_date)
      let disableAssignment = false;
      for (let ass of old_assignments){
        new_assignment.start_date = new Date(new_assignment.start_date)
        if (new_assignment.end_date) new_assignment.end_date = new Date(new_assignment.end_date)
        if (ass.project_id==new_assignment.project_id && ass.employee_id==new_assignment.employee_id && ass.status==ActiveStatus.Active){
          let old_range = rangeMoment.range(ass.start_date,ass.end_date)
          ass.start_date = new Date(ass.start_date)
          if (ass.end_date) ass.end_date = new Date(ass.end_date)
          if (new_range.overlaps(old_range,{ adjacent: true })){
            if (ass.start_date < new_assignment.start_date){
              ass.end_date = new_assignment.start_date
              ass.end_date.setDate(ass.end_date.getDate()-1)
              if (ass.end_date<ass.start_date) ass.start_date = ass.end_date
            } else if (new_assignment.end_date && (ass.start_date > new_assignment.start_date || (ass.start_date == new_assignment.start_date && ass.end_date && ass.end_date>new_assignment.end_date))){
              ass.start_date=new_assignment.end_date
              ass.start_date.setDate(ass.start_date.getDate()+1)
              if (ass.end_date<ass.start_date) ass.end_date = ass.start_date
            } else {
              // if (ass.end_date>new_assignment.end_date) {
              //   ass.start_date=new Date(new Date(new_assignment.start_date).getDate()-1)
              // } else {
                ass.status = ActiveStatus.Inactive
                disableAssignment = true
              // }
            }
            this.timesheetService.updateAssignment(ass).subscribe(data=>
              {
                if (disableAssignment) {this.messageService.add({severity:'danger', summary: 'Overlapping Assignments', detail: 'Overlapping assignment has been set as inactive'})}
                else {this.messageService.add({severity:'warning', summary: 'Overlapping Assignments', detail: 'Overlapping Assignments updated'})}
              })
          }
        }
      }
    }
  }

  editAssignment(assignment:Assignment){
    assignment.start_date = new Date(assignment.start_date)
    if (assignment.end_date) assignment.end_date = new Date(assignment.end_date)
    this.ref = this.dialogService.open(DialogAssignmentDetailsComponent, {
      header: 'Assignment Details',
      width: '70%',
      data: {
        assignment:assignment
      }
    });
    this.ref.onClose.subscribe((updated_assignment: Assignment) =>{
      if (updated_assignment) {
        var index = this.assignments.indexOf(assignment);
        this.assignments.splice(index,1)
        this.checkForRangeOverlap(updated_assignment,this.assignments)
        this.assignments.push(updated_assignment);
        this.messageService.add({severity:'success', summary: 'Assignment Updated'});
      }
    });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.assignments, "Assignments")
  }

  exportExcel() {
    this.exportService.exportExcel(this.assignments)
  }

  getCompleteFields(){
    return this.cols.map(el=>el.completeField)
  }

  setAsActive(){
    this.updateStatus(ActiveStatus.Active)
  }
  setAsInactive(){
    this.updateStatus(ActiveStatus.Inactive)
  }

  updateStatus(status:ActiveStatus){
    let promises = this.selectedAssignments.map(ass=>{
      ass.status = status
      return new Promise(resolve => this.timesheetService.updateAssignment(ass).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Assigment(s) successfully updated'})) 
  }

  delete(){
    let promises = this.selectedAssignments.map(ass=>{
      return new Promise(resolve => this.timesheetService.deleteAssignment(ass).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Assigment(s) successfully deleted'})) 
  }

}
