<p-toast></p-toast>
<div class="card">
    <p-table [value]="employees" [(selection)]="selectedLicense.users" dataKey="id" responsiveLayout="scroll">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Job Title</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-employee>
            <tr>
                <td>
                    <p-tableCheckbox [value]="employee"></p-tableCheckbox>
                </td>
                <td>{{employee.name}}</td>
                <td>{{employee.email}}</td>
                <td>{{employee.job_title}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
<button type="submit" (click)="saveAssignments()" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 