<div class="pages-body notfound-page flex flex-column">
    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>NOT FOUND</h2>
            </div>
            <div class="card mt-3 px-6">
                <img src="assets/layout/images/pages/404.png" alt=""/>
            </div>
            <div class="pages-detail pb-6">Requested resource is not available, or you don't have the required access rights to see it.</div>
            <button pButton pRipple type="button" label="GO BACK TO DASHBOARD" [routerLink]="['/dashboard']" class="p-button-text"></button>
        </div>
    </div>
</div>
