import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Tenant } from 'src/app/model/Tenant';
import { User } from 'src/app/model/User';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
// import {
//     DomSanitizer,
//     SafeHtml,
//     SafeUrl,
//     SafeStyle
// } from '@angular/platform-browser';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  constructor(private breadcrumbService:BreadcrumbService, private fb: FormBuilder,
    private messageService: MessageService, public tenantService:TenantService, public userService:UserService) {
      this.breadcrumbService.setItems([
        { label: 'Account Settings'},
        { label: 'Tenant Settings',routerLink:['/admin/tenant-settings/general']},
        { label: 'General', routerLink:['/admin/tenant-settings/general'] }
      ]); }

    tenantForm:FormGroup= this.fb.group({
      'id': [],
      'company':[,Validators.required],
      'owner_id':[,Validators.required],
      'company_email':[,Validators.required]
    })
    isNew: boolean = true
    tenant:Tenant = this.tenantService.currentTenant
    
    users:User[]=[]
    image_base64:any={}
    logoToBeDeleted: boolean = false;
  
    ngOnInit(): void {
      this.userService.getAllUsers().subscribe(data=>this.users = data)
      let canEdit = this.userService.checkPermission("Tenant Settings","edit")
      if (!canEdit){this.tenantForm.disable()}
      this.tenantForm.patchValue(this.tenantService.currentTenant)
      console.log(this.tenantForm)
      console.log(this.tenantService.currentTenant.Logo)
    }

    getImageSize(url: string): Observable<any> {
      return new Observable(observer => {
        var image = new Image();
        image.src = url;
    
        image.onload = (e: any) => {
          var height = e.path[0].height;
          var width = e.path[0].width;
    
          observer.next({width:width, height:height});
          observer.complete();
        };
      });
    }

    onUpload(event) {
      let fileReader = new FileReader();
      for (let file of event.files) {
        fileReader.readAsDataURL(file);
        fileReader.onload = (event:any)=> {
          // // loading the image to fetch and save its size
          this.getImageSize(event.target.result).subscribe(data=>{
            console.log(data);
            this.image_base64.width = data.width
            this.image_base64.height = data.height
          })
          // var image = new Image();
          // image.src = 
          // image.onload = function () {
          //   console.log(`width : ${image.width} px`, `height: ${image.height} px`);
          // };

          // base64
          this.image_base64.image = fileReader.result

          
        };
        
      this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: event.files[0].name});
      }
    }
  
    onSubmit(form:Tenant){
      if (this.tenantForm.valid){
        this.tenant = form
        if (this.image_base64 != {}){
          this.tenantService.saveLogo(this.image_base64).subscribe(data=>{
            if (typeof data == 'string') data = JSON.parse(data)
            this.tenant.logo_id = data.logo_id
            this.tenant.Logo = data.Logo
            this.tenantService.updateTenantInStorage(this.tenant)
            console.log("image uploaded");
            console.log(data)
          })
        }
        this.tenantService.updateTenant(this.tenant).subscribe(data=> {
          console.log("tenant updated");
          this.tenantService.updateTenantInStorage(this.tenant)
          this.messageService.add({severity: 'success', summary: 'Tenant updated', detail: this.tenant.company})
        })
      }
    }

    deleteLogo(){
      this.tenantService.deleteLogo(this.tenant.logo_id).subscribe(data=>{
        console.log("logo deleted");
        console.log(data)
        delete this.tenant.Logo
        delete this.tenant.logo_id
        this.image_base64 = {}
        this.tenantService.updateTenantInStorage(this.tenant)
      })
    }
    // for mandatory fields
    get company() { return this.tenantForm.get('company'); }
    get owner_id() { return this.tenantForm.get('owner_id'); }
    get company_email() { return this.tenantForm.get('company_email'); }
    get color() { return this.tenantForm.get('color'); }
}
