import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Settings, TimesheetSettings } from 'src/app/model/Settings';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor(private fb: FormBuilder, public utilsService:UtilsService, public tenantService: TenantService, 
    private breadcrumbService:BreadcrumbService, private messageService: MessageService) {
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/dashboard']},
        { label: 'Timesheet Settings'},
        { label: 'Notifications' }
      ]); }

  timesheetSettingsForm:FormGroup= this.fb.group({
    'approval_request': [],
    'project_assignment': [],
    'submission_reminder': [],
    'rejection_notification': [],
    'approval_reminder': []
  })

  timesheetSettings:Settings;

  attributes:string[]=Object.keys(this.timesheetSettingsForm.controls).filter(el=>el!='id')

  ngOnInit(): void {
    this.timesheetSettings = this.tenantService.timesheetSettings
    this.timesheetSettingsForm.patchValue(this.timesheetSettings.settings_json)
  }

  getAttributeName(attribute:string){
    return this.utilsService.humanize(attribute)
  }

  onSubmit(form:TimesheetSettings){
    if (this.timesheetSettingsForm.valid){
      this.timesheetSettings.settings_json = form

      this.tenantService.updateSettings(this.timesheetSettings).subscribe(data=> {
        console.log("notification settings updated");
        this.timesheetSettings=data
        console.log(data)
        this.messageService.add({severity: 'success', summary: 'Settings updated'})

      })
    }
  }

}
