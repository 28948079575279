<form [formGroup]="createReportForm" class="formgroup-inline" (ngSubmit)="submitReport(createReportForm.value)">   
    <div class="field">
        <label>Report Name: </label>
        <input type="text" pInputText class="w-full" formControlName="report_name" required="true" name="report_name"/>
        <div *ngIf="report_name.invalid && (report_name.dirty || report_name.touched)" class="alert alert-danger">
            <div *ngIf="report_name.errors?.required">Report name is required.</div>
        </div>
    </div>
    <div class="field">
        <label>Description: </label>
        <input type="text" pInputText class="w-full" formControlName="description" required="true" name="description"/>
    </div>
    <div class="field">
        <label>Report Type: </label>
        <p-dropdown styleClass="w-full" [options]="reportTypes" formControlName="report_type_id" optionLabel="report_type_name" optionValue="id" [filter]="true" filterBy="report_type_name"
             placeholder="Select..." name="report_type_id"></p-dropdown>
        <div *ngIf="report_type_id.invalid && (report_type_id.dirty || report_type_id.touched)" class="alert alert-danger">
            <div *ngIf="report_type_id.errors?.required">Report Type is required.</div>
        </div>
    </div>
</form>
<button type="submit" (click)="submitReport(createReportForm.value)" pButton label="Submit Report" ></button> 

<form [formGroup]="createReportTypeForm" class="formgroup-inline" (ngSubmit)="submitReportType(createReportTypeForm.value)">   
    <div class="field">
        <label>Report Type Name: </label>
        <input type="text" pInputText class="w-full" formControlName="report_type_name" required="true" name="report_type_name"/>
        <div *ngIf="report_type_name.invalid && (report_type_name.dirty || report_type_name.touched)" class="alert alert-danger">
            <div *ngIf="report_type_name.errors?.required">Type name is required.</div>
        </div>
    </div>
    <div class="field">
        <label>Description: </label>
        <input type="text" pInputText class="w-full" formControlName="description" required="true" name="description"/>
    </div>
    <div class="field">
        <label>Icon: </label>
        <input type="text" pInputText class="w-full" formControlName="icon" required="true" name="icon"/>
    </div>
</form>
<button type="submit" (click)="submitReportType(createReportTypeForm.value)" pButton label="Submit Report Type" ></button> 