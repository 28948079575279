
import { Module } from "./Module";
import { Tenant } from "./Tenant";
import { TimesheetTemplate } from "./TimesheetTemplate";

export class Settings {
    id:number;
    tenant_id: number;
    module_id: number;
    Module: Module;
    settings_json: any;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

export enum FirstWeekDay {
    Saturday = "Saturday",
    Sunday = "Sunday",
    Monday = "Monday"
}

export class TimesheetSettings {
    templates: TimesheetTemplate[];

    //notifications
    approval_request: string;
    project_assignment : string;
    submission_reminder: string;
    rejection_notification: string;
    approval_reminder: string;
    system_email_address: string;

    //period settings
    date_format: string;
    week_start: FirstWeekDay;
    past_weeks_shown: number;
    future_weeks_shown: number;
    lockdown_date: Date;
    allow_negative_time: boolean;
    allow_zero_time: boolean;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

export class TenantSettings {
    // notifications
    user_onboarding_email_text: string;
    assigned_license_text : string;
}