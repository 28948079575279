import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  updateFirstDayOfTheWeek(firstDay: string){
    moment.updateLocale('en', {
      week : {
          dow : firstDay=="Monday"?1:firstDay=="Sunday"?0:6 // Monday is the first day of the week
      }
    });
  }

  humanize(str) {
    let i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  getDaysOfTheWeekArray(start):Moment[] {
    let datesArray:Moment[] = []
    let new_date = moment(start).startOf('day')
    for (let i = 0; i<=6; i++){ 
      datesArray.push(moment(new_date))
      new_date.add(1,'day')
    }
    return datesArray;
  };

  getWeeksArray(start, end):Moment[] {
    
    var start_date = this.getFirstDayOfWeek(start)
    for(var arr:Moment[]=[],dt=start_date; dt<=end; dt.add(7,'day')){
        arr.push(moment(dt).startOf('day'));
    }
    return arr;
  };

  getFirstDayOfWeek(m:Moment):Moment {
    return moment(m).startOf('week')
  }

  isCurrentWeek(week:Moment, today:Moment){
    let firstDayThisWeek = this.getFirstDayOfWeek(today)
    return (week.isSame(firstDayThisWeek))
  }

  filterRoles(roles, event) :any[]{
    let filtered : any[] = [];
    let query = event.query;

    for(let i = 0; i < roles.length; i++) {
        let role = roles[i];
        if (role.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(role);
        }
    }
    return filtered;
  }
}
