import { time } from "console";
import { Assignment } from "./Assignment";
import { Project } from "./Project";
import { TimeEntry } from "./TimeEntry";
import { TimesheetNotification } from "./TimesheetNotification";
import { User } from "./User";

export enum TimesheetApprovalStatus {
    Pending = "Pending",
    Submitted = "Submitted",
    Rejected = "Rejected",
    Approved = "Approved"
}

export class TimesheetApproval {
    id:number;
    week_start: Date;
    // employee_id:number;
    tenant_id: number;
    approver_id: number;
    approved_at: Date;
    submitted_at : Date;
    submitter_id: number;
    rejecter_id: number;
    rejected_at:    Date;
    rejection_comment:  string;
    status:TimesheetApprovalStatus;

    assignment_id: number;
    Assignment:Assignment;

    TimesheetNotification:TimesheetNotification[];

    // Employee:User;
    Rejecter:User;
    Submitter:User;
    Approver:User;

    TimeEntries:TimeEntry[];

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(timesheetApproval){
        this.id = timesheetApproval.id || null;
        // this.tenant_id = timesheetApproval.tenant_id || null;
        this.approved_at = timesheetApproval.approved_at || null;
        this.approver_id = timesheetApproval.approver_id || null;
        this.submitted_at = timesheetApproval.submitted_at || null;
        this.status = timesheetApproval.status || null;
        this.rejecter_id = timesheetApproval.rejecter_id || null;
        this.rejected_at = timesheetApproval.rejected_at || null;
        this.rejection_comment = timesheetApproval.rejection_comment || null;
        this.assignment_id = timesheetApproval.assignment_id || null;
    }
}