import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  constructor(public app:AppComponent, 
    private breadcrumbService:BreadcrumbService) {
      this.breadcrumbService.setItems([
        { label: 'Admin'},
        { label: 'Dashboard' }
      ]); }
  ordersChart: any;
  ordersOptions: any;
  overviewChartData1:any;
  overviewChartData2:any;
  overviewChartData3:any;
  overviewChartData4:any;
  overviewChartOptions1:any;

  ngOnInit(): void {
    this.ordersChart = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
      datasets: [ {
        label: 'Revenue',
        data: [47, 38, 37, 48, 38, 43, 52, 55, 55],
        borderColor: [
            '#3F51B5',
        ],
        backgroundColor: [
            'rgba(63, 81, 181, 0.8)',
        ],
        borderWidth: 2,
        fill: true,
        tension: .4
    },{
          label: 'Hours',
          data: [31, 53, 49, 49, 52, 55, 59, 66, 64],
          borderColor: [
              '#4DD0E1',
          ],
          backgroundColor: [
              'rgba(77, 208, 225, 0.8)',
          ],
          borderWidth: 2,
          fill: true,
          tension: .4
      },]
    };
    this.ordersOptions = this.getOrdersOptions();

    this.overviewChartData1 = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
      datasets: [
          {
              data: [50, 64, 32, 24, 18, 27, 20, 36, 30],
              borderColor: [
                  '#009688',
              ],
              backgroundColor: [
                  '#4DB6AC',
              ],
              borderWidth: 2,
              fill: true,
              tension: .4
          }
      ]
    };
    this.overviewChartData2 = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
      datasets: [
          {
              data: [50, 64, 32, 24, 18, 27, 20, 36, 30],
              borderColor: [
                  '#795548 ',
              ],
              backgroundColor: [
                  '#A1887F',
              ],
              borderWidth: 2,
              fill: true,
              tension: .4
          }
      ]
    };
    this.overviewChartData3 = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
      datasets: [
          {
              data: [50, 64, 32, 24, 18, 27, 20, 36, 30],
              borderColor: [
                  '#FF5722',
              ],
              backgroundColor: [
                  '#FF5722',
              ],
              borderWidth: 2,
              fill: true,
              tension: .4
          }
      ]
    };
    this.overviewChartData4 = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
      datasets: [
          {
              data: [50, 64, 32, 24, 18, 27, 20, 36, 30],
              borderColor: [
                  '#E91E63',
              ],
              backgroundColor: [
                  '#F06292',
              ],
              borderWidth: 2,
              fill: true,
              tension: .4
          }
      ]
    };
    this.overviewChartOptions1 = {
      plugins: {
          legend: {
              display: false
          }
      },
      scales: {
          y: {
              display: false
          },
          x: {
              display: false
          }
      },
      tooltips: {
          enabled: false
      },
      elements: {
          point: {
              radius: 0
          }
      },
    };
  }

  getOrdersOptions() {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
        plugins: {
            legend: {
                display: true,
                labels: {
                    fontFamily,
                    fontColor: textColor,
                }
            }
        },
        responsive: true,
        scales: {
            y: {
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            },
            x: {
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            }
        }
    };
}

}
