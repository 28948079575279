<p-toast></p-toast>
<div class="card">
    <form [formGroup]="createForm" class="formgrid grid" (ngSubmit)="onSubmit(createForm.value)">   
        <div class="field col-12 md:col-5">
            <label>Project: </label>
            <p-dropdown styleClass="w-full" [options]="projects" formControlName="project_id" optionLabel="project_name" optionValue="id" [filter]="true" filterBy="project_name, project_number"
                 placeholder="Select..."></p-dropdown>
            <div *ngIf="project_id.invalid && (project_id.dirty || project_id.touched)" class="alert alert-danger">
                <div *ngIf="project_id.errors?.required">Project is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Position: </label>
            <input type="text" pInputText class="w-full" formControlName="position_name" name="position_name"/>
        </div>
        <!-- <div class="field col-12 md:col-5">
            <label>Task: </label>
            <p-dropdown styleClass="w-full" [options]="tasks" formControlName="task_id" optionLabel="task_name" optionValue="id" [filter]="true" filterBy="task_name"
                 placeholder="Select..." name="task_id"></p-dropdown>
            <div *ngIf="task_id.invalid && (task_id.dirty || task_id.touched)" class="alert alert-danger">
                <div *ngIf="task_id.errors?.required">Task is required.</div>
            </div>
        </div> -->
        <div class="field col-12 md:col-5">
            <label>User: </label>
            <p-dropdown styleClass="w-full" [options]="users" formControlName="employee_id" optionLabel="name" optionValue="id" [filter]="true" filterBy="user_name"
                 placeholder="Select..." name="employee_id" (onChange)="updateRates()"></p-dropdown>
            <div *ngIf="employee_id.invalid && (employee_id.dirty || employee_id.touched)" class="alert alert-danger">
                <div *ngIf="employee_id.errors?.required">User is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Charge Rate: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                <input pInputText class="w-full" type="number" formControlName="charge_rate">
            </div>
            <div *ngIf="charge_rate.invalid && (charge_rate.dirty || charge_rate.touched)" class="alert alert-danger">
                <div *ngIf="charge_rate.errors?.required">Charge Rate is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Cost Rate: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                <input pInputText class="w-full" type="number" formControlName="cost_rate">
            </div>
            <div *ngIf="cost_rate.invalid && (cost_rate.dirty || cost_rate.touched)" class="alert alert-danger">
                <div *ngIf="cost_rate.errors?.required">Cost Rate is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Start Date: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
                <p-calendar styleClass="w-full" formControlName="start_date" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>End Date: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
                <p-calendar styleClass="w-full" formControlName="end_date" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Status: </label>
            <p-selectButton [options]="statusOptions" formControlName="status"></p-selectButton>
        </div>
    </form>
</div>

<button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
<button type="submit" (click)="onSubmit(createForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 