import { User } from "@auth0/auth0-spa-js";
import { TimesheetApproval } from "./TimesheetApproval";

export enum NotificationType {
    ApprovalRequest = "Approval Request",
    ApprovalReminder = "Approval Reminder",
    RejectionNotification = "Rejection Notification", 
    SubmissionReminder = "Submission Reminder"
}

export class TimesheetNotification {
    id:number;
    type:NotificationType;
    message: string;
    date: Date;

    sender_id:number;
    Sender: User;

    receiver_id:number;
    Receiver: User;

    timesheet_approval_id: number;
    TimesheetApproval: TimesheetApproval;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
  
    constructor(timesheetNotification){
        this.receiver_id = timesheetNotification.receiver_id || null;
        this.timesheet_approval_id = timesheetNotification.timesheet_approval_id || null;
        this.type = timesheetNotification.type || null;
        this.message = timesheetNotification.message || null;
        this.date = timesheetNotification.date || null;
        this.sender_id = timesheetNotification.sender_id || null;
    }
}