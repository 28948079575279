import Module from "module";
import { Permission } from "./Permission";

export class Role {
    id:number;
    description:string;
    label:string;
    system_role:boolean;
    module_id:string;
    permissions:Permission[];
    Module: Module;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(role){
        this.description = role.description || null;
        this.label = role.label || null;
    }
}