import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Currency } from 'src/app/model/Currency';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-currencies',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.scss']
})
export class CurrenciesComponent implements OnInit {

  currencies: Currency[]=[]

  loading: boolean = true;
  
  cols: any[]= [
    { field: 'name', header: 'Name' }
  ];

  selectedCurrency: Currency = new Currency({name:""});
  displayCurrencyDetails: boolean = false;
  isNew: boolean = false;

  constructor(private tenantService: TenantService,public messageService:MessageService, public exportService: ExportService,
    private breadcrumbService:BreadcrumbService, private userService: UserService){
      this.breadcrumbService.setItems([
        { label: 'Account Settings'},
        { label: 'Tenant Settings',routerLink:['/admin/tenant-settings/general']},
        { label: 'Currencies' }
      ]); }

 ngOnInit(): void {
    this.updateCurrencies()
    
  }

  updateCurrencies(): void {
    this.loading = true
    this.tenantService.getAllCurrencies().subscribe(data=>{
      this.currencies=data;
      this.loading = false;
      console.log("currencies",data)
    })
  }
  
  addCurrency(){
    this.displayCurrencyDetails = true;
    this.selectedCurrency = new Currency({name:""})
    this.isNew = true;
  }

  submit() {
    console.log("selected currency", this.selectedCurrency)
    if (this.selectedCurrency.name && this.selectedCurrency.name != ""){
      if (this.isNew){
        this.tenantService.createCurrency(this.selectedCurrency).subscribe(data=>{
          this.selectedCurrency = data
          this.currencies.push(this.selectedCurrency)
          this.messageService.add({severity:'info', summary: 'Currency Created', detail: this.selectedCurrency.name})
        })
      } else {
        this.tenantService.updateCurrency(this.selectedCurrency).subscribe(data=>{
          var index = this.currencies.indexOf(this.selectedCurrency);
          this.currencies[index]=this.selectedCurrency;
          this.messageService.add({severity:'info', summary: 'Currency Updated', detail: this.selectedCurrency.name});
        })
      }
      this.displayCurrencyDetails = false
    }
  }

  editRow(currency:Currency){
    this.displayCurrencyDetails = true;
    this.selectedCurrency = currency
    this.isNew = false;
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.currencies)
  }

  exportExcel() {
    this.exportService.exportExcel(this.currencies)
  }

  getCompleteFields(){
    return this.cols.map(el=>el.field)
  }

  cancel() {
    this.displayCurrencyDetails = false
  }
}