<p-toast></p-toast>
<div class="card">
    <form [formGroup]="createForm" class="formgrid grid" (ngSubmit)="onSubmit(createForm.value)">   
        <div class="field col-12 md:col-10">
            <label>Name: </label>
            <input type="text" pInputText class="w-full" formControlName="name" required="true" name="name"/>
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors?.required">Name is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-10">
            <label>Footer: </label>
            <textarea [rows]="2" [cols]="30" pInputTextarea class="w-full" formControlName="footer"></textarea>
        </div>
        <div class="col-12 md:col-5">
            <label>Is Default: </label>
            <p-toggleButton  formControlName="is_default" class="w-full" styleClass="mb-4" onLabel="Yes" offLabel="No"></p-toggleButton>
        </div>
        <div class="col-12 md:col-5">
            <label>Includes Description: </label>
            <p-toggleButton  formControlName="include_description" class="w-full" styleClass="mb-4" onLabel="Yes" offLabel="No"></p-toggleButton>
        </div>
        <div class="col-12 md:col-5">
            <label>Includes Task: </label>
            <p-toggleButton  formControlName="include_task" class="w-full" styleClass="mb-4" onLabel="Yes" offLabel="No"></p-toggleButton>
        </div>
        <div class="col-12 md:col-5">
            <label>Includes Profit: </label>
            <p-toggleButton  formControlName="include_profit" class="w-full" styleClass="mb-4" onLabel="Yes" offLabel="No"></p-toggleButton>
        </div>
        <div class="field col-12 md:col-5">
            <label>Status: </label>
            <p-selectButton [options]="statusOptions" formControlName="status"></p-selectButton>
        </div>
    </form>
</div>

<button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
<button type="submit" (click)="onSubmit(createForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 