import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FirstWeekDay, Settings } from 'src/app/model/Settings';
import { TimesheetSettings } from 'src/app/model/Settings';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-time-entry',
  templateUrl: './time-entry.component.html',
  styleUrls: ['./time-entry.component.scss']
})
export class TimeEntryComponent implements OnInit {

  constructor(private fb: FormBuilder, private messageService: MessageService,
    private breadcrumbService:BreadcrumbService, private tenantService: TenantService) {
      this.breadcrumbService.setItems([
        { label: 'Settings'},
        { label: 'Timesheet Settings'},
        { label: 'Time Entry' }
      ]); }
    
      timeEntrySettingsForm:FormGroup= this.fb.group({
        'date_format': [],
        'week_start': [],
        'past_weeks_shown': [],
        'future_weeks_shown': [],
        'lockdown_date': [],
        'allow_negative_time': [],
        'allow_zero_time': []
      })
    
      timesheetSettings:Settings;
      canEdit:boolean = true;
      weekdays: FirstWeekDay[] = Object.values(FirstWeekDay)
      dateFormats = ['dd/MM/yyyy','M/d/yy','MMM d, y','MMMM d, y','EEEE, MMMM d, y']
  
    
      ngOnInit(): void {
        this.timesheetSettings = this.tenantService.timesheetSettings
        this.timesheetSettings.settings_json.lockdown_date = new Date(this.timesheetSettings.settings_json.lockdown_date)
        this.timeEntrySettingsForm.patchValue(this.timesheetSettings.settings_json)
      }
    
      onSubmit(form:TimesheetSettings){
        if (this.timeEntrySettingsForm.valid){
          this.timesheetSettings.settings_json = form
    
          this.tenantService.updateSettings(this.timesheetSettings).subscribe(data=> {
            console.log("time entry settings updated");
            this.timesheetSettings=data
            console.log(data)
            this.messageService.add({severity: 'success', summary: 'Settings updated'})
    
          })
        }
      }

      getDate() : Date {
        return new Date()
      }
    
    }
