<p-card *ngIf="timesheetApprovals.length>0; else none">
    <ng-template pTemplate="title">
        Approval
    </ng-template>

    <ng-template pTemplate="subtitle">
        {{employee.name}} - Week starting {{weekStart | date:'dd MMM YYYY'}}
    </ng-template>

    <ul class="no-bullets">
        <li *ngFor="let tsApp of timesheetApprovals">
            <p-table [value]="tsApp.TimeEntries" responsiveLayout="scroll">
                <ng-template pTemplate="caption">
                    {{tsApp.Assignment.Project.project_name}}
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width:200px">Task</th>
                        <th style="min-width:200px">Date</th>
                        <th style="min-width:200px">Hours</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-timeEntry let-rowIndex="rowIndex">
                    <tr>
                        <td style="min-width:200px">
                            {{timeEntry.Task.task_name}}
                        </td>
                        <td style="min-width:200px">
                            {{timeEntry.date | date:'dd MMM YYYY'}}
                        </td>
                        <td style="min-width:200px">
                            {{timeEntry.hours}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr class="p-rowgroup-footer">
                        <td colspan = "3">
                            <div style="text-align: right; width: 100%">Total Hours</div>
                        </td>
                        <td>{{calculateHoursTotal(tsApp)}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </li>
    </ul>

    <ng-template pTemplate="footer">
        <div>
            <button (click)="approveTimesheet()" pButton label="Approve" icon="pi pi-check" class="p-button-raised p-button-success mr-2"></button> 
            <button (click)="rejectionDialog()" pButton label="Reject" icon="pi pi-times" class="p-button-raised p-button-danger"></button> 
        </div>
    </ng-template>
</p-card>

<ng-template #none>
    No time-entry to approve.
</ng-template>

<p-dialog header="Rejection Comment" [(visible)]="displayRejectionDialog">
    <textarea [rows]="2" [cols]="30" pInputTextarea class="w-full" [(ngModel)]="rejectionComment"></textarea>
    <ng-template pTemplate="footer">
        <button (click)="rejectTimesheet()" pButton label="Confirm rejection" class="p-button-raised p-button-danger"></button> 
    </ng-template>
</p-dialog>