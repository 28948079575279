import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { PrimeNGConfig } from 'primeng/api';
import { MenuService } from './components/navigation/menus/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MenuService]
})
export class AppComponent {
  selectedModule:string = "Account Settings"

  topbarTheme = 'blue';
  menuTheme = 'light';
  layoutMode = 'light';
  menuMode = 'static';
  inlineMenuPosition = 'bottom';
  inputStyle = 'filled';
  ripple = true;
  isRTL = false;
  menuWidth: "15rem"

  menuClick: boolean;
  menuActive:boolean;
  staticMenuDesktopInactive: boolean;
  mobileMenuActive: boolean;
  mobileTopbarActive: boolean;
  topbarRightClick: boolean;
  topbarMenuActive: boolean;
  menuHoverActive = false;
  rightMenuActive: boolean;

  constructor(private primengConfig: PrimeNGConfig) {}

  ngOnInit() {
      this.primengConfig.ripple = true;
      this.menuActive = this.isStatic() && !this.isMobile();
  }
  onMenuClick($event) {
    this.menuClick = true;
    this.selectedModule;

    // if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
    //     this.inlineMenuActive[this.currentInlineMenuKey] = false;
    // }
  }

  selectModule(module){
    this.selectedModule=module
  }

  onTopbarMobileButtonClick(event) {
    this.mobileTopbarActive = !this.mobileTopbarActive;
    event.preventDefault();
  }

  onMenuButtonClick(event) {
    this.menuActive = !this.menuActive;
    this.topbarMenuActive = false;
    this.topbarRightClick = true;
    this.menuClick = true;

    if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
        this.mobileMenuActive = !this.mobileMenuActive;
        if (this.mobileMenuActive) {
            this.blockBodyScroll();
        } else {
            this.unblockBodyScroll();
        }
    }

    event.preventDefault();
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
        document.body.classList.add('blocked-scroll');
    } else {
        document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
        document.body.classList.remove('blocked-scroll');
    } else {
        document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
            'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }
  isMobile() {
    return window.innerWidth <= 991;
  }
  isStatic() {
    return this.menuMode === 'static';
  }
  isHorizontal() {
    return this.menuMode === 'horizontal';
  }    
  isSlim() {
    return this.menuMode === 'slim';
  }
  isOverlay() {
    return this.menuMode === 'overlay';
}
}
