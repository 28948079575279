import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table/table';
import { AppComponent } from 'src/app/app.component';
import { Project } from 'src/app/model/Project';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { DialogProjectDetailsComponent } from '../dialog-project-details/dialog-project-details.component';
import { ActiveStatus } from 'src/app/model/ActiveStatus';



@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  projects: Project[]=[]
  selectedProjects: Project[]=[]

  ref:DynamicDialogRef;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  loading: boolean = false;

  cols: any[]= [
    { field: 'project_name', header: 'Project Name', filter: true},
    { field: 'project_number', header: 'Project Code', filter: true },
    { field: 'Client', header: 'Client', nestedField : 'organisation_name', filter: true },
    { field: 'description', header: 'Description', filter: true },
    { field: 'status', header: 'Status', filter: true },
  ];
 
  constructor(private timesheetService: TimesheetService,public messageService:MessageService,
    public dialogService: DialogService, public tenantService: TenantService, public exportService:ExportService, 
    private breadcrumbService:BreadcrumbService, private userService: UserService){
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/dashboard']},
        { label: 'Admin'},
        { label: 'Projects' }
      ]); }

  ngOnInit(): void {
      this.canEdit = this.userService.checkPermission('Projects', 'edit')
      this.canAdd = this.userService.checkPermission('Projects', 'add')
      this.canDelete = this.userService.checkPermission('Projects', 'delete')

      this.updateProjects()
      this.cols.forEach(el=>el.completeField = el.nestedField?el.field+'.'+el.nestedField:el.field)
    }

  updateProjects(): void {
    //this.loading = true;
    this.timesheetService.getAllProjects().subscribe(data=>{
      this.projects=data;
      this.loading = false;
    })
  }
  
  addProject(){
    this.ref = this.dialogService.open(DialogProjectDetailsComponent, {
      header: 'Add a Project',
      width: '70%'
    });
    this.ref.onClose.subscribe((project: Project) =>{
      if (project) {
        this.projects.push(project)
        this.messageService.add({severity:'success', summary: 'Project Created', detail: project.project_name});
      }
    });
  }

  editProject(project:Project){
    this.ref = this.dialogService.open(DialogProjectDetailsComponent, {
      header: 'Project Details',
      width: '70%',
      data: {
        project:project
      }
    });
    this.ref.onClose.subscribe((updated_project: Project) =>{
      if (updated_project) {
        var index = this.projects.indexOf(project);
        this.projects[index]=updated_project;
        this.messageService.add({severity:'success', summary: 'Project Updated', detail: project.project_name});
      }
    });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.projects, "Projects")
}

  exportExcel() {
    this.exportService.exportExcel(this.projects)
  }

  clear(table: Table) {
    table.clear();
  }

  getCompleteFields(){
    return this.cols.map(el=>el.completeField)
  }

  setAsActive(){
    this.updateStatus(ActiveStatus.Active)
  }
  setAsInactive(){
    this.updateStatus(ActiveStatus.Inactive)
  }

  updateStatus(status:ActiveStatus){
    let promises = this.selectedProjects.map(proj=>{
      proj.status = status
      return new Promise(resolve => this.timesheetService.updateProject(proj).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Task(s) successfully updated'})) 
  }

  delete(){
    let promises = this.selectedProjects.map(proj=>{
      return new Promise(resolve => this.timesheetService.deleteProject(proj).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Task(s) successfully deleted'})) 
  }

}
