import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { TimesheetTemplate } from 'src/app/model/TimesheetTemplate';
import { User } from 'src/app/model/User';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-template-details',
  templateUrl: './dialog-template-details.component.html',
  styleUrls: ['./dialog-template-details.component.scss']
})
export class DialogTemplateDetailsComponent implements OnInit {

  new_template: TimesheetTemplate = new TimesheetTemplate({})
  createForm:FormGroup= this.fb.group({
    'id': [],
    'name':[,Validators.required],
    'is_default':[],
    'include_description':[],
    'include_task':[],
    'include_profit':[],
    'footer':[],
    'status':[,Validators.required]
  })
  isNew: boolean = true
  statusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]
  booleanOptions = [true,false]
  currentUser:User;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private fb: FormBuilder,
    private messageService: MessageService, public timesheetService:TimesheetService, private userService:UserService) {
      if (config.data && config.data.template) {
        this.new_template = this.config.data.template
        this.isNew = false
        this.createForm.patchValue(this.new_template)
      }
    }

  ngOnInit(): void {
   let canEdit = this.userService.checkPermission("Timesheet Settings","edit")
   if (!canEdit){this.createForm.disable()}
  }
  cancel(){
    this.ref.close();
  }

  onSubmit(form:TimesheetTemplate){
    if (this.createForm.valid){
      this.new_template = form

      if (this.isNew){
        this.timesheetService.createTemplate(form).subscribe(data=>{
          console.log("template created")
          console.log(data)
          this.new_template=data
          this.messageService.add({severity: 'success', summary: 'Template Created'})
          this.ref.close(this.new_template);
        })
      }
      else {
        this.timesheetService.updateTemplate(this.new_template).subscribe(data=> {
          console.log("template updated");
          this.new_template=data
          console.log(data)
          this.messageService.add({severity: 'success', summary: 'Template updated'})
          this.ref.close(this.new_template);
        })
      }
    }
  }
    // for mandatory fields
    get name() { return this.createForm.get('name'); }
}
