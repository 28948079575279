<p-toast></p-toast>
<div class="card">
    <form [formGroup]="createForm" class="formgrid grid" (ngSubmit)="onSubmit(createForm.value)">   
        <div class="field col-12 md:col-10">
            <label>Project Name: </label>
            <input type="text" pInputText class="w-full" formControlName="project_name" required="true" name="project_name"/>
            <div *ngIf="project_name.invalid && (project_name.dirty || project_name.touched)" class="alert alert-danger">
                <div *ngIf="project_name.errors?.required">Project Name is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Project Code: </label>
            <input type="text" pInputText class="w-full" formControlName="project_number" required="true" name="project_number"/>
            <div *ngIf="project_number.invalid && (project_number.dirty || project_number.touched)" class="alert alert-danger">
                <div *ngIf="project_number.errors?.required">Project Code is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Project Manager: </label>
            <p-dropdown styleClass="w-full" [options]="users" formControlName="manager_id" optionLabel="name" optionValue="id" [filter]="true" filterBy="name, email, nickname"
                 placeholder="Select..." name="manager_id" required="true" (onChange)="updateTimesheetApprover()"></p-dropdown>
            <div *ngIf="manager_id.invalid && (manager_id.dirty || manager_id.touched)" class="alert alert-danger">
                <div *ngIf="manager_id.errors?.required">Project Manager is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Client: </label>
            <p-dropdown styleClass="w-full" [options]="organisations" formControlName="client_id" optionLabel="organisation_name" optionValue="id" [filter]="true" filterBy="organisation_name, organisation_code"
                 placeholder="Select..." name="client_id"></p-dropdown>
            <div *ngIf="client_id.invalid && (client_id.dirty || client_id.touched)" class="alert alert-danger">
                <div *ngIf="client_id.errors?.required">Client is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Reference: </label>
            <input type="text" pInputText class="w-full" formControlName="reference" name="reference"/>
        </div>
        <div class="field col-12 md:col-10">
            <label>Description: </label>
            <textarea [rows]="2" [cols]="30" pInputTextarea class="w-full" formControlName="description"></textarea>
        </div>

        <div class="field col-12 md:col-5">
            <label>Budget Amount: </label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                <p-inputNumber formControlName="budget_amount" mode="currency" currency="AUD" maxFractionDigits="2"></p-inputNumber>
            </div>
        </div>
        <div class="col-12 md:col-5">
            <label>Chargeable: </label>
            <p-toggleButton  formControlName="chargeable" class="w-full" styleClass="mb-4" onLabel="Yes" offLabel="No"></p-toggleButton>
        </div>
        <div class="col-12 md:col-10">
            <label>Timesheet Approver: </label>
            <div class = "field grid p-fluid">
                <div class="field col-12 md:col-6 p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                    <input type="text" pInputText class="w-full" placeholder="Name" formControlName="timesheet_approver_name" name="timesheet_approver_name"/>
                </div>
                <div class="field col-12 md:col-6 p-inputgroup">
                    <span class="p-inputgroup-addon">@</span>
                    <input type="text" pInputText class="w-full" placeholder="Email" formControlName="timesheet_approver_email" name="timesheet_approver_email"/>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-10">
            <label>Notes: </label>
            <textarea [rows]="2" [cols]="30" pInputTextarea class="w-full" formControlName="notes"></textarea>
        </div>
        <div class="field col-12 md:col-5">
            <label>Template: </label>
            <p-dropdown styleClass="w-full" [options]="templates" formControlName="timesheet_template_id" optionLabel="name" optionValue="id" [filter]="true" filterBy="name"
            autoDisplayFirst="true" appendTo="body" name="timesheet_template_id"></p-dropdown>
            <div *ngIf="timesheet_template_id.invalid && (timesheet_template_id.dirty || timesheet_template_id.touched)" class="alert alert-danger">
                <div *ngIf="timesheet_template_id.errors?.required">Template is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Status: </label>
            <p-selectButton [options]="statusOptions" formControlName="status"></p-selectButton>
        </div>
    </form>
</div>

<button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
<button type="submit" (click)="onSubmit(createForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 