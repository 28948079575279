import { Time } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { Project } from 'src/app/model/Project';
import { TimesheetApproval, TimesheetApprovalStatus } from 'src/app/model/TimesheetApproval';
import { User } from 'src/app/model/User';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-check-managers',
  templateUrl: './check-managers.component.html',
  styleUrls: ['./check-managers.component.scss']
})
export class CheckManagersComponent implements OnInit {

  constructor(public utilsService:UtilsService, public timesheetService: TimesheetService,
    public tenantService: TenantService,private router: Router, public userService:UserService,
    public messageService:MessageService,public dialogService: DialogService, 
    private breadcrumbService:BreadcrumbService) {
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/dashboard']},
        { label: 'Check'},
        { label: 'Check Managers' }
      ]); }

  today:Moment = moment().startOf('day'); 
  selectedWeek:Moment=this.getFirstDayOfWeek(this.today);
  filteredTimesheetsApprovals: TimesheetApproval[]=[];
  timesheetsApprovals: TimesheetApproval[]=[];
  weeksArray:Moment[]=[]

  employees:User[]=[]
  selectedTimesheets:TimesheetApproval[]=[]
  //auth0ext_token:string=""
  managers:User[]=[]
  projects: Project[] = []

  selectedProjects: Project[]=[];
  selectedManager:User;

  loading:boolean = false;
  displayNotificationMessage:boolean = false;
  notificationMessage:string = ""

  currentUser:User;
  displayRejectionDialog:boolean = false;
  rejectionComment:string;

  public tableMenuItems:MenuItem[] = [
    {
        label: 'Approve',
        command: (event) => this.approveAllSelected(),
        disabled: !this.checkCanApproveReject()
    },
    {
        label: 'Reject',
        command: (event) =>this.rejectAllSelected(),
        disabled: !this.checkCanApproveReject
    },
    {
      label: 'Send Reminder',
      command: (event) =>this.notifySelectedUsers()
  }
    ];

  ngOnInit(): void {
    this.loading = true;
    this.userService.getUsersByCriteria(new User({status:ActiveStatus.Active})).subscribe(data=>{
      this.employees=data
      this.updateTimesheets()
    })
    this.timesheetService.getProjectsByCriteria(new Project({status:ActiveStatus.Active})).subscribe(data => {
      this.projects = data
      console.log("projects",data)
      this.managers = this.projects.reduce(function(prev, proj) {
        return prev.concat(proj.ProjectManager)
      }, []);
    })

    //setting first day of the week
    let firstDay = this.tenantService.timesheetSettings.settings_json.week_start
    moment.updateLocale('en', {
      week : {
          dow : firstDay=="Monday"?1:firstDay=="Sunday"?0:6 // Monday is the first day of the week
      }
    });
    this.currentUser = this.userService.currentUser
    this.updateWeeksArray()
    this.selectedWeek = this.weeksArray.find(el=>this.isCurrentWeek(el))   
  }

  updateTimesheets(){
    let startDate = moment(this.selectedWeek)
    this.timesheetService.getTimesheetsByStartDate(startDate).subscribe(data=>{
      console.log("timeshets",data)
      this.timesheetsApprovals = data;
      this.filteredTimesheetsApprovals = data;
      this.loading = false
    })
  }

  filterByProjectAndManager(){
    let filteredList:TimesheetApproval[]=this.timesheetsApprovals
    if (this.selectedManager){
      filteredList = filteredList.filter(tsApp=>tsApp.Assignment.Project.manager_id==this.selectedManager.id)
    }
    if (this.selectedProjects.length>0){
      filteredList = this.timesheetsApprovals.filter(tsApp=> this.selectedProjects.some(proj=>tsApp.Assignment.project_id == proj.id))
    }
    this.filteredTimesheetsApprovals = filteredList
}

  updateWeeksArray(){
    let toSubstract = this.tenantService.timesheetSettings.settings_json.past_weeks_shown
    let toAdd = this.tenantService.timesheetSettings.settings_json.future_weeks_shown
    let minDate:Moment = moment().subtract(toSubstract, 'week')
    let maxDate :Moment = moment().add(toAdd,'week')
    this.weeksArray = this.getWeeksArray(minDate,maxDate)
    this.weeksArray.sort((a:Moment,b:Moment)=>a.isBefore(b)?1:-1)
  }

  getFirstDayOfWeek(m:Moment,firstDay?:String):Moment {
    return this.utilsService.getFirstDayOfWeek(m)
  }
  getDaysOfTheWeekArray(start):Moment[] {
    return this.utilsService.getDaysOfTheWeekArray(start)
  };
  getWeeksArray(start, end):Moment[] {
    return this.utilsService.getWeeksArray(start,end)
  };
  isCurrentWeek(week:Moment){
    return this.utilsService.isCurrentWeek(week,this.today)
  }
  checkCanApproveReject(): boolean { 
    // TODO
    // if (this.selectedUser.id==this.currentUser.id) return this.userService.checkPermission('Own Timesheets - Time Entries', 'edit')
    // else if (this.isSupervisedEmployee(this.selectedUser)) return this.userService.checkPermission('Supervised Timesheets - Time Entries', 'edit')
    // else return this.userService.checkPermission('All Timesheets - Time Entries', 'edit')
    return true
  }
 
  notifySelectedUsers(){
    if (this.selectedTimesheets.length>0) {
      this.notificationMessage = this.tenantService.timesheetSettings.settings_json.approval_reminder
      this.displayNotificationMessage = true;
    }
    else {
      this.messageService.add({
        severity:'warnning', 
        summary: 'Please select at least one employee'
      })
    }
  }

  sendEmail(){
    let managers: User[] = this.selectedTimesheets.map(el=>{return el.Assignment.Project.ProjectManager})
    console.log(managers)
    for (let tsApp of this.selectedTimesheets){
      let link:string = `${location.origin}/timesheet/approval/${tsApp.id}`
      this.timesheetService.notifySelectedManagers([tsApp.Assignment.Project.ProjectManager], link, this.notificationMessage, {PeriodStartDate:this.selectedWeek.format("L") },
      this.userService.currentUser.id,tsApp.id).subscribe(data=>{
        console.log(data)
        this.messageService.add({severity:'info', summary: 'Email sent'});
      })
    }
    this.displayNotificationMessage = false
    
  }

  getSumByEmployee(tsApp:TimesheetApproval){
    return tsApp.TimeEntries.reduce((prev,te)=> {return prev+te.hours},0)
  }

  seeTimesheet(tsApp:any){
    this.router.navigateByUrl('/timesheet/view/'+tsApp.employee_id)
  }

  approveAllSelected(){
    for (let ts of this.selectedTimesheets){
      ts.status = TimesheetApprovalStatus.Approved
      ts.approved_at = new Date()
      ts.approver_id = this.currentUser.id
      this.timesheetService.updateTimesheetApproval(ts).subscribe(data=> {
        ts = data
        let index = this.timesheetsApprovals.indexOf(ts)
        this.timesheetsApprovals.splice(index)
      })
    }
  }
  rejectionDialog(){
    this.displayRejectionDialog=true
  }
  rejectAllSelected(){
    for (let ts of this.selectedTimesheets){
      ts.status = TimesheetApprovalStatus.Rejected
      ts.rejected_at = new Date()
      ts.rejecter_id = this.currentUser.id
      ts.rejection_comment = this.rejectionComment
      this.timesheetService.updateTimesheetApproval(ts).subscribe(data=>this.messageService.add({severity:'info', summary: 'Timesheet Rejected'}))     

      let link:string = `${location.origin}/timesheet/view/${ts.Assignment.employee_id}`
      this.timesheetService.notifyEmployeeOfRejection([ts.Assignment.Employee],link,{RejectionComment: this.rejectionComment, PeriodStartDate: this.selectedWeek}, this.currentUser.id).subscribe(data=>{
        this.messageService.add({severity:'info', summary: 'Employee Notified'})
        this.displayRejectionDialog = false;
      })
    }
  }
}
