import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { Assignment } from 'src/app/model/Assignment';
import { Project } from 'src/app/model/Project';
// import { Role } from 'src/app/model/Role';
import { Task } from 'src/app/model/Task';
import { User } from 'src/app/model/User';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-assignment-details',
  templateUrl: './dialog-assignment-details.component.html',
  styleUrls: ['./dialog-assignment-details.component.scss']
})
export class DialogAssignmentDetailsComponent implements OnInit {

  new_assignment: Assignment = new Assignment({status:ActiveStatus.Active})

  createForm:FormGroup= this.fb.group({
    'id': [],
    'employee_id':[,Validators.required],
    'position_name':[,],
    'project_id':[,Validators.required],
    'start_date':[,Validators.required],
    'end_date':[,],
    'cost_rate':[,Validators.required],
    'charge_rate':[,Validators.required],
    'status':[,],
  })
  statusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]
  isNew: boolean = true
  users:User[]=[]
  projects:Project[]=[]

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private fb: FormBuilder,
    private messageService: MessageService, public timesheetService:TimesheetService, public userService:UserService) {
      if (config.data && config.data.assignment) {
        this.new_assignment = this.config.data.assignment
        this.isNew = false
      }
      this.createForm.patchValue(this.new_assignment)
    }

  ngOnInit(): void {
    this.timesheetService.getProjectsByCriteria(new Project({status:ActiveStatus.Active})).subscribe(data=>this.projects=data)
    this.userService.getUsersByCriteria(new User({status:ActiveStatus.Active})).subscribe(data=>this.users=data)

    let canEdit = this.userService.checkPermission("Assignments","edit")
    if (!canEdit){this.createForm.disable()}
  }
  cancel(){
    this.ref.close();
  }

  updateRates(){
    if (this.createForm.value.employee_id){
      let selectedUser = this.users.find(user=>user.id==this.createForm.value.employee_id)
      this.createForm.patchValue({
        cost_rate:selectedUser.default_cost_rate,
        charge_rate:selectedUser.default_charge_rate
      })
    }
  }

  onSubmit(form:Assignment){
    if (this.createForm.valid){
      this.new_assignment = form
      if (this.isNew){
        this.timesheetService.createAssignment(form).subscribe(data=>{
          console.log("assignment created")
          this.new_assignment=data
          this.messageService.add({severity: 'success', summary: 'Assignment Created'})
          this.ref.close(this.new_assignment);
        })
      }
      else {
        this.timesheetService.updateAssignment(this.new_assignment).subscribe(data=> {
          console.log("assignment updated");
          this.new_assignment=data
          this.messageService.add({severity: 'success', summary: 'Assignment updated'})
          this.ref.close(this.new_assignment);
        })
      }
    }
  }
    // for mandatory fields
    get employee_id() { return this.createForm.get('employee_id'); }
    get project_id() { return this.createForm.get('project_id'); }
    get cost_rate() { return this.createForm.get('cost_rate'); }
    get charge_rate() { return this.createForm.get('charge_rate'); }
    get start_date() { return this.createForm.get('start_date'); }
}
