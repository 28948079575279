import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment, { Moment } from "moment";
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
// import { Role } from 'src/app/model/Role';
import { TimeEntry } from 'src/app/model/TimeEntry';
import { TimesheetApproval, TimesheetApprovalStatus } from 'src/app/model/TimesheetApproval';
import { User } from 'src/app/model/User';
import { UserService } from 'src/app/services/user.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DialogTimesheetDetailsComponent } from './dialog-timesheet-details/dialog-timesheet-details.component';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { Task } from 'src/app/model/Task';
import { Assignment } from 'src/app/model/Assignment';
import { Table } from 'primeng/table';
import { Project } from 'src/app/model/Project';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit {

  timesheetsApprovals: TimesheetApproval[]=[]
  timeEntries: TimeEntry[]=[]
  groupedTimeEntries : any[]=[]
  managersMap : Map<number,any[]> = new Map<number,any[]>()
  assignments: Assignment[] = []
  //auth0ext_token:string=""

  today:Moment;
  selectedUser:User
  selectedWeek:Moment;
  copyToWeek:Moment;
  weeksArray:Moment[]=[]
  daysArray:Moment[];

  users:User[]=[]
  currentUser:User;
  employee_id:number=null;

  loading:boolean = false
  isEditMode:boolean=false;
  isDetailedMode:boolean = false;

  allowZeroTimeEntries: boolean = false;
  allowNegativeTimeEntries: boolean = false;
  canEdit:boolean = false;
  canAdd: boolean = false;

  projects:Project[]=[]
  selectedProject:Project=new Project({});
  tasks:Task[]=[]
  filteredTasks:Task[]=[]

  ref:DynamicDialogRef;

  @ViewChild('op', {static: false}) overlayPanel;
  @ViewChild('dt') datatable : Table;

  constructor(private timesheetService: TimesheetService,public userService: UserService, 
    public tenantService: TenantService, private route:ActivatedRoute,
    public messageService:MessageService,public dialogService: DialogService,
    public utilsService: UtilsService, 
    private confirmationService: ConfirmationService,
    private breadcrumbService:BreadcrumbService) {
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/dashboard']},
      ]); }

 ngOnInit(): void {
    this.allowZeroTimeEntries = this.tenantService.timesheetSettings.settings_json.allow_zero_time
    this.allowNegativeTimeEntries = this.tenantService.timesheetSettings.settings_json.allow_negative_time

    this.loading=true;
    this.currentUser = this.userService.currentUser
    if (this.canSeeOtherUsers()){
      this.userService.getUsersByCriteria(new User({status:ActiveStatus.Active})).subscribe(data=>{
        this.users=data
        if (!this.userService.checkPermission('All Timesheets - Time Entries','view')){
          this.users = this.users.filter(user=>this.isSupervisedEmployee(user))
        }        
        this.employee_id = parseInt(this.route.snapshot.paramMap.get('employee_id')) || null;
        if (this.employee_id == null || this.employee_id == undefined) this.selectedUser=this.users.find(el=>el.id==this.currentUser.id)
        else this.selectedUser=this.users.find(el=>el.id==this.employee_id)
        this.updateTimesheets()
      })
    }

    //setting first day of the week
    let firstDay = this.tenantService.timesheetSettings.settings_json.week_start
    this.utilsService.updateFirstDayOfTheWeek(firstDay)
    this.today = moment().startOf('day');
    this.selectedWeek = this.getFirstDayOfWeek(this.today);
    this.copyToWeek = this.selectedWeek.add(7,'day')
    this.daysArray = this.getDaysOfTheWeekArray(this.selectedWeek)

    this.updateWeeksArray()
    this.selectedWeek = this.weeksArray.find(el=>this.isCurrentWeek(el))

    this.timesheetService.getTasksByCriteria(new Task({status:ActiveStatus.Active})).subscribe(data=>{
      this.tasks=data
      this.filteredTasks = this.tasks;
    })

  }

  checkPermissions(){
    // check if the timesheet has been locked down
    let lockdownDate = moment(this.tenantService.timesheetSettings.settings_json.lockdown_date)
    if (this.selectedWeek<=lockdownDate) {this.canEdit = false; this.canAdd = false;}
    else {
      this.canAdd = this.checkCanAdd()
      this.canEdit = this.checkCanEdit()
    }
  }

  checkCanEdit(){
    if (this.selectedUser.id==this.currentUser.id) return this.userService.checkPermission('Own Timesheets - Time Entries', 'edit')
    else if (this.isSupervisedEmployee(this.selectedUser)) return this.userService.checkPermission('Supervised Timesheets - Time Entries', 'edit')
    else return this.userService.checkPermission('All Timesheets - Time Entries', 'edit')
  }

  checkCanAdd(){
    if (this.selectedUser.id==this.currentUser.id) return this.userService.checkPermission('Own Timesheets - Time Entries', 'add')
    else if (this.isSupervisedEmployee(this.selectedUser)) return this.userService.checkPermission('Supervised Timesheets - Time Entries', 'add')
    else return this.userService.checkPermission('All Timesheets - Time Entries', 'add')
  }

  isSupervisedEmployee(user:User){
    for (let ass of this.assignments){
      if (ass.employee_id == user.id && ass.Project.manager_id == this.currentUser.id 
        && ass.start_date<=this.selectedWeek.add(7,'days').toDate() && ass.end_date>=this.selectedWeek.toDate()){
          return true
        }
    }
    return false
  }

  canSeeOtherUsers(){
    return this.userService.checkAtLeastOnePermission(['Supervised Timesheets - Time Entries','All Timesheets - Time Entries'])
  }

  updateWeeksArray(){
    let toSubstract = this.tenantService.timesheetSettings.settings_json.past_weeks_shown
    let toAdd = this.tenantService.timesheetSettings.settings_json.future_weeks_shown
    let minDate:Moment = moment().subtract(toSubstract, 'week')
    let maxDate :Moment = moment().add(toAdd,'week')
    this.weeksArray = this.getWeeksArray(minDate,maxDate)
    this.weeksArray.sort((a:Moment,b:Moment)=>a.isBefore(b)?1:-1)
  }

  updateTimesheets(){
    this.loading=true;
    if (this.selectedWeek){
      this.daysArray = this.getDaysOfTheWeekArray(this.selectedWeek)
      // console.log(this.daysArray)
    }
    let startDate = moment(this.selectedWeek)

    // check if the timesheet has been locked down
    let lockdownDate = moment(this.tenantService.timesheetSettings.settings_json.lockdown_date)
    
    if (startDate<=lockdownDate) {this.canEdit = false; this.canAdd = false;}
    this.timesheetService.getAssignmentByCriteria(new Assignment({employee_id:this.selectedUser.id})).subscribe(data=>{
      this.assignments=data
      this.filterProjects()
    })
    this.timesheetService.getTimesheetsByStartDate(startDate,this.selectedUser.id).subscribe(data=>{
      console.log(data)
      this.timesheetsApprovals = data
      this.groupedTimeEntries = []
      this.timeEntries = []

      for (let tsApp of this.timesheetsApprovals){
        if (!this.userService.checkPermission('All Timesheets - Time Entries', 'view') && tsApp.Assignment.Project.manager_id != this.currentUser.id){
          break;
        }
        this.timeEntries = this.timeEntries.concat(tsApp.TimeEntries)
        for (let timeEntry of tsApp.TimeEntries){
          let tsDate = moment(timeEntry.date).format("L")
          let index = this.groupedTimeEntries.findIndex(el=>(el.Task.id==timeEntry.Task.id && (timeEntry.description == null || el[tsDate].description == null || el[tsDate].description == ""))) // && el.Assignment.id == timeEntry.Assignment.id)
          // if there is already a record and descriptions are compatible
          if (index>-1 ){
            this.groupedTimeEntries[index][tsDate]= 
            { 
              hours: (this.groupedTimeEntries[index][tsDate]?this.groupedTimeEntries[index][tsDate].hours:0)+ timeEntry.hours,
              description : (this.groupedTimeEntries[index][tsDate]?this.groupedTimeEntries[index][tsDate].description||"":"") + timeEntry.description,
              existingRecord : true
            }
          } else {
            let newRow = {Task: timeEntry.Task,TimesheetApproval:tsApp}
            for (let day of this.daysArray){
              newRow[day.format("L")]={hours:0,existingRecord:false}
            }
            newRow[tsDate] = {hours:timeEntry.hours,description:timeEntry.description,existingRecord : true}
            console.log(newRow)
            this.groupedTimeEntries.push(newRow)
          }
        }
      }
      this.loading = false
      console.log(this.groupedTimeEntries)
    })
    this.checkPermissions()
    if (this.isEditMode) this.newRow()
  }

  copyTimesheet(){
    // copy all time entries for the period to the week selected in the overlay panel
    let week_offset = moment(this.copyToWeek).diff(this.selectedWeek,'weeks')
    let promises = this.timeEntries.map(te=>{
      let teToCreate = new TimeEntry(te) // new object such as not to modify existing time entry
      teToCreate.date = moment(te.date).add(week_offset, 'weeks').toDate()
      let assignment_id = this.timesheetsApprovals.find(tsapp=>tsapp.id==te.timesheet_approval_id).assignment_id
      console.log("creating time entry for", te.Task.task_name)
      return new Promise(resolve => this.timesheetService.createTimeEntry(teToCreate,assignment_id).subscribe(data=>{console.log(data);resolve(data)}))
    })

    Promise.all(promises).then(data =>
      {
        this.messageService.add({severity: 'success', summary: 'Weekly timesheet successfully copied'})
        this.overlayPanel.hide()
      })
  }

  overrideExisitingTimeEntries(){
    this.timesheetService.deleteTimesheetsByStartDate(this.copyToWeek, this.selectedUser.id).subscribe(data=>{console.log(data)})
    this.copyTimesheet()
  }

  confirmOverride() {
    // check if there are existing time entries for the selected period
    this.timesheetService.getTimesheetsByStartDate(this.copyToWeek, this.selectedUser.id).subscribe(data=>{
      // if there are, check if the user wants to override them
      if (data.length>0){
        this.confirmationService.confirm({
            message: 'Do you want to override existing time entries for the selected week?',
            header: 'Override Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.overrideExisitingTimeEntries()
          },
          reject: (type) => {
              switch(type) {
                  case ConfirmEventType.REJECT:
                      this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
                      this.copyTimesheet()
                  break;
                  case ConfirmEventType.CANCEL:
                      this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
                  break;
              }
          }
      });
      } else {
        this.copyTimesheet()
      }
    })
  }

  canSubmit(){
    return this.timesheetsApprovals.length>0 && (this.timesheetsApprovals[0].status == TimesheetApprovalStatus.Pending || this.timesheetsApprovals[0].status == TimesheetApprovalStatus.Rejected)
  }

  canUnlock(){
    return this.timesheetsApprovals.length>0 && (this.timesheetsApprovals[0].status == TimesheetApprovalStatus.Submitted || this.timesheetsApprovals[0].status == TimesheetApprovalStatus.Approved)
  }

  canBeEdited(){
    return this.canEdit && (this.timesheetsApprovals.length==0  || this.timesheetsApprovals[0].status == TimesheetApprovalStatus.Pending || this.timesheetsApprovals[0].status == TimesheetApprovalStatus.Rejected)
  }

  getDaysOfTheWeekArray(start):Moment[] {
    return this.utilsService.getDaysOfTheWeekArray(start)
  };

  getWeeksArray(start, end):Moment[] {
    return this.utilsService.getWeeksArray(start,end)
  };

  getFirstDayOfWeek(m:Moment,firstDay?:String):Moment {
    return this.utilsService.getFirstDayOfWeek(m)
  }

  isCurrentWeek(week:Moment){
    return this.utilsService.isCurrentWeek(week,this.today)
  }

  addTimesheet(day:Moment){
    let timeEntry = new TimeEntry({date:day?day.toDate():null})
    this.ref = this.dialogService.open(DialogTimesheetDetailsComponent, {
      header: 'Time entry',
      width: '70%',
      data: {
        timeEntry:timeEntry,
        user:this.selectedUser,
        selectedWeek: moment(this.selectedWeek).toDate(),
        endOfWeek: moment(this.selectedWeek).add(6,'day').toDate()
      }
    });
    this.ref.onClose.subscribe((timeEntry: TimeEntry) =>{
      if (timeEntry) {
        this.updateTimesheets()
        this.messageService.add({severity:'success', summary: 'Time Entry Created'});
      }
    });
  }

  // adds empty row to the groupedTimeEntries object
  addRow(){
    let task = new Task({})
    task.project_id = 0
    let newRow = {Task:  task,TimesheetApproval:new TimesheetApproval({})}
    for (let day of this.daysArray){
      newRow[day.format("L")]={hours:0,description:"",existingRecord:false}
    }
    this.groupedTimeEntries.push(newRow)
    console.log(this.groupedTimeEntries)
  }

  deleteTimeEntry(ts:any, date: Moment){
    //let timeEntry = this.timeEntries.find(el=>el.task_id == ts.Task.id && moment(el.date).format('L') == date.format('L'))
    // this.timesheetService.deleteTimeEntry(timeEntry.id).subscribe(data=>{
    //   console.log(data)
    //   this.messageService.add({severity:'success', summary: 'Time Entry Deleted'})
      ts[date.format('L')]={hours:0,description:"", existingRecord:false}
    // })
  }

  markAsModified(ts:any, date: Moment){
    if (!this.allowNegativeTimeEntries && ts[date.format('L')].hours<0){
      this.messageService.add({severity: 'error', summary: 'Negative entries are not allowed'})
    } else if (!this.allowZeroTimeEntries && ts[date.format('L')].hours==0){
      this.messageService.add({severity: 'error', summary: 'Null entries are not allowed'})
    } else {
      ts[date.format('L')].existingRecord = true
    }
  }

  save(){
    //deleting existing timesheets
    let promises_deletes = this.timeEntries.map(te=>{
      let corresponding_ts = this.groupedTimeEntries.find(el=>el.Task.id == te.Task.id)
      if (!corresponding_ts[moment(te.date).format("L")].existingRecord){
        console.log("deleting time entry for", corresponding_ts.Task.task_name)
        return new Promise(resolve => this.timesheetService.deleteTimeEntry(te.id).subscribe(data=> resolve(data)))
      }
    })

    //updating existing timesheets
    let promises_updates = this.timeEntries.map(te=>{
      let new_ts = this.groupedTimeEntries.find(el=>el.Task.id == te.Task.id)
      if (new_ts[moment(te.date).format("L")].existingRecord &&(te.hours != new_ts[moment(te.date).format("L")].hours || te.description != new_ts[moment(te.date).format("L")].description)){
        te.hours = new_ts[moment(te.date).format("L")].hours
        te.description = new_ts[moment(te.date).format("L")].description
        console.log("updating time entry for", new_ts.Task.task_name)
        return new Promise(resolve => this.timesheetService.updateTimeEntry(te).subscribe(data=> resolve(data)))
      }
    })
    
    //creating new timesheets
    let promises_creation = this.groupedTimeEntries.reduce((array,ts) => {
      let ts_promises = []
      for (let att in ts){
        if (att!="TimesheetApproval" && att!="Task"&& att!="status"){
          if ((ts[att].existingRecord) && this.timeEntries.some(old_ts=>old_ts.task_id == ts.Task.id && moment(old_ts.date).format('L')==att)==false){
            let new_ts = new TimeEntry({task_id:ts.Task.id, TimesheetApproval: ts.TimesheetApproval, date:moment(att).toDate(),hours:ts[att].hours,description:ts[att].description})
            console.log("creating time entry for", ts.Task.task_name)
            ts_promises.push(new Promise(resolve => this.timesheetService.createTimeEntry(new_ts, ts.TimesheetApproval.assignment_id).subscribe(data=> resolve(data))))
          }
        }
      }
      return array.concat(ts_promises)
    },[])
    Promise.all(promises_creation.concat(promises_updates.concat(promises_deletes))).then(data=>
      {
        this.messageService.add({severity: 'success', summary: 'Weekly timesheet updated'})
        this.updateTimesheets();
      })
  }

  cancel(){
    this.updateTimesheets();
    this.isEditMode=false;
    this.isDetailedMode = false;
  }

  submitTimesheet(){
    this.managersMap = new Map<number,any[]>()
    //group by manager and send email
    for (let tsApp of this.timesheetsApprovals){     
        tsApp.submitted_at = new Date()
        tsApp.submitter_id = this.selectedUser.id
        if (tsApp.Assignment){
          tsApp.status = TimesheetApprovalStatus.Submitted
          let tsApproverId = tsApp.Assignment.Project.timesheet_approver_id?tsApp.Assignment.Project.timesheet_approver_id:tsApp.Assignment.Project.manager_id
          let gpTs = this.managersMap.get(tsApproverId) || []
          gpTs.push(tsApp)
          this.managersMap.set(tsApp.Assignment.Project.manager_id,gpTs)
        } else { 
          // draft: if there is no assignment, the timesheet directly goes to Approved
          tsApp.status = TimesheetApprovalStatus.Approved
        }
      this.timesheetService.updateTimesheetApproval(tsApp).subscribe(data=>{
        console.log("timesheet updated")
        this.updateTimesheets()
      })
    }

    for (let manager_id of this.managersMap.keys()){
      let manager = this.users.find(user=>user.id==manager_id)
      let ids_string = this.managersMap.get(manager_id).map(tsApp=>tsApp.id).toString()
      let link:string = `${location.origin}/timesheet/approval/${ids_string}/${this.selectedUser.id}`
      this.timesheetService.notifyManagerOfNewSubmission([manager],link,{PeriodStartDate: this.selectedWeek.format("L")},this.currentUser.id).subscribe(data=>console.log("manager notified"))
    }  
  }

  unlockTimesheet(){
    for (let tsApp of this.timesheetsApprovals){
      tsApp.status = TimesheetApprovalStatus.Pending
      this.timesheetService.updateTimesheetApproval(tsApp).subscribe(data=>console.log("timesheet updated"))
    }
    this.newRow()
  }

  getDailySumByProject(te,date){
    let sum:number=0
    //this.groupedTimeEntries.map(gte=>{if(gte.Task.project_id !=0 && gte.TimesheetApproval.Assignment.project_id == te.TimesheetApproval.Assignment.project_id && gte[date]) sum+=gte[date].hours})
    this.groupedTimeEntries.map(gte=>{if(gte.Task.project_id && gte.Task.project_id == te.Task.project_id && gte[date]) sum+=gte[date].hours})
    
    return sum
  }

  getSumByProject(project_id){
    let sum:number=0
    this.groupedTimeEntries.map(gte=>{
      if (gte.Task.project_id !=0 && gte.Task.project_id == project_id){
        for (let att in gte){
          if (att!="TimesheetApproval" && att!="Task"&& att!="status") sum+=gte[att].hours
        }
      }      
    })
    return sum
  }

  getSumByAssignment(groupedTE){
    let sum:number=0
    this.groupedTimeEntries.map(gte=>{
      if (gte.Task.project_id !=0 && gte.TimesheetApproval.id == groupedTE.TimesheetApproval.id){
        for (let att in groupedTE){
          if (att!="TimesheetApproval" && att!="Task"&& att!="status") sum+=groupedTE[att].hours
        }
      }      
    })
    return sum
  }

  getSumByDay(date:string){
    let sum:number=0
    this.groupedTimeEntries.map(ts=>{
      sum+=ts[date]?ts[date].hours:0
    })
    return sum
  }

  getGrandTotal(){
    let sum:number = 0
    this.groupedTimeEntries.map(ts=>{
      for (let att in ts){
        if (att!="TimesheetApproval" && att!="Task"&& att!="status") sum+=ts[att].hours
      }
    })
    return sum
  }

  goToWeeklyView(){
    this.isDetailedMode = true;
    if (this.canBeEdited()){
      this.isEditMode=true;
      this.newRow();
    }
  }

  goToReadOnlyView(){
    this.isDetailedMode = false
    this.isEditMode = false;
  }

  addRowIfComplete(row){
    if (row.Task.id){
      // add corresponding assignment 
      let assignment = this.assignments.find(ass=>ass.project_id == row.Task.project_id && ass.employee_id == this.selectedUser.id)
      row.TimesheetApproval.Assignment = assignment
      row.TimesheetApproval.assignment_id = assignment.id
      this.groupedTimeEntries.push(Object.assign(row))
      // add new row
      this.newRow(row)

      // make sure the project for the new row is expanded
      //let index = this.groupedTimeEntries.findIndex(group=>group.Task.project_id == row.Task.project_id)+1
      this.datatable.expandedRowKeys[row.Task.id] = true
      console.log(this.datatable.expandedRowKeys)
    }
  }

  filterTasks(){
    this.filteredTasks = this.tasks.filter(el=>el.project_id==this.selectedProject.id)
  }

  filterProjects(){
    this.projects = []
    for (let ass of this.assignments){
      if ((!this.projects.some(p=>p.id==ass.project_id)) && new Date(ass.start_date)<= this.daysArray[0].toDate() && (!ass.end_date || new Date(ass.end_date)>=this.daysArray[0].toDate())) {this.projects.push(ass.Project)}
    } 
  }

  // push a new row in the table object
  newRow( assignment?:Assignment, newRow={}) {
    // initialize new row
    newRow = {Task: new Task({}),TimesheetApproval:new TimesheetApproval({status:TimesheetApprovalStatus.Pending ,assignment_id:assignment?assignment.id:null})}
    for (let day of this.daysArray){
      newRow[day.format("L")]={hours:0,existingRecord:false}
    }
    // Insert
    this.datatable.value.push(newRow);
    // Set the new row in edit mode
    this.datatable.initRowEdit(newRow);
    return newRow
  }

  
}
