import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  constructor(public app:AppComponent, public userService: UserService) { }

  model:any[]


  ngOnInit(): void {
    this.model = [
      {
          label: 'Modules',
          items: [
              {label: 'Account Settings', icon: 'pi pi-fw pi-cog', routerLink: ['/admin/users'], active:true},
              {label: 'Time Tracking', icon: 'pi pi-fw pi-calendar', routerLink: ['/timesheet/view']},
              {label: 'Cost Control', icon: 'pi pi-fw pi-money-bill'},
              {label: 'Risk Management', icon: 'pi pi-fw pi-bolt'},
          ]
      },
      {
        label: 'Help Center'
      }
    ]
  }

  

}
