<p-table #dt [value]="permissions" [columns] = "actionColumns" responsiveLayout="scroll" [loading]="loading"
    rowGroupMode="subheader" groupRowsBy="module_id" >
    <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
            <div style="flex-direction: row;display: flex;">
                <h3>Roles</h3>
                <button *ngIf = "canAdd" pButton class="p-button-success ml-4 mb-3" (click)="addRole()" label="Create"  icon="pi pi-plus"></button>
            </div>
           <p-toolbar>
                <ng-template pTemplate="left">
                    <p-dropdown [options]="roles" [(ngModel)]="selectedRole" optionLabel="label" (onChange)="updatePermissions()"
                        class="mr-2" autoDisplayFirst="false"></p-dropdown>
                    <button pButton type="button" icon="pi pi-pencil" (click)="editRole()" class="p-button-warning mr-2" pTooltip="Edit" tooltipPosition="bottom" [disabled]="!canEdit"></button>
                    <button pButton type="button" icon="pi pi-trash" (click)="deleteRole()" class="p-button-danger mr-2" pTooltip="Delete" tooltipPosition="bottom" [disabled]="!canDelete"></button>        
                    
                </ng-template>

                <ng-template pTemplate="right">  
                    <button pButton type="button" icon="pi pi-refresh" (click)="updatePermissions()" class="p-button-primary mr-2" pTooltip="Refresh" tooltipPosition="bottom"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th></th>
            <th *ngFor="let col of columns">
                {{col.label}}
                <!-- <button *ngIf = "canEdit" pButton type="button" (click)="editRole(rowData)" icon="pi pi-pencil" ></button>           -->
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-permission let-columns="columns">
        <tr pRowGroupHeader style="background: antiquewhite;">
            <td>
                <i [class]="permission.Module.icon"></i>
                <span class="font-bold ml-2">{{permission.Module?permission.Module.name:""}}</span>
            </td>
            <td *ngFor="let col of columns"></td>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td>{{rowData.label}}</td>
            <td *ngFor="let col of columns">
                <div class="field-checkbox">
                    <p-checkbox *ngIf = "rowData[col.has_key] && rowData.rolePermission" [(ngModel)]="rowData.rolePermission[col.can_key]" [binary]="true" inputId="binary" [disabled]="!canEdit"></p-checkbox>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<div style="display:flex;justify-content:end;">
    <button *ngIf="canEdit" (click)="save()" pButton label="Save" class="p-button-raised mr-2 mt-2"></button> 
</div>

<p-dialog header="Role Details" [(visible)]="displayDialog" modal="modal" showEffect="fade" [style]="{width: '400px'}">
    <form [formGroup]="createForm" class="formgrid grid" (ngSubmit)="onSubmit(createForm.value)" >   
        <div class="field col-12 md:col-12">
            <label>Label: </label>
            <input type="text" pInputText class="w-full" formControlName="label" required="true" name="label"/>
            <div *ngIf="label.invalid && (label.dirty || label.touched)" class="alert alert-danger">
                <div *ngIf="label.errors?.required">Label is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-12">
            <label>Description: </label>
            <input type="text" pInputText class="w-full" formControlName="description" name="description"/>
        </div>
    </form>
    <p-footer>
        <button pButton type="button" (click)="hideDialog()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
        <button type="submit" (click)="onSubmit(createForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 
    </p-footer>
</p-dialog>
