import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { Auth0Role } from 'src/app/model/Auth0Role';
import { Project } from 'src/app/model/Project';
import { Task } from 'src/app/model/Task';
import { User } from 'src/app/model/User';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-task-details',
  templateUrl: './dialog-task-details.component.html',
  styleUrls: ['./dialog-task-details.component.scss']
})
export class DialogTaskDetailsComponent implements OnInit {

  new_task: Task = new Task({status:ActiveStatus.Active, chargeable: true})
  projects:Project[]=[]
  createForm:FormGroup= this.fb.group({
    'id': [],
    'task_name':[,Validators.required],
    'project_id':[,Validators.required],
    'description':[],
    'notes':[],
    'reference':[],
    'budget_amount':[],
    'budget_hours':[],
    'chargeable':[,Validators.required],
    'status':[,Validators.required]
  })
  isNew: boolean = true
  statusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]
  booleanOptions = [true,false]

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private fb: FormBuilder,
    private messageService: MessageService, public timesheetService:TimesheetService, private userService:UserService) {
      if (config.data && config.data.task) {
        this.new_task = this.config.data.task
        this.isNew = false
      }
      console.log(this.new_task)
      this.createForm.patchValue(this.new_task)
    }

  ngOnInit(): void {
   this.timesheetService.getProjectsByCriteria(new Project({status:ActiveStatus.Active})).subscribe(data=>this.projects=data)

   let canEdit = this.userService.checkPermission("Tasks","edit")
   if (!canEdit){this.createForm.disable()}
  }
  cancel(){
    this.ref.close();
  }

  onSubmit(form:Task){
    if (this.createForm.valid){
      this.new_task = form

      if (this.isNew){
        this.timesheetService.createTask(form).subscribe(data=>{
          this.new_task=data
          this.ref.close(this.new_task);
        })
      }
      else {
        this.timesheetService.updateTask(this.new_task).subscribe(data=> {
          console.log(data)
          this.ref.close(this.new_task);
        })
      }
    }
  }
    // for mandatory fields
    get task_name() { return this.createForm.get('task_name'); }
    get project_id() { return this.createForm.get('project_id'); }
}
