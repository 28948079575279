export class Currency {
    id:number;
    name: string;
    tenant_id: number;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(currency){
        this.name = currency.name || null
    }
}