import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timeStamp } from 'console';
import { Report } from 'src/app/model/Report';
import { ReportType } from 'src/app/model/ReportType';
import { TimesheetService } from 'src/app/services/timesheet.service';

@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.scss']
})
export class AddReportComponent implements OnInit {

  constructor(private fb: FormBuilder, public timesheetService:TimesheetService) { }

  createReportForm:FormGroup= this.fb.group({
    'id': [],
    'report_name':[,Validators.required],
    'report_type_id':[,Validators.required],
    'description':[],
  })

  createReportTypeForm:FormGroup= this.fb.group({
    'id': [],
    'report_type_name':[,Validators.required],
    'description':[],
    'icon':[],
  })

  reportTypes:ReportType[]=[]

  ngOnInit(): void {
    this.timesheetService.getAllReportTypes().subscribe(data=>this.reportTypes=data)
  }

  submitReport(report:Report){
    this.timesheetService.createReport(report).subscribe(data=> console.log("report successfully created"))
  }

  submitReportType(reportType:ReportType){
    this.timesheetService.createReportType(reportType).subscribe(data=> {
      console.log("report type successfully created")
      this.reportTypes.push(data)
    })
  }

  get report_name() { return this.createReportForm.get('report_name'); }
  // get task_id() { return this.createForm.get('task_id'); }
  get report_type_name() { return this.createReportTypeForm.get('report_type_name'); }
  get report_type_id() { return this.createReportForm.get('report_type_id'); }
}
