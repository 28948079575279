<p-toast></p-toast>
<div class="card">
    <ng-template pTemplate="title" class="card-header">
       Company Details
    </ng-template>
    <form [formGroup]="createForm" class="formgrid grid" (ngSubmit)="onSubmitForms()">   
        <div class="field col-12 md:col-10">
            <label>Company Name: </label>
            <input type="text" pInputText class="w-full" formControlName="company" required="true" name="company"/>
            <div *ngIf="company.invalid && (company.dirty || company.touched)" class="alert alert-danger">
                <div *ngIf="company.errors?.required">Company name is required.</div>
            </div>
        </div>
    </form>
</div>
<div class="card">
    <ng-template pTemplate="title" class="card-header">
        Account Owner
    </ng-template>
    <form [formGroup]="userForm" class="formgrid grid" (ngSubmit)="onSubmitForms()">   
        <div class="field col-12 md:col-5">
            <label>Owner Name: </label>
            <input type="text" pInputText class="w-full" formControlName="name" required="true" name="name"/>
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors?.required">Name is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Owner Email: </label>
            <input type="text" pInputText class="w-full" formControlName="email" required="true" name="email"/>
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                <div *ngIf="email.errors?.required">Email is required.</div>
            </div>
        </div>
    </form>
</div>
<button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary" label="Cancel"></button>
<button type="submit" (click)="onSubmitForms()" pButton label="Save" ></button>