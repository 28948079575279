import {Module} from "./Module";
import { TenantLicense } from "./TenantLicense";
import { User } from "./User";

export enum TenantLicenseStatus {
    Active = "Active",
    Inactive = "Inactive"
}

export class License {
    id:number;
    name: string;
    monthly_cost_per_user: number;
    Module:Module;
    module_id: Number;
    duration: number;
    max_users: number;

    users:User[];

    TenantLicenses:TenantLicense[];

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(module){
        this.name = module.name || null;

    }
}