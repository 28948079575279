
<p-toast></p-toast>
<p-table [value]="groupedTimeEntries" responsiveLayout="scroll" [loading]="loading"
#dt responsiveLayout="scroll" [columns]="daysArray" emptymessage="No timesheet for this period"
rowGroupMode="subheader" groupRowsBy="Task.project_id"   dataKey="Task.project_id">
    <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
            <div style="flex-direction: row;display: flex;">
                <h3>Timesheets</h3>
                <button *ngIf = "canAdd" pButton class="p-button-success ml-4 mb-3" (click)="addTimesheet()" label="Time entry"  icon="pi pi-plus"></button>
            </div>
            <p-toolbar>
                <ng-template pTemplate="left">
                    <p-dropdown [options]="users" [(ngModel)]="selectedUser" optionLabel="name" [filter]="true" filterBy="name, email" (onChange)="updateTimesheets()"
                        placeholder="Employee" class="mr-2"></p-dropdown>
                    <p-dropdown [options]="weeksArray" [(ngModel)]="selectedWeek" placeholder="Period Start Date" (onChange)="updateTimesheets(dt)" class="mr-2">
                        <ng-template pTemplate="selectedItem">
                            <div style="display: inline-flex;">
                                <div *ngIf="isCurrentWeek(selectedWeek)" style="margin-right: 10px;"><i style="color:rgb(74, 119, 170)" class="pi pi-calendar"></i></div>
                                <div>{{selectedWeek | date:'dd MMM YYYY'}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-week pTemplate="item">
                            <div style="display: inline-flex;">
                                <div *ngIf="isCurrentWeek(week)" style="margin-right: 10px;"><i style="color:rgb(74, 119, 170)" class="pi pi-calendar"></i></div>
                                <div>{{week | date:'dd MMM YYYY'}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton type="button" class="p-button-primary mr-2" icon="pi pi-clone" (click)="op.show($event)" label="Copy Timesheet"></button>
                    <p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true" appendTo="body">
                        <ng-template pTemplate style="padding: 0.8rem">
                            <p-dropdown [options]="weeksArray" [(ngModel)]="copyToWeek" placeholder="Period Start Date" (onChange)="copyTimesheet()" class="mr-2">
                                <ng-template pTemplate="selectedItem">
                                    <div style="display: inline-flex;">
                                        <div *ngIf="isCurrentWeek(copyToWeek)" style="margin-right: 10px;"><i style="color:rgb(74, 119, 170)" class="pi pi-calendar"></i></div>
                                        <div>{{copyToWeek | date:'dd MMM YYYY'}}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-week pTemplate="item">
                                    <div style="display: inline-flex;">
                                        <div *ngIf="isCurrentWeek(week)" style="margin-right: 10px;"><i style="color:rgb(74, 119, 170)" class="pi pi-calendar"></i></div>
                                        <div>{{week | date:'dd MMM YYYY'}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <button *ngIf= "copyToWeek!=selectedWeek" (click)="confirmOverride()" pButton class="p-button-raised p-button-success mb-1" icon = "pi pi-check"></button> 
                        </ng-template>
                    </p-overlayPanel>
                    <button pButton type="button" class="p-button-primary mr-2" icon="pi pi-refresh" (click)="updateTimesheets()"></button>
                    <button *ngIf="isEditMode" pButton type="button" (click)="cancel()" class="p-button-secondary mr-2" label="Cancel" icon = "pi pi-times"></button>
                    <button *ngIf="isEditMode" (click)="save()" pButton label="Save" class="p-button-success" icon = "pi pi-save"></button> 
                </ng-template>
            </p-toolbar>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr class="timesheetTable">
            <th style="width:1rem;"></th>
            <th style="width:3%;"></th>
            <th style="width:12%;">
                <button *ngIf="!isDetailedMode && canBeEdited()" pButton label="Edit" icon="pi pi-pencil" (click)="goToWeeklyView()"></button>
                <button *ngIf="!isDetailedMode && !canBeEdited()" pButton label="Details" icon="pi pi-eye" (click)="goToWeeklyView()"></button>
                <!-- <ng-template #assignmentTitle><button pButton label="Week" icon="pi pi-eye" (click)="goToReadOnlyView()"></button></ng-template> -->
            </th>
            <th *ngFor="let day of daysArray" style="width:11%;">
                <button *ngIf="isEditMode && canAdd; else staticHeader" pButton (click)="addTimesheet(day)" label="{{day | date:'EEE dd MMM'}}"
                 style="width: 90%;">
                </button>
                <ng-template #staticHeader>
                    <div class="day-header">
                        {{day | date:'EEE dd MMM'}}
                    </div>
                </ng-template>
            </th>
            <th style="width:6%;"><div class="total-header">Total</div></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-te let-expanded="expanded" >
        <tr class = "group-header">
            <td colspan="2" *ngIf = "!te.Task.id else statusChevron">
                <p-dropdown  styleClass="w-full" [(ngModel)]="selectedProject" [ngModelOptions]="{standalone: true}" [options]="projects" [filter]="true" filterBy="project_name, project_number" optionLabel="project_name"
            placeholder="Select..." (onChange)="filterTasks()" appendTo="body" [style]="{'width':'100%'}" class="small-dropdown"></p-dropdown>
            </td>
            <ng-template #statusChevron>
                <td>
                    <button *ngIf="isDetailedMode && te.Task.id" type="button" pButton pRipple [pRowToggler]="te" class="p-button-text p-button-rounded p-button-plain" style="max-width:1rem; max-height: 1rem;" [icon]="expanded ? 'pi pi-caret-down' : 'pi pi-caret-right'"></button>               
                </td>
                <td> 
                    <span [class]="'timesheet-status-' + te.TimesheetApproval.status.toLowerCase()">{{te.TimesheetApproval.status}}</span>
                </td>
            </ng-template>
            <td>
                <p-dropdown *ngIf = "!te.Task.id; else projectName" styleClass="w-full" [options]="filteredTasks" [(ngModel)]="te.Task" [ngModelOptions]="{standalone: true}" [filter]="true" filterBy="task_name" optionLabel="task_name"
                placeholder="Select..." appendTo="body" [style]="{'width':'100%'}" (onChange)="addRowIfComplete(te)" class="small-dropdown"></p-dropdown>
                <ng-template #projectName>{{te.TimesheetApproval.Assignment.Project.project_name}}</ng-template>
            </td>
            
            <td *ngFor="let day of daysArray">
                {{getDailySumByProject(te,day.format('L'))}}
            </td>
            <td style="text-align: center">{{te.Task.id?getSumByProject(te.TimesheetApproval.Assignment.project_id):0}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-te>
        <tr *ngIf="isDetailedMode && isEditMode && te.Task.id" class = "expanded-row">
            <td></td>
            <td colspan="2">{{te.Task.task_name}}</td>
            <td *ngFor="let day of daysArray" pEditableColumn>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="number" class="table-input-hours" [(ngModel)]="te[day.format('L')].hours" showButtons="false" (input)="markAsModified(te,day)"/>
                        <input class="table-input-description" type="text" pInputText [(ngModel)]="te[day.format('L')].description" placeholder="Description" (input)="markAsModified(te,day)"/>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <div *ngIf="te[day.format('L')]">
                            <div style="flex-direction:row;display: flex;place-content: center;">
                                <div class="table-input-hours">{{te[day.format('L')].hours}}</div>
                                <span *ngIf="te[day.format('L')].description" class="table-input-description">{{te[day.format('L')].description.length>10?te[day.format('L')].description.substring(0,10) + '...':te[day.format('L')].description}}</span>
                                <button *ngIf="te[day.format('L')].existingRecord" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text delete-icon p-button-danger mt-1" (click)="deleteTimeEntry(te,day)" ></button>
                            </div>                    
                        </div>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td style="text-align: center">{{getSumByAssignment(te)}}</td>
        </tr>
        <tr *ngIf="isDetailedMode && !isEditMode">
            <td></td>
            <td></td>
            <td>{{te.Task.task_name}}</td>
            <td *ngFor="let day of daysArray" pEditableColumn>
                <div class="table-input-hours">{{te[day.format('L')].hours}}</div>
                <span class="table-input-description">{{te[day.format('L')].description}}</span>
            </td>
            <td style="text-align: center">{{getSumByAssignment(te)}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="footer" >
        <tr class="project-footer">
            <!-- <td><button *ngIf="isEditMode && canAdd" pButton class="add-icon" (click)="addRow()" icon="pi pi-plus" ></button></td> -->
            <td></td>
            <td></td>
            <td>Total</td>
            <td *ngFor="let day of daysArray">
                {{getSumByDay(day.format('L'))}}
            </td>
            <td> {{getGrandTotal()}}</td>
        </tr>
    </ng-template>
</p-table>

<div style="display:flex;justify-content:end;">
    <button *ngIf="canUnlock()" (click)="unlockTimesheet()" pButton label="Unlock" class="p-button-raised p-button-plain mr-2 mb-2" icon = "pi pi-unlock"></button> 
    <button *ngIf="canSubmit()" (click)="submitTimesheet()" pButton label="Submit" class="p-button-raised p-button-warning mr-2 mb-2" icon = "pi pi-check"></button> 
</div>

<button *ngIf="isEditMode" pButton type="button"icon="pi pi-plus" class="ui-button-info" label="Add"
(click)="newRow()"></button>
  
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>