import { Injectable } from '@angular/core';
import { ImportLog } from '../model/ImportLog';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { Organisation } from '../model/Organisation';
import { Project } from '../model/Project';
import { TimeEntry } from '../model/TimeEntry';
import { Assignment } from '../model/Assignment';
import { Task } from '../model/Task';
import { TimesheetApproval } from '../model/TimesheetApproval';
import { User } from '../model/User';

import { environment } from './../../environments/environment';
import { TenantService } from './tenant.service';



const headers = new HttpHeaders({
'Access-Control-Allow-Origin':'*',
});

@Injectable({
  providedIn: 'root'
})

export class ImportService {

  constructor(private http: HttpClient, public tenantService: TenantService) { }
  //private api_endpoint = environment.apiUrl+ "/" + this.tenantService.encodedTenant + "/"

  public updateImportLog(importLog:ImportLog): Observable<ImportLog> {
    return this.http.put<ImportLog>(this.tenantService.api_url+"/import-log",importLog,{headers:headers})
  }

  public getImportLog(importLog:ImportLog): Observable<ImportLog> {
    return this.http.get<ImportLog>(this.tenantService.api_url+"/import-log/"+importLog.id,{headers:headers})
  }

  public createImportLog(importLog:ImportLog) : Observable<any> {
    return this.http.post<ImportLog>(this.tenantService.api_url+"/import-log",importLog,{headers:headers})
  }

  public async filesToJson(files:Map<string,any>) {
    let json:any={}
    let key_array = Array.from(files.keys())
    const file_promises = key_array.map((key)=>{
      const reader = new FileReader();
      let workBook = null;
      let jsonData = null;
      return new Promise(resolve => {
        reader.onload = (event) => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          json[key]=jsonData.data
          resolve(json)
        }
        reader.readAsBinaryString(files.get(key));
      })
    })
    const res = await Promise.all(file_promises)
    // console.log("last res",res[res.length-1])
    return res[res.length-1]
  }

  public async fileToJson(file:any, table_name:string) {
    let json:any={}
      const reader = new FileReader();
      let workBook = null;
      let jsonData = null;
      return new Promise(resolve => {
        reader.onload = (event) => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          json[table_name]=jsonData.data
          resolve(json)
        }
        reader.readAsBinaryString(file);
      })
  }

  public importProjectsBulk(json): Observable<any> {
      return this.http.post<any>(this.tenantService.api_url+"/import-projects",json,{headers:headers})
    }
  public importOrganisationsBulk(json): Observable<any> {
    return this.http.post<any>(this.tenantService.api_url+"/import-organisations",json,{headers:headers})
  }
  public importUsersBulk(json): Observable<User[]> {
    return this.http.post<any>(this.tenantService.api_url+"/import-users",json,{headers:headers})
  }
  public importTasksBulk(json): Observable<Task[]> {
    return this.http.post<any>(this.tenantService.api_url+"/import-tasks",json,{headers:headers})
  }
  public importAssignmentsBulk(json): Observable<Assignment[]> {
    return this.http.post<any>(this.tenantService.api_url+"/import-assignments",json,{headers:headers})
  }
  public importTimeEntriesBulk(json): Observable<TimeEntry[]> {
    return this.http.post<any>(this.tenantService.api_url+"/import-time-entries",json,{headers:headers})
  }
  public importTimeApprovalsBulk(json): Observable<TimesheetApproval[]> {
    return this.http.post<any>(this.tenantService.api_url+"/import-timesheet-approvals",json,{headers:headers})
  }
  public getLastImportLog(table_name:string): Observable<ImportLog[]>{
    return this.http.get<ImportLog[]>(this.tenantService.api_url+"/import-log/"+table_name,{headers:headers})
  }

}
