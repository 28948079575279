import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { TimesheetNotification } from 'src/app/model/TimesheetNotification';
import { User } from 'src/app/model/User';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notifications-history',
  templateUrl: './notifications-history.component.html',
  styleUrls: ['./notifications-history.component.scss']
})
export class NotificationsHistoryComponent implements OnInit {

  notifications: TimesheetNotification[]=[]
  canEdit: boolean = false;

  id_token:string=""
  ref:DynamicDialogRef;
  currentUser:User;
  canAdd: boolean = false;
  loading: boolean = true;
  //auth0ext_token:string;
  statusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]
  displayNotificationDetails:boolean = false;
  selectedNotification:TimesheetNotification = new TimesheetNotification({});

  cols: any[]= [
    { field: 'type', header: 'Notification Type'},
    { field: 'date', header: 'Date', isDate:true},
    { field: 'Sender', header: 'Sender', nestedField:'name'},
    { field: 'message', header: 'Message'},
  ];
  
  constructor(private timesheetService: TimesheetService, public tenantService: TenantService,
    public messageService:MessageService, public dialogService: DialogService, public exportService:ExportService,
    private breadcrumbService:BreadcrumbService, private userService: UserService) {
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/dashboard']},
        { label: 'Notifications History' }
      ]);
    }

  ngOnInit(): void { 
    this.currentUser = this.userService.currentUser
    this.updateNotifications(); 
    this.cols.forEach(el=>el.completeField = el.nestedField?el.field+'.'+el.nestedField:el.field)
  }

  updateNotifications(){
    this.loading = true
    this.timesheetService.getTimesheetNotificationsByCriteria(new TimesheetNotification({receiver_id:this.currentUser.id})).subscribe(data=>{
      this.notifications=data;
      //console.log(data)
      this.loading = false;
    })
  }
 
  viewNotification(notification:TimesheetNotification){
    this.displayNotificationDetails = true;
    this.selectedNotification = notification
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.notifications)
  }

  exportExcel() {
    this.exportService.exportExcel(this.notifications)
  }

  getCompleteFields(){
    return this.cols.map(el=>el.completeField)
  }
}