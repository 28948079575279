import { Role } from "./Role";
import { ActiveStatus } from "./ActiveStatus";
import { Organisation } from "./Organisation";
import { Tenant } from "./Tenant";
import { License } from "./License";
import { TenantLicense } from "./TenantLicense";

export enum EmploymentType {
    Casual = "Casual",
    Contractor = "Contractor",
    FullTime = "Full-time",
    PartTime = "Part-time"
}

export class User {
    id:number;
    auth0_id:string;
    name:string;
    nickname:string;
    email: string;
    job_title: string;
    latest_location: string;
    tenant_id: number;
    status:ActiveStatus;
    start_date: Date;
    end_date: Date;
    employment_type: EmploymentType;
    default_cost_rate: number;
    default_charge_rate: number;
    supplier_id: number;
    default_manager_id: number;

    roles:Role[]

    tenantLicenses: TenantLicense[];

    Supplier: Organisation;
    Manager: User;
    Tenant: Tenant;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(user){
        this.name = user.name || null;
        this.email=user.email || null;
        this.nickname = user.nickname || null;
        this.job_title = user.job_title || null;
        this.latest_location = user.latest_location || null;
        this.status= user.status || null;
        this.start_date = user.start_date || null;
        this.end_date = user.end_date || null;
        this.employment_type = user.employment_type || null;
        this.default_cost_rate = user.default_cost_rate || null;
        this.default_charge_rate = user.default_charge_rate || null;
        // this.role = user.role || null;
    }
}