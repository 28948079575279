import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppComponent } from 'src/app/app.component';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { Task } from 'src/app/model/Task';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { DialogTaskDetailsComponent } from '../dialog-task-details/dialog-task-details.component';
import { resolve } from 'dns';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  tasks: Task[]=[]
  selectedTasks: Task[]=[]
  ref:DynamicDialogRef;
  activeFilter:ActiveStatus;

  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  loading: boolean = true;
  statusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]
  cols: any[]= [
    { field: 'task_name', header: 'Task' },
    { field: 'Project', header: 'Project', nestedField : 'project_name' },
    { field: 'status', header: 'Status', filter: true },
  ];
  
  public tableMenuItems:MenuItem[] = [
    {
        label: 'Activate',
        command: (event) => this.setAsActive()
    },
    {
        label: 'Deactivate',
        command: (event) =>this.setAsInactive()
    },
    {
        label: 'Delete',
        command: (event) => this.delete(),
        disabled: !this.canDelete
    }
    ];
  
  constructor(private timesheetService: TimesheetService, public tenantService: TenantService,
    public messageService:MessageService, public dialogService: DialogService, public exportService:ExportService, 
    private breadcrumbService:BreadcrumbService, private userService: UserService){
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/dashboard']},
        { label: 'Admin'},
        { label: 'Tasks' }
      ]); }

 ngOnInit(): void {
    this.canEdit = this.userService.checkPermission('Tasks', 'edit')
    this.canAdd = this.userService.checkPermission('Tasks', 'add')
    this.canDelete = this.userService.checkPermission('Tasks', 'delete')
    this.updateTasks()
    this.cols.forEach(el=>el.completeField = el.nestedField?el.field+'.'+el.nestedField:el.field)
  }

  updateTasks(){
    this.loading = true
    this.timesheetService.getAllTasks().subscribe(data=>{
      this.tasks=data
      // console.log(data)
      this.loading = false;
    })
  }
  
  addTask(){
    this.ref = this.dialogService.open(DialogTaskDetailsComponent, {
      header: 'Add a Task',
      width: '70%'
    });
    this.ref.onClose.subscribe((task: Task) =>{
      if (task) {
        this.tasks.push(task)
        this.messageService.add({severity:'success', summary: 'Task Created', detail: task.task_name});
      }
    });
  }

  editTask(task:Task){
    this.ref = this.dialogService.open(DialogTaskDetailsComponent, {
      header: 'Task Details',
      width: '70%',
      data: {
        task:task
      }
    });
    this.ref.onClose.subscribe((updated_task: Task) =>{
      if (updated_task) {
        var index = this.tasks.indexOf(task);
        this.tasks[index]=updated_task;
        this.messageService.add({severity:'success', summary: 'Task Updated', detail: task.task_name});
      }
    });
  }

  setAsActive(){
    this.updateStatus(ActiveStatus.Active)
  }
  setAsInactive(){
    this.updateStatus(ActiveStatus.Inactive)
  }

  updateStatus(status:ActiveStatus){
    let promises = this.selectedTasks.map(task=>{
      task.status = status
      return new Promise(resolve => this.timesheetService.updateTask(task).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Task(s) successfully updated'})) 
  }

  delete(){
    let promises = this.selectedTasks.map(task=>{
      return new Promise(resolve => this.timesheetService.deleteTask(task).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Task(s) successfully deleted'})) 
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.tasks, "Tasks")
  }

  exportExcel() {
    this.exportService.exportExcel(this.tasks)
  }

  getCompleteFields(){
    return this.cols.map(el=>el.completeField)
  }
}
