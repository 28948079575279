
<p-progressBar mode="indeterminate"></p-progressBar>
Work in progress...
<br>
<div class="grid dashboard">
    <div class="col-12 md:col-6 lg:col-3">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-clock"></i>
                <h6 class="m-0" [ngClass]="{'pl-2': !app.isRTL, 'pr-2': app.isRTL}">Employees</h6>
                <div [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">
                    <!-- <button pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" (click)="menu1.toggle($event)"></button>
                    <p-menu #menu1 [popup]="true" [model]="[{label: 'Update', icon: 'pi pi-fw pi-refresh'},{label: 'Edit', icon: 'pi pi-fw pi-pencil'}]"></p-menu> -->
                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 80px">
                    <span class="mb-1 fs-xlarge">65</span>
                    <span class="overview-status p-1 teal-bgcolor fs-small">3 new starters</span>
                </div>
                <div class="flex align-items-end">
                    <p-chart type="bar" [data]="overviewChartData1" [options]="overviewChartOptions1" responsive="true" height="60px" width="160px"></p-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-clock"></i>
                <h6 class="m-0" [ngClass]="{'pl-2': !app.isRTL, 'pr-2': app.isRTL}">Hours</h6>
                <div [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">
                    <!-- <button pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" (click)="menu1.toggle($event)"></button>
                    <p-menu #menu1 [popup]="true" [model]="[{label: 'Update', icon: 'pi pi-fw pi-refresh'},{label: 'Edit', icon: 'pi pi-fw pi-pencil'}]"></p-menu> -->
                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 80px">
                    <span class="mb-1 fs-xlarge">1640</span>
                    <span class="overview-status p-1 brown-bgcolor fs-small">1300 hours billable</span>
                </div>
                <div class="flex align-items-end">
                    <p-chart type="line" [data]="overviewChartData2" [options]="overviewChartOptions1" responsive="true" height="60px" width="160px"></p-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-dollar"></i>
                <h6 class="m-0" [ngClass]="{'pl-2': !app.isRTL, 'pr-2': app.isRTL}">Projects</h6>
                <div [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">
                    <!-- <button pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" (click)="menu2.toggle($event)"></button>
                    <p-menu #menu2 [popup]="true" [model]="[{label: 'Update', icon: 'pi pi-fw pi-refresh'},{label: 'Edit', icon: 'pi pi-fw pi-pencil'}]"></p-menu> -->
                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 80px">
                    <span class="mb-1 fs-xlarge">19</span>
                    <span class="overview-status p-1 deeporange-bgcolor fs-small">15 different clients</span>
                </div>
                <div class="flex align-items-end">
                    <p-chart type="bar" [data]="overviewChartData3" [options]="overviewChartOptions1" responsive="true" height="60px" width="160px"></p-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-users"></i>
                <h6 class="m-0" [ngClass]="{'pl-2': !app.isRTL, 'pr-2': app.isRTL}">Revenue</h6>
                <div [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">
                    <!-- <button pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" (click)="menu3.toggle($event)"></button>
                    <p-menu #menu3 [popup]="true" [model]="[{label: 'Update', icon: 'pi pi-fw pi-refresh'},{label: 'Edit', icon: 'pi pi-fw pi-pencil'}]"></p-menu> -->
                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 80px">
                    <span class="mb-1 fs-xlarge">$57K</span>
                    <span class="overview-status p-1 pink-bgcolor fs-small">$9,640 income</span>
                </div>
                <div class="flex align-items-end">
                    <p-chart type="line" [data]="overviewChartData4" [options]="overviewChartOptions1" responsive="true" height="60px" width="160px"></p-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 lg:col-6">
        <div class="card height-100">
            <div class="card-header">
                <h5>Employees</h5>
                <div>
                    <!-- <button pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" (click)="menu5.toggle($event)"></button>
                    <p-menu #menu5 [popup]="true" [model]="[{label: 'New', icon: 'pi pi-fw pi-plus'},{label: 'Edit', icon: 'pi pi-fw pi-pencil'},{label: 'Delete', icon: 'pi pi-fw pi-trash'}]"></p-menu> -->
                </div>
            </div>

            <ul class="widget-list">
                <li class="flex align-items-center py-3">
                    <div class="person-item flex align-items-center">
                        <img src="assets/demo/images/avatar/xuxuefeng.png">
                        <div [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
                            <div>Xuxue Feng</div>
                            <small class="muted-text">feng@ultima.org</small>
                        </div>
                    </div>
                    <span class="person-tag indigo-bgcolor p-1 fs-small" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">Accounting</span>
                    <span class="person-tag orange-bgcolor p-1 fs-small" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">Sales</span>
                </li>

                <li class="flex align-items-center py-3">
                    <div class="person-item flex align-items-center">
                        <img src="assets/demo/images/avatar/elwinsharvill.png">
                        <div [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
                            <div>Elwin Sharvill</div>
                            <small class="muted-text">sharvill@ultima.org</small>
                        </div>
                    </div>
                    <span class="person-tag teal-bgcolor p-1 fs-small" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">Finance</span>
                    <span class="person-tag orange-bgcolor p-1 fs-small" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">Sales</span>
                </li>

                <li class="flex align-items-center py-3">
                    <div class="person-item flex align-items-center">
                        <img src="assets/demo/images/avatar/avatar-1.png">
                        <div [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
                            <div>Anna Fali</div>
                            <small class="muted-text">fali@ultima.org</small>
                        </div>
                    </div>
                    <span class="person-tag pink-bgcolor p-1 fs-small" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">Management</span>
                </li>

                <li class="flex align-items-center py-3">
                    <div class="person-item flex align-items-center">
                        <img src="assets/demo/images/avatar/avatar-2.png">
                        <div [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
                            <div>Jon Stone</div>
                            <small class="muted-text">stone@ultima.org</small>
                        </div>
                    </div>
                    <span class="person-tag pink-bgcolor p-1 fs-small" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">Management</span>
                    <span class="person-tag teal-bgcolor p-1 fs-small" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">Finance</span>
                </li>

                <li class="flex align-items-center py-3">
                    <div class="person-item flex align-items-center">
                        <img src="assets/demo/images/avatar/avatar-3.png">
                        <div [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
                            <div>Stephen Shaw</div>
                            <small class="muted-text">shaw@ultima.org</small>
                        </div>
                    </div>
                    <span class="person-tag teal-bgcolor p-1 fs-small" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">Finance</span>
                </li>
            </ul>
        </div>
    </div>

    <div class="col-12 lg:col-6">
        <div class="card height-100">
            <div class="card-header">
                <h5>Revenue</h5>
                <div>
                    <!-- <button pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" (click)="menu6.toggle($event)"></button>
                    <p-menu #menu6 [popup]="true" [model]="[{label: 'Update', icon: 'pi pi-fw pi-refresh'},{label: 'Edit', icon: 'pi pi-fw pi-pencil'}]"></p-menu> -->
                </div>
            </div>
            <p-chart type="line" [data]="ordersChart" [options]="ordersOptions"></p-chart>
        </div>
    </div>
</div>