import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Console } from 'console';
import { MessageService } from 'primeng/api';
import { Role } from 'src/app/model/Role';
import { Permission } from 'src/app/model/Permission';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UserService } from 'src/app/services/user.service';
import { RolePermission } from 'src/app/model/RolePermission';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  constructor(public userService:UserService,private messageService: MessageService,private fb: FormBuilder, private breadcrumbService:BreadcrumbService){
    this.breadcrumbService.setItems([
      { label: 'Account Settings',routerLink:['/admin/tenant-settings/general']},
      { label: 'Roles'}
    ]); }

  permissions:Permission[]=[]
  roles:Role[]=[]

  displayDialog:boolean = false;
  isNew: boolean = false;

  canAdd: boolean = false;
  loading:boolean = true;
  canEdit:boolean = false;
  canDelete:boolean = false;

  actionColumns:any[] = [
    {label: "Add", can_key: "can_add", has_key: "has_add"},
    {label: "Edit", can_key: "can_edit", has_key: "has_edit"},
    {label: "View", can_key: "can_view", has_key: "has_view"}, 
    {label: "Delete", can_key: "can_delete", has_key: "has_delete"},
    {label: "Enable",can_key: "can_enable", has_key: "has_enable"}
  ]

  createForm:FormGroup= this.fb.group({
    'id': [],
    'description': [],
    'label':[,Validators.required]
  })
  selectedRole:Role;

  ngOnInit(): void {
    this.canAdd = this.userService.checkPermission('Roles', 'add')
    this.canEdit = this.userService.checkPermission('Roles', 'edit')
    this.canDelete = this.userService.checkPermission('Roles', 'delete')

    this.loading = false;
    this.updatePermissions(true)
  }

  addRole(){
    this.isNew = true;
    this.selectedRole = new Role({});
    this.createForm.patchValue(this.selectedRole)
    
    this.displayDialog = true;
  }

  updatePermissions(init:boolean=false){
    this.loading = true;
    this.userService.getAllRoles().subscribe(data=>{
      this.roles=data; 
      console.log(this.roles)
      this.roles.sort((a, b) => a.label.localeCompare(b.label))
      if (init) {
        this.selectedRole = Object.assign({},this.roles[0])
      } else {
        this.selectedRole = this.roles.find(el=>el.id==this.selectedRole.id)
      }
      if (this.selectedRole.system_role) this.canEdit=false
      this.userService.getAllPermissions().subscribe(permData=>{
        this.permissions=permData; 
        this.permissions.sort((a,b)=>a.order-b.order);
        
        for (let perm of this.permissions){
          let rolePerm = this.selectedRole.permissions.find(el=>el.id==perm.id) || undefined
          if (rolePerm){
            perm.rolePermission = rolePerm.rolePermission
          } else {
            perm.rolePermission = new RolePermission({role_id:this.selectedRole.id,permission_id:perm.id})
          }
        }
        console.log(this.permissions)
        this.loading=false
      }) 
    })
  }

  hideDialog(){
    this.displayDialog=false;
  }

  editRole(){
    this.isNew = false;
    this.createForm.patchValue(this.selectedRole)
    this.displayDialog = true;
    let canEdit = this.userService.checkPermission("Projects","edit")
    if (!canEdit){this.createForm.disable()}
  }

  deleteRole(){
    this.userService.deleteRole(this.selectedRole).subscribe(data=>{
      this.messageService.add({severity: 'success', summary: 'Role Deleted', detail: this.selectedRole.label}) 
      this.selectedRole = undefined
      this.updatePermissions(true)
    })

  }

  onSubmit(role:Role){
    if (this.isNew){
      role.system_role=false;
      this.userService.createRole(role).subscribe(data=>{
        console.log(data)
        this.messageService.add({severity: 'success', summary: 'Role Created', detail: role.label}) 
        this.selectedRole = data
        this.updatePermissions()
        this.hideDialog()
      })
    } else {
      this.userService.updateRole(role).subscribe(data=>{
        console.log(data)
        this.selectedRole = data
        this.messageService.add({severity: 'success', summary: 'Role Updated', detail: role.label}) 
        this.updatePermissions()
        this.hideDialog()
      })
    }
  }
  save(){
    this.selectedRole.permissions = this.permissions
    this.userService.updatePermissions(this.selectedRole).subscribe(data=>{
      console.log("permissions updated",data)
      this.updatePermissions()
    })
  }

  getColspan(){
    return 2 + this.roles.length
  }

  // for mandatory fields
  get label() { return this.createForm.get('label'); }

}
