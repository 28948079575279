import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { BillingRecord } from 'src/app/model/BillingRecord';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { DialogBillingRecordDetailsComponent } from '../dialog-billing-record-details/dialog-billing-record-details.component';

@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss']
})
export class BillingHistoryComponent implements OnInit {

  billingRecords: BillingRecord[]=[]
  filteredBillingRecords : BillingRecord[]=[]
  ref:DynamicDialogRef;
  
  loading: boolean = true;
  
  cols: any[]= [
    { field: 'date', header: 'Date' },
    { field: 'billed_amount', header: 'Billed Amount' },
    { field: 'invoice_number', header: 'Invoice Number'},
    { field: 'status', header: 'Status' },
  ];

  constructor(private tenantService: TenantService,public messageService:MessageService,
    public dialogService: DialogService, public exportService:ExportService, 
    private breadcrumbService:BreadcrumbService, private userService: UserService){
      this.breadcrumbService.setItems([
        { label: 'Admin'},
        { label: 'Licenses & Billing'},
        { label: 'Billing History' }
      ]); }

 ngOnInit(): void {
    this.updateBillingRecords()
    
  }

  updateBillingRecords(): void {
    this.loading = true
    this.tenantService.getAllBillingRecords().subscribe(data=>{
      this.billingRecords=data;
      this.loading = false;
      ////console.log(data)
    })
  }
  
  addBillingRecord(){
    this.ref = this.dialogService.open(DialogBillingRecordDetailsComponent, {
      header: 'Add a BillingRecord',
      width: '70%'
    });
    this.ref.onClose.subscribe((billingRecord: BillingRecord) =>{
      if (billingRecord) {
        this.billingRecords.push(billingRecord)
        this.messageService.add({severity:'info', summary: 'Billing record Created', detail: billingRecord.date.toDateString()});
      }
    });
  }

  viewDetails(billingrecord:BillingRecord){
    this.ref = this.dialogService.open(DialogBillingRecordDetailsComponent, {
      header: 'Billing Record Details',
      width: '70%',
      data: {
        billingrecord:billingrecord
      }
    });
    this.ref.onClose.subscribe((updated_billingrecord: BillingRecord) =>{
      if (updated_billingrecord) {
        var index = this.billingRecords.indexOf(billingrecord);
        this.billingRecords[index]=updated_billingrecord;
        this.messageService.add({severity:'info', summary: 'BillingRecord Updated', detail: billingrecord.date.toDateString()});
      }
    });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.filteredBillingRecords)
}

  exportExcel() {
    this.exportService.exportExcel(this.filteredBillingRecords)
  }

  getCompleteFields(){
    return this.cols.map(el=>el.field)
  }
}
