import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { TenantLicense } from 'src/app/model/TenantLicense';
import { User } from 'src/app/model/User';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-license-details',
  templateUrl: './dialog-license-details.component.html',
  styleUrls: ['./dialog-license-details.component.scss']
})
export class DialogLicenseDetailsComponent implements OnInit {

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService, private userService:UserService
    , public tenantService: TenantService) { 
    if (config.data &&  config.data.license) {
      this.selectedLicense = this.config.data.license
      if (!this.selectedLicense.users) this.selectedLicense.users = []
    }
  }

  employees:User[]=[]
  selectedLicense:TenantLicense;

  ngOnInit(): void {
    this.userService.getUsersByCriteria(new User({status:ActiveStatus.Active})).subscribe(data=>{
      this.employees=data
    })
  }

  saveAssignments(){
    if (this.selectedLicense.users.length<=this.selectedLicense.nb_available_licenses)
      {
        this.selectedLicense.nb_assigned_licenses = this.selectedLicense.users?this.selectedLicense.users.length:0
        this.tenantService.updateTenantLicense(this.selectedLicense).subscribe(data=>{
          this.selectedLicense = data
          this.ref.close(this.selectedLicense)
        })
      } else {
        // automate buying licenses in the future
        this.messageService.add({severity: 'error', summary: 'Insufficient number of licenses', detail: 'Please buy more licenses to proceed'}) 
      }
  }
  
  cancel(){
    this.ref.close();
  }
}
