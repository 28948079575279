import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings, TimesheetSettings } from '../model/Settings';
import { Observable } from 'rxjs';
import { BillingRecord, BillingRecordStatus } from '../model/BillingRecord';
import { License, TenantLicenseStatus } from '../model/License';
import { PaymentMethod, PaymentMethodStatus } from '../model/PaymentMethod';
import { Tenant } from '../model/Tenant';
import { TenantLicense } from '../model/TenantLicense';

import { environment } from './../../environments/environment';
import { Currency } from '../model/Currency';

const headers = new HttpHeaders({
  'Access-Control-Allow-Origin':'*',
});

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  public currentTenant:Tenant = JSON.parse(localStorage.getItem('tenant'));
  public encodedTenant: string;
  public api_url: string;
  //public res = localStorage.clear();
  public timesheetSettings:Settings = (this.currentTenant && this.currentTenant.Settings)?this.currentTenant.Settings.find(sett=>sett.Module.name == "Timesheets"):null
  public paymentMethodStatusOptions = [PaymentMethodStatus.Active,PaymentMethodStatus.Disabled,PaymentMethodStatus.Inactive]
  public billingRecordStatusOptions = [BillingRecordStatus.Cancelled,BillingRecordStatus.Overdue,BillingRecordStatus.Paid,BillingRecordStatus.Pending]
  public tenantLicenseStatusOptions = [TenantLicenseStatus.Active,TenantLicenseStatus.Inactive]
  public tenant_endpoint:string;
  constructor(private http: HttpClient) { }

  public getAllTenants() : Observable<Tenant[]> {
    return this.http.get<Tenant[]>(this.tenant_endpoint,{headers:headers})
  }

  public getTenantById(tenant_id:number): Observable<Tenant> {
    console.log("current tenant",tenant_id)
    return this.http.get<Tenant>(this.tenant_endpoint,{headers:headers})
  }

  public updateTenant(tenant:Tenant) : Observable<Tenant> {
    return this.http.put<Tenant>(this.tenant_endpoint,tenant,{headers:headers})
  }

  public saveLogo(image_base64:any) : Observable<Tenant> {
    return this.http.post<Tenant>(this.tenant_endpoint+"/logo",image_base64,{headers:headers})
  }

  public deleteLogo(logo_id:any) : Observable<any> {
    return this.http.delete<any>(this.tenant_endpoint+"/logo/"+logo_id,{headers:headers})
  }

  public createTenant(tenant:Tenant) : Observable<Tenant> {
    return this.http.post<Tenant>(this.tenant_endpoint,tenant,{headers:headers})
  }

  public setCurrentTenant(tenant_id: number){
    this.updateEncodedTenant(tenant_id)
    
    this.getTenantById(tenant_id).subscribe(data=>{
      console.log("setting tenant",data)
      this.currentTenant = data
      this.updateTenantInStorage(data)
      this.timesheetSettings = this.currentTenant.Settings.find(sett=>sett.Module.name == "Timesheets")
    })
  }

  public updateEncodedTenant(tenant_id: number){
    this.encodedTenant = tenant_id.toString().padStart(8,"0")
    //updating endpoints
    this.api_url = environment.apiUrl+"/" + this.encodedTenant
    this.tenant_endpoint = environment.apiUrl+"/" + this.encodedTenant + "/tenant"
  }

  public updateTenantInStorage(tenant){
    localStorage.setItem('tenant',JSON.stringify(tenant))
    this.currentTenant = tenant
    this.updateEncodedTenant(this.currentTenant.id)
  }

  public getAllBillingRecords():Observable<BillingRecord[]>{
    return this.http.get<BillingRecord[]>(this.tenant_endpoint+"/billing-records",{headers:headers})
  }

  public createBillingRecords(record:BillingRecord):Observable<BillingRecord>{
    return this.http.post<BillingRecord>(this.tenant_endpoint+"/billing-record",record, {headers:headers})
  }

  public updateBillingRecords(record:BillingRecord):Observable<BillingRecord>{
    return this.http.put<BillingRecord>(this.tenant_endpoint+"/billing-record",record, {headers:headers})
  }

  // public getAllTenantLicenses():Observable<TenantLicense[]>{
  //   return this.http.get<TenantLicense[]>(this.tenant_endpoint+"/tenant-licenses",{headers:headers})
  // }

  public createTenantLicense(record:TenantLicense):Observable<TenantLicense>{
    return this.http.post<TenantLicense>(this.tenant_endpoint+"/tenant-licenses",record, {headers:headers})
  }

  public updateTenantLicense(record:TenantLicense):Observable<TenantLicense>{
    return this.http.put<TenantLicense>(this.tenant_endpoint+"/tenant-licenses",record, {headers:headers})
  }

  public getAllLicenses():Observable<License[]>{
    return this.http.get<License[]>(this.tenant_endpoint+"/licenses",{headers:headers})
  }

  public getAllPaymentMethods():Observable<PaymentMethod[]>{
    return this.http.get<PaymentMethod[]>(this.tenant_endpoint+"/payment-methods",{headers:headers})
  }

  public getPaymentMethodsByCriteria(paymentMethodFilter:PaymentMethod): Observable<PaymentMethod[]> {
    return this.http.post<PaymentMethod[]>(this.tenant_endpoint + "/payment-methods/filter",paymentMethodFilter,{headers:headers})
  }

  public createPaymentMethod(record:PaymentMethod):Observable<PaymentMethod>{
    return this.http.post<PaymentMethod>(this.tenant_endpoint+"/payment-methods",record, {headers:headers})
  }

  public updatePaymentMethod(record:PaymentMethod):Observable<PaymentMethod>{
    return this.http.put<PaymentMethod>(this.tenant_endpoint+"/payment-methods",record, {headers:headers})
  }

  public updateSettings(record:Settings):Observable<Settings>{
    var index = this.currentTenant.Settings.findIndex(sett=>sett.id==record.id);
    this.currentTenant.Settings.splice(index,1)
    this.currentTenant.Settings.push(record)
    return this.http.put<Settings>(this.tenant_endpoint+"/settings",record, {headers:headers})
  }

  public createCurrency(record:Currency):Observable<Currency>{
    console.log("creating currency", record)
    return this.http.post<Currency>(this.tenant_endpoint+"/currencies",record, {headers:headers})
  }

  public updateCurrency(record:Currency):Observable<Currency>{
    return this.http.put<Currency>(this.tenant_endpoint+"/currencies",record, {headers:headers})
  }

  public getAllCurrencies():Observable<Currency[]>{
    return this.http.get<Currency[]>(this.tenant_endpoint+"/currencies",{headers:headers})
  }


}
