import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { License, TenantLicenseStatus } from 'src/app/model/License';
import { TenantLicense } from 'src/app/model/TenantLicense';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { DialogLicenseDetailsComponent } from '../dialog-license-details/dialog-license-details.component';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent implements OnInit {

  licenses: License[]=[]
  ref:DynamicDialogRef;
  activeFilter:TenantLicenseStatus;
  
  loading: boolean = true;
  
  cols: any[]= [
    { field: 'name', header: 'Name' },
    { field: 'TenantLicenses', header: 'Licenses Number', nestedField:'nb_available_licenses' },
    { field: 'TenantLicenses', header: 'Assigned', nestedField:'nb_assigned_licenses'}
  ];

  constructor(private tenantService: TenantService,public messageService:MessageService,
    public dialogService: DialogService, public exportService:ExportService, 
    private breadcrumbService:BreadcrumbService, private userService: UserService){
      this.breadcrumbService.setItems([
        { label: 'Admin'},
        { label: 'Licenses & Billing'},
        { label: 'Licenses' }
      ]); }

 ngOnInit(): void {
    this.updateLicenses()
    
  }

  updateLicenses(): void {
    this.loading = true
    this.tenantService.getAllLicenses().subscribe(data=>{
      this.licenses=data;
      this.loading = false;
      //console.log("licenses",data)

    })
  }

  buyLicense(license:License){}
  moreInfo(license:License){}


  editRow(license:License){
      this.ref = this.dialogService.open(DialogLicenseDetailsComponent, {
        header: 'License Details',
        width: '70%',
        data: {
          license:license.TenantLicenses[0]
        }
      });
      this.ref.onClose.subscribe((updated_license: TenantLicense) =>{
        if (updated_license) {
          var index = this.licenses.indexOf(license);
          this.licenses[index].TenantLicenses[0]=updated_license;
          this.messageService.add({severity:'info', summary: 'License Updated', detail: license.name});
        }
      });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  getCompleteFields(){
    return this.cols.map(el=>el.field)
  }

  filterByStatus(dt){
    if (this.activeFilter==TenantLicenseStatus.Active){
      dt.filter(record=>(record.assigned_licenses+record.available_licenses)>0)
    } else if (this.activeFilter==TenantLicenseStatus.Inactive){
      dt.filter(record=>(record.assigned_licenses+record.available_licenses)==0)
    }
  }
}