import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectsComponent } from './components/company-admin/projects/projects.component';
import { UsersComponent } from './components/company-admin/users/users.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { TasksComponent } from './components/company-admin/tasks/tasks.component';
import { AssignmentsComponent } from './components/company-admin/assignments/assignments.component';
import { TimesheetComponent } from './components/timesheet/timesheet.component';
import { TenantsComponent } from './components/ecs-admin/tenants/tenants.component';
import { ErrorComponent } from './components/navigation/error/error.component';
import { OrganisationsComponent } from './components/company-admin/organisations/organisations.component';
import { ReportsComponent } from './components/reports/reports.component';
import { UserSettingsComponent } from './components/settings/user-settings/user-settings.component';
import { TimesheetApprovalComponent } from './components/timesheet/timesheet-approval/timesheet-approval.component';
import { CheckTimesheetsComponent } from './components/timesheet/check-timesheets/check-timesheets.component';
import { CheckManagersComponent } from './components/timesheet/check-managers/check-managers.component';
import { AdminDashboardComponent } from './components/company-admin/admin-dashboard/admin-dashboard.component';
import { GeneralComponent } from './components/settings/tenant-settings/general/general.component';
import { TimeEntryComponent } from './components/timesheet/timesheet-settings/time-entry/time-entry.component';
import { SystemComponent } from './components/settings/tenant-settings/system/system.component';
import { ReportPreviewComponent } from './components/reports/report-preview/report-preview.component';
import { AddReportComponent } from './components/reports/add-report/add-report.component';
import { ImportComponent } from './components/timesheet/import/import.component';
import { RolesComponent } from './components/settings/tenant-settings/roles/roles.component';
import { PermissionGuard } from './permission.guard';
import { LicensesComponent } from './components/settings/tenant-settings/account-billing/licenses/licenses.component';
import { BillingHistoryComponent } from './components/settings/tenant-settings/account-billing/billing-history/billing-history.component';
import { BillingNotificationsComponent } from './components/settings/tenant-settings/account-billing/billing-notifications/billing-notifications.component';
import { PaymentMethodsComponent } from './components/settings/tenant-settings/account-billing/payment-methods/payment-methods.component';
import { TemplatesComponent } from './components/timesheet/timesheet-settings/templates/templates.component';
import { NotificationsComponent } from './components/timesheet/timesheet-settings/notifications/notifications.component';
import { UnauthorizedComponent } from './components/navigation/unauthorized/unauthorized.component';
import { UnlicensedComponent } from './components/navigation/unlicensed/unlicensed.component';
import { LicenseGuard } from './license.guard';
import { NotificationsHistoryComponent } from './components/timesheet/notifications-history/notifications-history.component';
import { CurrenciesComponent } from './components/settings/tenant-settings/currencies/currencies.component';
import { BatchPrintComponent } from './components/timesheet/batch-print/batch-print.component';
import { TimesheetDashboardComponent } from './components/timesheet/timesheet-dashboard/timesheet-dashboard.component';

const routes: Routes = [  
  // General
  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: TimesheetDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'user-settings',
    component: UserSettingsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'report-preview',
    component: ReportPreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'add-report',
    component: AddReportComponent,
    canActivate: [AuthGuard],
  },

  //Admin
  {
    path: 'admin/users',
    component: UsersComponent,
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      permission: 'Users'
    }
  },
  {
    path: 'admin/roles',
    component: RolesComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'Roles'
    }
  },
  {
    path: 'admin/tenant-settings/general',
    component: GeneralComponent,
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      permission: 'Tenant Settings'
    }
  },
  {
    path: 'admin/tenant-settings/currencies',
    component: CurrenciesComponent,
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      permission: 'Tenant Settings'
    }
  },
  {
    path: 'admin/tenant-settings/system',
    component: SystemComponent,
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      permission: 'Tenant Settings'
    }
  },
  {
    path: 'admin/account/licenses',
    component: LicensesComponent,
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      permission: 'Billing'
    }
  },
  {
    path: 'admin/account/billing-history',
    component: BillingHistoryComponent,
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      permission: 'Billing'
    }
  },
  {
    path: 'admin/account/billing-notifications',
    component: BillingNotificationsComponent,
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      permission: 'Billing'
    }
  },
  {
    path: 'admin/account/payment-methods',
    component: PaymentMethodsComponent,
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      permission: 'Billing'
    }
  },

  //Timesheet
  {
    path: 'timesheet/dashboard',
    component: TimesheetDashboardComponent,
    canActivate: [AuthGuard,LicenseGuard],
    data: {
      permission: 'Own Timesheets - Time Entries',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/projects',
    component: ProjectsComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Projects',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/organisations',
    component: OrganisationsComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Organisations',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/tasks',
    component: TasksComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Tasks',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/assignments',
    component: AssignmentsComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Assignments',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/view/:user_id',
    component: TimesheetComponent,
    canActivate: [AuthGuard, LicenseGuard],
    data: {
      permission: 'Own Timesheets - Time Entries',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/view',
    component: TimesheetComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Own Timesheets - Time Entries',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/notifications',
    component: NotificationsHistoryComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Own Timesheets - Time Entries',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/batch-print',
    component: BatchPrintComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Reports',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/check-timesheets',
    component: CheckTimesheetsComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Supervised Timesheets - Time Entries',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/check-managers',
    component: CheckManagersComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'All Timesheets - Time Entries',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/approval/:ts_app_ids/:employee_id',
    component: TimesheetApprovalComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Supervised Timesheets - Time Entries',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/import',
    component: ImportComponent,
    canActivate: [AuthGuard],
    data: {
      permission: '',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/timesheet-settings/templates',
    component: TemplatesComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Timesheet Settings',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/timesheet-settings/notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Timesheet Settings',
      module: 'Timesheets'
    }
  },
  {
    path: 'timesheet/timesheet-settings/time-entry',
    component: TimeEntryComponent,
    canActivate: [AuthGuard,PermissionGuard, LicenseGuard],
    data: {
      permission: 'Timesheet Settings',
      module: 'Timesheets'
    }
  },

  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'unlicensed',
    component: UnlicensedComponent
  },
  {
    path: '**',
    component: ErrorComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
