<p-toast></p-toast>
<div class="card">
    <h5>Import tables</h5>
    <p-selectButton [options]="table_names" [(ngModel)]="current_table"
                            optionLabel="label" (onChange)="getLastImportLogs() ; resetInfoMessage()"></p-selectButton>
</div>
<div class="card">
    <!-- <div class="card-header">
        {{current_table.label}} file
    </div> -->
    <div class="grid">
        <div class="col-12 md:col-6" style="display:flex;flex-direction: column;">
            <div style="display:flex;">
                <button pButton type="button" icon="pi pi-download" label="Download template" (click)="downloadTemplate()" class="p-button-primary mr-2" pTooltip="Download template"></button>
                
                <p-fileUpload #fileUpload mode="basic" [name]="current_table+'[]'" [auto]="true" customUpload="true" (uploadHandler)="uploadFile($event, fileUpload)" chooseLabel="Browse"
                        multiple="true" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" maxFileSize="1000000"></p-fileUpload>
            </div>
            <div *ngIf="hasFile()" style="margin-top: 7px;">
                <span style="margin: 7px;">{{uploadedFiles.get(current_table.label).name}}</span>
            </div>
            <p-messages severity="info">
                <ng-template pTemplate>
                    <div style="flex-direction: row;">
                        <i class="pi pi-info-circle"  style="font-size: 1.2rem; margin-right: 5px;"></i><span>Data Requirements</span>
                        <ul>
                            <li *ngFor="let msg of listMsgs">
                                {{msg}}
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </p-messages>
        </div>
        <p-table class="col-12 md:col-6" [value] = "lastImportLogs">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <h5>Last Imports</h5>
                    <div style="display:flex;">
                        <div class="p-input-icon-left ml-auto"></div>
                        <div style="display:flex;">
                            <button pButton type="button" icon="pi pi-refresh" (click)="getLastImportLogs()" class="p-button-primary mr-2" pTooltip="Refresh" tooltipPosition="bottom"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>Started At</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-log>
                <tr>
                    <td>{{log.started_on|date:'medium'}}</td>
                    <td>{{log.status}}</td>
                    <td>{{log.error_message}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div class="card"> 
    <button pButton type="button" [label]="'Import '+ current_table.label"  icon="pi pi-check" (click)="startImport(current_table.label)" class="p-button-success mr-2" pTooltip="Start Import" tooltipPosition="bottom"></button>
    <button pButton type="button" label="Clear" icon="pi pi-times" (click)="resetAll()" class="p-button-danger mr-2" pTooltip="Reset" tooltipPosition="bottom"></button>
</div>
 <!--    <button pButton type="button" label="Start Import" icon="pi pi-check" (click)="startImport()" class="p-button-success mr-2" pTooltip="Start Import" tooltipPosition="bottom"></button>
    <button pButton type="button" label="Reset All" icon="pi pi-times" (click)="resetAll()" class="p-button-danger mr-2" pTooltip="Reset" tooltipPosition="bottom"></button> -->
        <!-- <div>
        <h5>Upload your files</h5>
        <p-fileUpload name="demo[]" auto="true" customUpload="true"
                multiple="true" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" maxFileSize="1000000">
            <ng-template pTemplate="content" let-files>
               <p-table *ngIf="files.length>0" [value]="files">
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            <h5>Datatable mapping</h5>
                            <div style="display:flex;">
                                <div class="p-input-icon-left ml-auto"></div>
                                <div><button pButton type="button" label="Submit" icon="pi pi-check" (click)="submitDataFiles(files)" class="p-button-success mr-2" pTooltip="Submit" tooltipPosition="bottom"></button></div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body" let-file>
                            <tr>
                                <td>{{file.name}}</td>
                                <td><p-dropdown [options]="tables" styleClass="w-full"[(ngModel)]="file.table_name"></p-dropdown></td>
                            </tr>
                    </ng-template>
               </p-table>
            </ng-template>
        </p-fileUpload>
    </div> -->
<!-- </div> -->