import { ActiveStatus } from "./ActiveStatus";

export class Organisation {
    id:number;
    organisation_name:string;
    organisation_code: string;
    reference: string ;
    notes: string ;
    tenant_id: number ;
    status:ActiveStatus;
    is_supplier: boolean;
    is_client: boolean;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
  
    constructor(organisation){
        this.organisation_name=organisation.organisation_name || null;
        this.organisation_code = organisation.organisation_code || null;
        this.reference = organisation.reference|| null;
        this.notes = organisation.notes|| null;
        this.status = organisation.status || null;
        this.is_client = organisation.is_client || null;
        this.is_supplier = organisation.is_supplier || null;
    }
}