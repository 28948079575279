import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Auth0Role } from 'src/app/model/Auth0Role';
import { Tenant } from 'src/app/model/Tenant';
import { User } from 'src/app/model/User';
import { TenantService } from 'src/app/services/tenant.service';
import { MessageService } from 'primeng/api';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-tenant-details',
  templateUrl: './dialog-tenant-details.component.html',
  styleUrls: ['./dialog-tenant-details.component.scss']
})
export class DialogTenantDetailsComponent implements OnInit {

  //auth0_token:string=""
  //auth0ext_token:string = ""
  new_tenant: Tenant = new Tenant({})
  roles:Auth0Role[]=[]
  createForm:FormGroup= this.fb.group({
    'id': [],
    'company':[,Validators.required],
    'owner_id':[]
  })
  isNew: boolean = true
  
  userForm:FormGroup= this.fb.group({
    'id': [],
    'email':[,Validators.required],
    'name':[,Validators.required]
  })
  tenant_owner:User=new User({});

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private fb: FormBuilder,
    private messageService: MessageService, public timesheetService: TimesheetService,
    private tenantService: TenantService, private userService: UserService) {
      if (config.data && config.data.tenant) {
        this.new_tenant = this.config.data.tenant
        this.isNew = false
        this.createForm.patchValue(this.new_tenant)
      }
    }

  ngOnInit(): void {
      if (!this.isNew){
        this.userService.getUserById(this.new_tenant.owner_id).subscribe(data=>{
          this.tenant_owner = data;
          this.userForm.patchValue(this.tenant_owner)
        })
      }
  }
  cancel(){
    this.ref.close();
  }

  onSubmitForms(){
    if (this.createForm.valid && this.userForm.valid){
      this.new_tenant = this.createForm.value
      this.tenant_owner = this.userForm.value

      if (this.isNew){
        this.tenantService.updateTenant(this.new_tenant).subscribe(data=> {
          console.log("Tenant created", data);
          this.messageService.add({severity: 'success', summary: 'Tenant created', detail: this.new_tenant.company})
          // this.ref.close(this.new_tenant);
          this.new_tenant = data
          this.tenant_owner.tenant_id = data.id
          this.userService.createUser(this.tenant_owner).subscribe(res => {
            console.log("Tenant owner created",res)
            this.tenant_owner = res
            this.messageService.add({severity: 'success', summary: 'Tenant Owner created'})
            this.new_tenant.owner_id = res.id
            this.tenantService.updateTenant(this.new_tenant).subscribe(res2 => {
              console.log("tenant updated with new tenant owner id")
            })
          })
        })
      }
          // this.messageService.add({severity: 'success', summary: 'User created', detail: res.name})     
          // this.tenant_owner = res
          // this.new_tenant.owner_id = this.tenant_owner.id
          // this.auth0ExtService.createAuth0Group(this.new_tenant,this.auth0ext_token).subscribe(res=> {
          //   this.messageService.add({severity: 'success', summary: 'Auth0 group created', detail: res.name})
          //   console.log("auth0 group created",res)
          //   this.new_tenant.id = res._id
          //   this.auth0ExtService.addUserToGroup(this.new_tenant.id,this.tenant_owner,this.auth0ext_token).subscribe(res=> {
          //     console.log("user added to group",res)
          //     this.messageService.add({severity: 'success', summary: 'User added to Auth0 group'})
          //   })
          //   this.auth0ExtService.addRoleToUser(this.roles.find(el=>el.name="Admin"),this.tenant_owner,this.auth0ext_token).subscribe(res=> {
          //     console.log("role added to user", res)
          //     this.messageService.add({severity: 'success', summary: 'Admin role given to user'})
          //   })
            
      //     })
      //   })
      // }
      else {
        this.tenantService.updateTenant(this.new_tenant).subscribe(data=> {
          console.log("Tenant updated");
          this.messageService.add({severity: 'success', summary: 'Tenant updated', detail: this.new_tenant.company})
          this.ref.close(this.new_tenant);
        })
      }
    }
  }

    // for mandatory fields
    get name() { return this.userForm.get('name'); }
    get email() { return this.userForm.get('email'); }
    get company() { return this.createForm.get('company'); }
}
