import Module from "module";
import { Report } from "./Report";

export class ReportType {
    id:number;
    report_type_name: string;
    icon: string;
    description: string;
    Reports:Report[]=[]

    module_id: number;
    Module: Module;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(reportType){
        this.id = reportType.id || null;
        this.report_type_name=reportType.report_type_name || null;
    }
}