<p-toast></p-toast>
<div class="card">
    <form [formGroup]="createForm" class="formgrid grid" (ngSubmit)="onSubmit(createForm.value)" >   
        <div class="field col-12 md:col-5">
            <label>Name: </label>
            <input type="text" pInputText class="w-full" formControlName="name" required="true" name="name"/>
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors?.required">Name is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Nickname: </label>
            <input type="text" pInputText class="w-full" formControlName="nickname" name="nickname"/>
        </div>
        <div class="field col-12 md:col-5">
            <label>Email: </label>
            <input type="text" pInputText class="w-full" formControlName="email" required="true" name="email"/>
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                <div *ngIf="email.errors?.required">Email is required.</div>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Status: </label>
            <p-selectButton [options]="statusOptions" formControlName="status"></p-selectButton>
        </div>
        <div class="field col-12 md:col-5">
            <label>Employment Type: </label>
            <p-dropdown styleClass="w-full" [options]="employmentOptions" formControlName="employment_type" placeholder="Select..."></p-dropdown>
        </div>
        <div *ngIf="createForm.value.employment_type=='Contractor'" class="field col-12 md:col-10">
            <label>Supplier: </label>
            <p-dropdown styleClass="w-full" [options]="suppliers" optionValue= "id" optionLabel= "organisation_name" formControlName="supplier_id" placeholder="Select..."></p-dropdown>
        </div>
        <div class="field col-12 md:col-5">
            <label>Role: </label>
            <p-autoComplete styleClass="w-full" multiple="true" (completeMethod)="filterRoles($event)" field="label" [dropdown]="true" name="roles" 
                [suggestions]="filteredRolesOptions" formControlName="roles"></p-autoComplete>
        </div>
        <div class="field col-12 md:col-5">
            <label>Start Date: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
                <p-calendar styleClass="w-full" formControlName="start_date" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>End Date: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
                <p-calendar styleClass="w-full" formControlName="end_date" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Job Title: </label>
            <input type="text" pInputText class="w-full" formControlName="job_title" name="job_title"/>
        </div>
        <div class="field col-12 md:col-5">
            <label>Latest Location: </label>
            <input type="text" pInputText class="w-full" formControlName="latest_location" name="latest_location"/>
        </div>
        <div class="field col-12 md:col-5">
            <label>Default Charge Rate: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                <input pInputText class="w-full" type="number" formControlName="default_charge_rate">
            </div>
        </div>
        <div class="field col-12 md:col-5">
            <label>Default Cost Rate: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                <input pInputText class="w-full" type="number" formControlName="default_cost_rate">
            </div>
        </div>
        <div class="field col-12 md:col-10">
            <label>Default Manager: </label>
            <p-dropdown styleClass="w-full" [options]="managers" formControlName="default_manager_id" optionLabel="project_name" optionValue="id" [filter]="true" filterBy="name,email,nickname"
                 placeholder="Select..." name="default_manager_id"></p-dropdown>
        </div>
    </form>
</div>

<button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
<button type="submit" (click)="onSubmit(createForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 
<button *ngIf="!isNew" pButton type="button" (click)="sendInvitation()" class="p-button-raised p-button-secondary mr-2 mb-2" icon="pi pi-envelope" label="Re-send invitation"></button>