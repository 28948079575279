import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './services/user.service';
import { TitleCasePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(private userService:UserService, private titlecasePipe: TitleCasePipe,private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let permName:string = route.data.permission //|| this.router.getCurrentNavigation().extras.state.permission
      let permAction:string = "view"
      
      if (this.userService.checkPermission(permName, permAction)){
        console.log("permissions valid")
        return true
      }
      else {
        console.log(`permissions unvalid (${permName}, ${permAction})`)
        this.router.navigate(['/unauthorized'])
        return false;
      }
  }
  
}
