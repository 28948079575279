import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import moment, { Moment } from "moment";
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { TimesheetApproval } from 'src/app/model/TimesheetApproval';
import { User } from 'src/app/model/User';

import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Assignment } from 'src/app/model/Assignment';

@Component({
  selector: 'app-check-timesheets',
  templateUrl: './check-timesheets.component.html',
  styleUrls: ['./check-timesheets.component.scss']
})
export class CheckTimesheetsComponent implements OnInit {

  constructor(public utilsService:UtilsService, public timesheetService: TimesheetService,
    public tenantService: TenantService,private router: Router, public userService:UserService,
    public messageService:MessageService,public dialogService: DialogService, 
    private breadcrumbService:BreadcrumbService) {
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink:['timesheet/dashboard']},
        { label: 'Check'},
        { label: 'Check Timesheets' }
      ]); }

  today:Moment;
  selectedWeek:Moment;
  timesheetsApprovals: TimesheetApproval[]=[];
  weeksArray:Moment[]=[]
  daysArray:Moment[];

  employeesTimesheets : any[] = []

  employees:User[]=[]
  assignments:Assignment[]=[]
  selectedEmployeesTs:any[]=[]
  //auth0ext_token:string=""

  loading:boolean = false;
  displayNotificationMessage:boolean = false;
  notificationMessage:string = ""

  ngOnInit(): void {
     //setting first day of the week
     let firstDay = this.tenantService.timesheetSettings.settings_json.week_start
     this.utilsService.updateFirstDayOfTheWeek(firstDay)
     this.today = moment().startOf('day');
     this.selectedWeek = this.getFirstDayOfWeek(this.today);
     this.daysArray = this.getDaysOfTheWeekArray(this.selectedWeek)
 
     this.updateWeeksArray()
     this.selectedWeek = this.weeksArray.find(el=>this.isCurrentWeek(el))
     this.updateTimesheets()
  }

  hasOpenAssignment(emp:User){
    
    let hasAssignment =  this.assignments.some(ass => emp.id == ass.employee_id 
      && new Date(ass.start_date)<=this.daysArray[6].toDate() && 
      (!ass.end_date || new Date(ass.end_date)>=this.daysArray[0].toDate())
    )
    console.log("has assignment",hasAssignment)
    return hasAssignment
  }

  updateTimesheets(){
    this.loading=true;
    if (this.selectedWeek){
      this.daysArray = this.getDaysOfTheWeekArray(this.selectedWeek)
      // console.log(this.daysArray)
    }
    let startDate = moment(this.selectedWeek)
    this.userService.getUsersByCriteria(new User({status:ActiveStatus.Active})).subscribe(empData=>{
    console.log(empData)
    this.employees=empData
    this.timesheetService.getAssignmentByCriteria(new Assignment({status:ActiveStatus.Active})).subscribe(assData=>{
      this.assignments = assData
      console.log(assData)
      this.employees = this.employees.filter(emp => this.hasOpenAssignment(emp))
      console.log("employees",this.employees)
      this.timesheetService.getTimesheetsByStartDate(startDate).subscribe(data=>{
        this.timesheetsApprovals = data;
        // console.log(data)
        this.employeesTimesheets = []
        for (let tsApp of this.timesheetsApprovals){
          let index = this.employeesTimesheets.findIndex(el=>el.employee.id==tsApp.Assignment.employee_id)
          let updatedRow;
          if (index == -1){
            updatedRow = {
              status:tsApp.status,
              employee: tsApp.Assignment.Employee
            }
          } else {
            updatedRow = this.employeesTimesheets[index]
          }
          for (let timeEntry of tsApp.TimeEntries){
            let tsDate = moment(timeEntry.date).format("L")
            updatedRow[tsDate] = (updatedRow[tsDate] ||0) + timeEntry.hours
          }

          if (index==-1) this.employeesTimesheets.push(updatedRow)
          else this.employeesTimesheets[index]=updatedRow
        }

        // adding employees without any timesheet entered
        for (let emp of this.employees){
          let index = this.employeesTimesheets.findIndex(el=>el.employee.id==emp.id)
          if (index == -1){
            this.employeesTimesheets.push({
              employee:emp, 
              status: "None"
            })
          }
        }
          // console.log(this.employeesTimesheets)
          this.loading = false
        })
      })
    })
  }

  updateWeeksArray(){
    let toSubstract = this.tenantService.timesheetSettings.settings_json.past_weeks_shown
    let toAdd = this.tenantService.timesheetSettings.settings_json.future_weeks_shown
    let minDate:Moment = moment().subtract(toSubstract, 'week')
    let maxDate :Moment = moment().add(toAdd,'week')
    this.weeksArray = this.getWeeksArray(minDate,maxDate)
    this.weeksArray.sort((a:Moment,b:Moment)=>a.isBefore(b)?1:-1)
  }


  getFirstDayOfWeek(m:Moment,firstDay?:String):Moment {
    return this.utilsService.getFirstDayOfWeek(m)
  }
  getDaysOfTheWeekArray(start):Moment[] {
    return this.utilsService.getDaysOfTheWeekArray(start)
  };
  getWeeksArray(start, end):Moment[] {
    return this.utilsService.getWeeksArray(start,end)
  };
  isCurrentWeek(week:Moment){
    return this.utilsService.isCurrentWeek(week,this.today)
  }

  notifySelectedUsers(){
    if (this.selectedEmployeesTs.length>0) {
      this.notificationMessage = this.tenantService.timesheetSettings.settings_json.submission_reminder
      this.displayNotificationMessage = true;
    }
    else {
      this.messageService.add({
        severity:'warnning', 
        summary: 'Please select at least one employee'
      })
    }
  }

  sendEmail(){
    let employeeEmails: any[] = this.selectedEmployeesTs.map(el=>{return el.employee})
    console.log(employeeEmails)
    let link:string = `${location.origin}/timesheet`
    this.timesheetService.notifySelectedEmployees(employeeEmails, link, this.notificationMessage, {PeriodStartDate:this.selectedWeek.format("L")},
    this.userService.currentUser.id,null).subscribe(data=>{
      console.log(data)
      this.messageService.add({severity:'info', summary: 'Email sent'});
      this.displayNotificationMessage = false
    })
  }

  getSumByEmployee(employeeTs:any){
    let sum:number = 0
    for (let att in employeeTs){
      if (att!="employee" && att!="status") sum+=employeeTs[att]
    }
    return sum
  }

  seeTimesheet(employeeTs:any){
    this.router.navigateByUrl('/timesheet/view/'+employeeTs.employee.id)
  }
}
