<p-table #dt [value]="assignments" [columns] = "cols" responsiveLayout="scroll" [loading]="loading" [globalFilterFields]="getCompleteFields()"
[rows]="20" [showCurrentPageReport]="true" [rowsPerPageOptions]="[20,30,50]"  [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
    <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
            <div style="flex-direction: row;display: flex;">
                <h3>Assignments</h3>
                <button *ngIf = "canAdd" pButton class="p-button-success ml-4 mb-3" (click)="addAssignment()" label="Create"  icon="pi pi-plus"></button>
            </div>
            <p-toolbar >
                <ng-template pTemplate="left">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText class="w-full" type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search assignment" />
                   </span>
                   <p-selectButton [options]="timesheetService.activeStatusOptions" [(ngModel)]="activeFilter" (onChange)="dt.filter(activeFilter, 'status','equals')" class="ml-2"></p-selectButton>
                </ng-template>

                <ng-template pTemplate="right">     
                    <button pButton type="button" icon="pi pi-refresh" (click)="updateAssignments()" class="p-button-primary mr-2" pTooltip="Refresh" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-table" (click)="dt.exportCSV()" class="p-button-warning mr-2" pTooltip="Export to CSV" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button green-bgcolor mr-2"  pTooltip="Export to XLS" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-danger mr-2" pTooltip="Export to PDF" tooltipPosition="bottom"></button>           
                </ng-template>
            </p-toolbar>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.completeField" >
                {{col.header}}
                <p-sortIcon [field]="col.completeField"></p-sortIcon>               
            </th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
                <span *ngIf="col.isDate && rowData[col.field];else nodate">{{(col.nestedField&&rowData[col.field]?rowData[col.field][col.nestedField]:rowData[col.field])|date: tenantService.timesheetSettings.settings_json.date_format}}</span>
                <ng-template #nodate>{{(col.nestedField&&rowData[col.field]?rowData[col.field][col.nestedField]:rowData[col.field])}}</ng-template>
            </td>
            <td>
                <button *ngIf = "canEdit" pButton type="button" (click)="editAssignment(rowData)" icon="pi pi-pencil" ></button>
            </td>
        </tr>
    </ng-template>
</p-table>