<div class="layout-wrapper" [ngClass]="{'p-input-filled': inputStyle === 'filled', 'p-ripple-disabled': !ripple,
'layout-menu-static': isStatic(),
'layout-menu-overlay': isOverlay(),
'layout-menu-horizontal': isHorizontal(),
'layout-menu-slim': isSlim(),
'layout-menu-active': menuActive,
'layout-menu-mobile-active': mobileMenuActive,
'layout-topbar-mobile-active': mobileTopbarActive,
'layout-rightmenu-active': rightMenuActive,
'layout-rtl': isRTL}" [class]="'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme">

    <app-header [selectedModule]="selectedModule"></app-header>
    <div class="layout-main" >
        <app-breadcrumb></app-breadcrumb>
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>
</div>
