//unused
<!-- <div class="card">
    <div class="card-header">
        <h3>{{report.report_name}}</h3>
    </div>
    <div class="p-col formgrid grid">
        <div class="field col-12 md:col-2">
            <label>From: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
                <p-calendar styleClass="w-full" [(ngModel)]="dateFrom" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
            </div>
        </div>
        <div class="field col-12 md:col-2">
            <label>To: </label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
                <p-calendar styleClass="w-full" [(ngModel)]="dateTo" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
            </div>
        </div>

        <div *ngIf="displayProjectDropdown" class="field col-12 md:col-4">
            <label>Project: </label>
            <p-multiSelect styleClass="w-full" [options]="projects" [(ngModel)]="selectedProject" optionLabel="project_name" [filter]="true" filterBy="project_name, project_number"
             name="task_id" placeholder="All"></p-multiSelect>
        </div>

        <div *ngIf="displayStaffDropdown" class="field col-12 md:col-4">
            <label>Staff: </label>
            <p-multiSelect [options]="staff" styleClass="w-full" [(ngModel)]="selectedStaff" optionLabel="name" [filter]="true"
             filterBy="name, email" placeholder="All"></p-multiSelect>
        </div>

        <div *ngIf="displayStaffTypeDropdown" class="field col-12 md:col-2">
            <label>Employment Type: </label>
            <p-multiSelect [options]="staffTypes" styleClass="w-full" [(ngModel)]="selectedStaffType" placeholder="All"></p-multiSelect>
        </div>

        <div class="field col-12 md:col-2">
            <label>Template: <i class="pi pi-info-circle" pTooltip="If no template is selected, the default project template will be applied"></i></label>
            <p-multiSelect [options]="templates" styleClass="w-full" [(ngModel)]="selectedTemplate" placeholder="All"></p-multiSelect>
        </div>

        <div class="field col-12 md:col-2" style="padding: 20px;">
            <p-checkbox label = "Approved Timesheets Only" [(ngModel)]="approvedTimesheetOnly" [binary]="true"></p-checkbox>
        </div>
    </div>

    <div class="card-footer">
        <p-button label="Preview" (click)="display()" icon="pi pi-search"></p-button>
    </div>
</div>
<div *ngIf="displayPreview && !tableByTsApproval; else tablesList" class="card">
    <p-table [value]="compiledData" responsiveLayout="scroll" [rowGroupMode]="groupMode" [groupRowsBy]="groupBy"
    #dt responsiveLayout="scroll" [columns]="cols" [sortField]="sortBy">
        <ng-template pTemplate="caption">
            <div class="flex flex-column md:flex-row md:justify-content-between table-header">
                Preview
                <div>
                    <button pButton type="button" icon="pi pi-refresh" (click)="updateData()" class="p-button-primary mr-2" pTooltip="Refresh" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-table" (click)="dt.exportCSV()" class="p-button-warning mr-2" pTooltip="Export to CSV" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button green-bgcolor mr-2"  pTooltip="Export to XLS" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-danger mr-2" pTooltip="Export to PDF" tooltipPosition="bottom"></button>            
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="groupheader" let-row>
            <tr pRowGroupHeader>
                <td [colspan]="getNbCols()">
                    <span class="font-bold ml-2">{{row[groupBy]}}</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <ng-container *ngFor="let col of columns">
                    <th *ngIf="col.toPrint">
                        {{col.label}}          
                    </th>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <ng-container *ngFor="let col of columns">
                    <td *ngIf="col.toPrint && col.field!='date'">
                        {{col.isCurrency?"$":""}}{{(col.nestedField&&rowData[col.field]?rowData[col.field][col.nestedField]:rowData[col.field])}}
                    </td>
                    <td *ngIf="col.toPrint && col.field=='date'">
                        {{rowData[col.field] |date: tenantService.timesheetSettings.settings_json.date_format}}
                    </td>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
</div>
<div *ngIf="displayPreview && tableByTsApproval" class="card">
    <div class="flex flex-column md:flex-row md:justify-content-end table-header"> 
        <button pButton type="button" icon="pi pi-refresh" (click)="updateData()" class="p-button-primary mr-2" pTooltip="Refresh" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-table" (click)="dt.exportCSV()" class="p-button-warning mr-2" pTooltip="Export to CSV" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button green-bgcolor mr-2"  pTooltip="Export to XLS" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-danger mr-2" pTooltip="Export to PDF" tooltipPosition="bottom"></button>            
    </div>
    <div *ngFor="let projEmp of compiledData" class="card">
        <p-table [value]="projEmp.time_entries" responsiveLayout="scroll" #dt responsiveLayout="scroll" [columns]="cols" [sortField]="sortBy">
            <ng-template pTemplate="caption">
                <div>Client: {{projEmp.Project.Client.organisation_name}}</div>
                <div>Project: {{projEmp.Project.project_number}} {{projEmp.Project.project_name}}</div>
                <div>Employee: {{projEmp.Employee.name}}</div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <th *ngIf="col.toPrint">
                            {{col.label}}          
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <td *ngIf="col.toPrint && col.field!='date'">
                            {{col.isCurrency?"$":""}}{{(col.nestedField&&rowData[col.field]?rowData[col.field][col.nestedField]:rowData[col.field])}}
                        </td>
                        <td *ngIf="col.toPrint && col.field=='date'">
                            {{rowData[col.field] |date: tenantService.timesheetSettings.settings_json.date_format}}
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div style="font-weight: 200;font-size: smaller;">{{getFooter(projEmp)}}</div>
                <p-divider></p-divider>

                <div style="font-size: smaller;">Submitted By: {{projEmp.submitted_by}}</div>
                <div style="font-size: smaller;">Submitted On: {{projEmp.submitted_at |date: tenantService.timesheetSettings.settings_json.date_format}}</div>
            </ng-template>
        </p-table>
    </div>
</div> -->