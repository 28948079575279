// import { Role } from "../../../../archive/Role";
import { CurrencyPipe } from "@angular/common";
import { ActiveStatus } from "./ActiveStatus";
import { Currency } from "./Currency";
import { Project } from "./Project";
import { User } from "./User";

export class Assignment {
    id:number;
    project_id: number;
    position_name: string;
    employee_id: number ;
    cost_rate: number;
    charge_rate: number;
    start_date: Date;
    end_date: Date;
    status: ActiveStatus;
    tenant_id: number;

    cost_rate_currency_id: number;
    CostRateCurrency: Currency;

    charge_rate_currency_id: number;
    ChargeRateCurrency: Currency;

    // Role:Role;
    Employee:User;
    Project:Project;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    
    constructor(assignment){
        this.cost_rate = assignment.cost_rate|| null;
        this.charge_rate = assignment.charge_rate || null;
        this.start_date = assignment.start_date || null;
        this.end_date = assignment.end_date || null;
        this.position_name = assignment.position_name || null;
        this.status = assignment.status || null;
        this.employee_id = assignment.employee_id || null;
    }
}