import { ReportType } from "./ReportType";

export class Report {
    id:number;
    report_name: string;
    report_type_id: number;
    description: string;
    // ReportType:ReportType

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(report){
        this.id = report.id || null;    
        this.report_name = report.report_name || null;
    }
}