<div class="layout-topbar shadow-4">
    <div class="layout-topbar-left">
        <a class="layout-topbar-logo" routerLink="/timesheet/dashboard" style="padding-left:2rem; justify-content: left;width: 13rem;">
            <img id="app-logo" src="assets/layout/images/ecs logo.png" alt="ultima-layout" style="height: 4rem;padding-top: 5px;">
        </a>
        <a class="layout-menu-button shadow-6" style="left: 11.75rem;" (click)="app.onMenuButtonClick($event)" pRipple>
            <i class="pi pi-chevron-right"></i>
        </a>
        <a class="layout-topbar-mobile-button" (click)="app.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>
    </div>

    <div class="layout-topbar-right" style="display:flex;">
        <div class="layout-topbar-actions-left">
            <p-menubar  *ngIf="auth.isAuthenticated$ | async" [model]="subMenu" class="submenu" autoDisplay="true" appendTo="body"></p-menubar>
        </div>
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <li class="layout-topbar-action rounded-circle">
                    <p-button *ngIf="(auth.isAuthenticated$ | async) === false" class="btn btn-primary btn-block" (click)="loginWithRedirect()">Log in</p-button>
                    <p-menubar *ngIf="auth.isAuthenticated$ | async" [model]="userMenu" styleClass="layout-menu" appendTo="body"></p-menubar>
                </li>
            </ul>
        </div>
    </div>
</div>
<app-side-menu></app-side-menu>




        
    


