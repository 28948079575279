import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-unlicensed',
  templateUrl: './unlicensed.component.html',
  styleUrls: ['./unlicensed.component.scss']
})
export class UnlicensedComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  module:string;
  ngOnInit(): void {
    this.module = this.route.snapshot.paramMap.get('module')
  }

}
