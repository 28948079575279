import { ActiveStatus } from "./ActiveStatus";
import { Organisation } from "./Organisation";
import { User } from "./User";

export class TimesheetTemplate {
    id:number;
    name: string;
    is_default: boolean;
    include_description: boolean;
    include_task: boolean;
    include_profit: boolean;
    footer: string;
    tenant_id: number;
    status: ActiveStatus;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
  
    constructor(template){
        this.status = template.status || ActiveStatus.Active;
    }
}