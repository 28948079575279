import { Component, OnInit } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import moment from 'moment';
import { AppComponent } from 'src/app/app.component';
import { Assignment } from 'src/app/model/Assignment';
import { Project } from 'src/app/model/Project';
import { TimesheetApproval } from 'src/app/model/TimesheetApproval';
import { TimesheetNotification } from 'src/app/model/TimesheetNotification';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-timesheet-dashboard',
  templateUrl: './timesheet-dashboard.component.html',
  styleUrls: ['./timesheet-dashboard.component.scss']
})
export class TimesheetDashboardComponent implements OnInit {

  constructor(public app:AppComponent, public timesheetService:TimesheetService, public userService: UserService,
    private breadcrumbService:BreadcrumbService, private tenantService: TenantService) {
      this.breadcrumbService.setItems([
        { label: 'Timesheet' , routerLink:['timesheet/dashboard']},
        { label: 'Dashboard', routerLink:['timesheet/dashboard'] }
      ]); }
  ordersChart: any;
  ordersOptions: any;

  overviewChartAssignments:any;
  overviewChartHours:any;
  overviewChartOptions1:any;

  currentAssignments: Assignment[]=[];
  allAssignments: Assignment[]=[];
  currentUser:User;
  numberClients: number;
  timesheetApprovals: TimesheetApproval[]=[]
  timesheetNotifications: TimesheetNotification[] = []
  currentDate = new Date();
  startOfYear = moment(this.currentDate).startOf("year");
  monthArray:string[] = []
  numberOfHoursArray:number[] = []
  numberOfAssignmentsArray:number[] = []

  

  ngOnInit(): void {
    this.currentUser = this.userService.currentUser
    this.timesheetService.getAssignmentByCriteria(new Assignment({employee_id:this.currentUser.id})).subscribe(assignments=>{
        this.allAssignments = assignments
        this.currentAssignments = assignments.filter(ass=>this.isAssignmentActive(ass)).sort((a,b)=>{return (new Date(b.start_date).getMilliseconds()-new Date(a.start_date).getMilliseconds())})
        this.numberClients = [...new Set(this.currentAssignments.map(ass=>ass.Project.Client))].length
    
        this.timesheetService.getTimesheetsBetweenDates(this.startOfYear,moment(this.currentDate),this.currentUser.id).subscribe(data=>{
            this.timesheetApprovals = data;
            
            // prepare data
            for (let i =0; i<=6; i++){
                let month = moment().subtract(6-i, "month")
                let numberOfHours = this.timesheetApprovals.reduce((prevTsApp,tsApp) => {
                    if (moment(tsApp.week_start)<=month.endOf("month") && moment(tsApp.week_start)>=month.startOf("month")){
                        prevTsApp+=tsApp.TimeEntries.reduce((prev,curr)=>{return prev+curr.hours},0)
                    }
                    return prevTsApp
                },0)
                let monthlyAssignments = this.allAssignments.filter(ass=>moment(ass.start_date)<=month.endOf("month") && (!ass.end_date || moment(ass.end_date)>=month.startOf("month")))
                this.numberOfAssignmentsArray.push(monthlyAssignments.length)
                this.numberOfHoursArray.push(numberOfHours)
                let monthName = month.startOf("month").format('MMMM');
                this.monthArray.push(monthName)
            }
            console.log(this.numberOfAssignmentsArray)
            this.ordersChart = {
                labels: this.monthArray,
                datasets: [ {
                data: this.numberOfHoursArray,
                borderColor: [
                    '#3F51B5',
                ],
                backgroundColor: [
                    'rgba(63, 81, 181, 0.8)',
                ],
                borderWidth: 2,
                fill: true,
                tension: .4
            }]
            };
            this.overviewChartAssignments = {
            labels: this.monthArray,
            datasets: [
                {
                    data: this.numberOfAssignmentsArray,
                    borderColor: [
                        '#009688',
                    ],
                    backgroundColor: [
                        '#4DB6AC',
                    ],
                    borderWidth: 2,
                    fill: true,
                    tension: .4
                }
            ]
            };
            this.overviewChartHours = {
            labels: this.monthArray,
            datasets: [
                {
                    data: this.numberOfHoursArray,
                    borderColor: [
                        '#E91E63',
                    ],
                    backgroundColor: [
                        '#F06292',
                    ],
                    borderWidth: 2,
                    fill: true,
                    tension: .4
                }
            ]
            };
        })
    })
    
    this.timesheetService.getTimesheetNotificationsByCriteria(new TimesheetNotification({receiver_id:this.currentUser.id})).subscribe(data=>{
        this.timesheetNotifications = data.sort((a,b)=>(new Date(b.date).getMilliseconds() - new Date(a.date).getMilliseconds())).slice(0,2)
    })

    this.ordersOptions = this.getOrdersOptions();

    this.overviewChartOptions1 = {
      plugins: {
          legend: {
              display: false
          }
      },
      scales: {
          y: {
              display: false
          },
          x: {
              display: true
          }
      },
      tooltips: {
          enabled: false
      },
      elements: {
          point: {
              radius: 0
          }
      },
    };
  }

  getTotalSum(){
    return this.timesheetApprovals.reduce((prevTsApp,tsApp) => {
        return prevTsApp+=tsApp.TimeEntries.reduce((prev,curr)=>{return prev+curr.hours},0)
    },0)
  }

  isAssignmentActive(ass:Assignment){
    return (new Date(ass.start_date)<= this.currentDate && (!ass.end_date || new Date(ass.end_date)>=this.currentDate)) 
  }

  getOrdersOptions() {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
        plugins: {
            legend: {
                display: false,
                labels: {
                    fontFamily,
                    fontColor: textColor,
                }
            }
        },
        responsive: true,
        scales: {
            y: {
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            },
            x: {
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            }
        }
    };
}

}