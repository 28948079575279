<p-table #dt [value]="currencies" [columns] = "cols" responsiveLayout="scroll" [loading]="loading" [globalFilterFields]="getCompleteFields()"
[rows]="20" [showCurrentPageReport]="true" [rowsPerPageOptions]="[20,30,50]"  [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
    <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
            <div style="flex-direction: row;display: flex;">
                <h3>Currencies</h3>
                <button pButton class="p-button-success ml-4 mb-3" (click)="addCurrency()" label="Create"  icon="pi pi-plus"></button>
            </div>
            <p-toolbar >
                <ng-template pTemplate="left">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText class="w-full" type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
                    </span>
                    <!-- <p-selectButton [options]="tenantService.tenantCurrencyStatusOptions" [(ngModel)]="activeFilter" (onChange)="filterByStatus(dt)" class="ml-2"></p-selectButton> -->
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton type="button" icon="pi pi-refresh" (click)="updateCurrencies()" class="p-button-primary mr-2" pTooltip="Refresh" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-table" (click)="dt.exportCSV()" class="p-button-warning mr-2" pTooltip="Export to CSV" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button green-bgcolor mr-2"  pTooltip="Export to XLS" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-danger mr-2" pTooltip="Export to PDF" tooltipPosition="bottom"></button>           
                </ng-template>
            </p-toolbar>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" >
                <span>{{col.header}}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>               
            </th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns" >
                {{(col.nestedField&&rowData[col.field][0]?rowData[col.field][0][col.nestedField]:rowData[col.field])}}
            </td>
            <td>
                <button pButton type="button" (click)="editRow(rowData)" icon="pi pi-pencil" ></button>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog header="Currency" [(visible)]="displayCurrencyDetails" [style]="{width: '50vw'}">
    <div class="field col-12 md:col-10">
        <label>Name: </label>
        <input type="text" pInputText class="w-full" [(ngModel)]="selectedCurrency.name" required="true"/>
        <div *ngIf="selectedCurrency.name==''" class="alert alert-danger">
            <div>Name is required.</div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="cancel()" class="p-button-raised p-button-secondary mr-2 mb-2" label="Cancel"></button>
        <button type="button" (click)="submit()" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 
    </ng-template>
</p-dialog>

