<p-toast></p-toast>
<div class="card">
    <h3 style="padding: 0.75rem 0;">Time Entry Settings</h3>
    <form [formGroup]="timeEntrySettingsForm" class="p-fluid p-formgrid grid">
        <div class="field col-12 md:col-5">
            <label>Date Format:<i class="pi pi-info-circle" pTooltip="Date format used in display tables ad reports"></i></label>
            <!-- <p-inputMask formControlName="date_format" mask="dd/MM/YYYY" characterPattern="[А-Яа-я]"></p-inputMask> -->
            <p-dropdown styleClass="w-full" formControlName="date_format" [options] = "dateFormats" [disabled]="!canEdit"></p-dropdown>
            <i>Example: {{getDate() | date: timeEntrySettingsForm.value.date_format}}</i>
        </div>
        <div class="field col-12 md:col-5">
            <label>First Day Of The Week:<i class="pi pi-info-circle" pTooltip="First day of the week used in the timesheet view and check screens, and in the reports. Any change to this setting will make all previously approved weeks appear as if they have not been submitted for approval"></i></label>
            <p-dropdown styleClass="w-full" formControlName="week_start" [options] = "weekdays"  [disabled]="!canEdit"></p-dropdown>
        </div>
        <div class="field col-12 md:col-5">
            <label>Past Weeks Shown:<i class="pi pi-info-circle" pTooltip="Number of past weeks available in the timesheet view and check screens"></i></label>
            <p-inputNumber styleClass="w-full" formControlName="past_weeks_shown" [min]="0" mode= "decimal" [max]="50" [disabled]="!canEdit" [showButtons]="true" incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down"></p-inputNumber>
        </div>
        <div class="field col-12 md:col-5">
            <label>Future Weeks Shown:<i class="pi pi-info-circle" pTooltip="Number of future weeks available in the timesheet view and check screens"></i></label>
            <p-inputNumber styleClass="w-full"  formControlName="future_weeks_shown" mode= "decimal" [min]="0" [max]="50" [disabled]="!canEdit" [showButtons]="true" incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down"></p-inputNumber>
        </div>
        <div class="field col-12 md:col-5">
            <p-checkbox label = "Allow Negative Time" formControlName="allow_negative_time" [binary]="true" inputId="binary" [disabled]="!canEdit"></p-checkbox>
            <i class="pi pi-info-circle" pTooltip="Whether time entries with a null value can be entered or not. Changing this setting won't affect time entries that have already been entered."></i>
        </div>
        <div class="field col-12 md:col-5">
            <p-checkbox label = "Allow Zero Time" formControlName="allow_zero_time" [binary]="true" inputId="binary" [disabled]="!canEdit"></p-checkbox>
            <i class="pi pi-info-circle" pTooltip="Whether time entries with a negative value can be entered or not. Changing this setting won't affect time entries that have already been entered."></i>
        </div>
        <div class="field col-12 md:col-5">
            <label>Lockdown Date:<i class="pi pi-info-circle" pTooltip="Time Entries dated before this date can no longer be modified, even by an administrator. "></i></label>
            <div class="p-inputgroup"> 
                <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
                <p-calendar styleClass="w-full" formControlName="lockdown_date" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
            </div>
        </div>
    </form>
    <button type="submit" (click)="onSubmit(timeEntrySettingsForm.value)" class = "p-button-raised mr-2 mb-2" pButton label="Save" ></button> 
</div>


