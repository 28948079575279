import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Auth0Role } from 'src/app/model/Auth0Role';
import { EmploymentType, User } from 'src/app/model/User';
import { TenantService } from 'src/app/services/tenant.service';

import { AuthService } from '@auth0/auth0-angular';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { Organisation } from 'src/app/model/Organisation';
import { UserService } from 'src/app/services/user.service';
import { Role } from 'src/app/model/Role';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-dialog-user-details',
  templateUrl: './dialog-user-details.component.html',
  styleUrls: ['./dialog-user-details.component.scss']
})
export class DialogUserDetailsComponent implements OnInit {

  //auth0_token:string=""
  //auth0ext_token:string = ""
  new_user: User = new User({status:ActiveStatus.Active})
  roles:Auth0Role[]=[]
  createForm:FormGroup= this.fb.group({
    'id': [],
    'auth0_id': [],
    'name':[,Validators.required],
    'nickname':[,],
    'email':[,Validators.required],
    'latest_location':[,],
    'job_title':[,],
    'default_cost_rate':[,],
    'default_charge_rate':[,],
    'start_date':[,],
    'end_date':[,],
    'status':[,],
    'employment_type':[,],
    'roles':[[],],
    'default_manager_id':[,],
    'supplier_id':[,],
  })
  isNew: boolean = true
  
  managers:User[]=[]
  suppliers:Organisation[]=[]

  statusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]
  employmentOptions = [EmploymentType.Casual,EmploymentType.Contractor,EmploymentType.FullTime, EmploymentType.PartTime]
  rolesOptions:Role[] = []
  filteredRolesOptions:Role[] = []

  currentUser:User;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private fb: FormBuilder,
    private messageService: MessageService, public timesheetService:TimesheetService,
    private tenantService: TenantService, private userService: UserService, private utilsService:UtilsService) {
      if (config.data && config.data.user) {
        this.new_user = this.config.data.user
        this.isNew = false
        this.createForm.patchValue(this.new_user)
        // console.log("user",this.new_user)
      }
    }

  ngOnInit(): void {
    // console.log("is new", this.isNew)
    // console.log("user", this.new_user)
    this.currentUser = this.userService.currentUser

    this.userService.getUsersByCriteria(new User({status:ActiveStatus.Active})).subscribe(data=>this.managers=data)
    this.timesheetService.getOrganisationsByCriteria(new Organisation({status:ActiveStatus.Active})).subscribe(data=>this.suppliers=data)
    this.userService.getRolesByCriteria(new Role({status:ActiveStatus.Active})).subscribe(data=>{
      this.rolesOptions = data
      this.filteredRolesOptions = this.rolesOptions
      // this.rolesOptions=[]
      // for (let role of data){
      //   var group = this.rolesOptions.find(el=>el.label==role.category)
      // }

    })
    let canEdit = this.userService.checkPermission("Users","edit")
    if (!canEdit){this.createForm.disable()}
  }
  cancel(){
    this.ref.close();
  }

  onSubmit(form:User){
    //console.log("current tenant",this.tenantService.selectedTenantId)
    if (this.createForm.valid){
      this.new_user = form

      if (this.isNew){
          this.userService.createUser(form).subscribe(res=>{
            this.new_user=res
            this.ref.close(this.new_user);
          })
          
      }
      else {
        this.userService.updateUser(form).subscribe(res=>{
          this.new_user=form
          this.ref.close(this.new_user);
        })
      }
    } else {
      console.log("Not valid")
    }
  }
  filterRoles(event){
    this.filteredRolesOptions = this.utilsService.filterRoles(this.rolesOptions,event)
  }

  sendInvitation(){
    this.userService.sendResetPasswordEmail(this.createForm.value).subscribe(data=>{
      console.log(data)
      this.messageService.add({severity:'success', summary: 'Notification sent', detail: this.createForm.value.name});
    })
  }

    // for mandatory fields
    get name() { return this.createForm.get('name'); }
    get email() { return this.createForm.get('email'); }
    get Role() { return this.createForm.get('Role'); }
}

