
<!-- <div class="card"> -->
    <h3>Reports</h3>
    <div class="p-grid">
        <p-accordion [multiple]="true">
            <p-accordionTab header="Favourites" [selected]="favouriteReports && favouriteReports.length>0">
                <p-table [value]="favouriteReports" (onRowSelect)="goToReport($event)" dataKey="id" selectionMode="single">
                    <ng-template pTemplate="body" let-favourite>
                        <tr [pSelectableRow]="favourite">
                            <td><button pButton pRipple type="button" icon="pi pi-star-fill" class="p-button-rounded p-button-text mr-2 mb-2" (click)="removeFromFavourites(favourite)" ></button></td>
                            <td>{{favourite.Report.report_name}}</td>
                            <td>{{favourite.Report.description}}</td>
                            <td><button pButton pRipple type="button" icon="pi pi-angle-double-right" (click)="goToReport(favourite.Report)" class="p-button-rounded p-button-text mr-2 mb-2"></button></td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab *ngFor="let type of reportTypes"  [header]="type.report_type_name" [selected]="true">
                <p-table [value]="type.Reports" dataKey="id">
                    <ng-template pTemplate="body" let-report>
                        <tr>
                            <td>
                                <button *ngIf="isInFavorites(report); else noFavorite" pButton pRipple type="button" icon="pi pi-star-fill" class="p-button-rounded p-button-text mr-2 mb-2" (click)="removeReportFromFavourites(report)" ></button>
                                <ng-template #noFavorite>
                                    <button pButton pRipple type="button" icon="pi pi-star" (click)="addToFavourites(report)" class="p-button-rounded p-button-text mr-2 mb-2"></button>
                                </ng-template>
                            </td>
                            <td>{{report.report_name}}</td>
                            <td>{{report.description}}</td>
                            <td><button pButton pRipple type="button" icon="pi pi-angle-double-right" (click)="goToReport(report)" class="p-button-rounded p-button-text mr-2 mb-2"></button></td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
        </p-accordion>
    <!-- </div> -->