import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { Auth0Role } from 'src/app/model/Auth0Role';
import { Organisation } from 'src/app/model/Organisation';
import { User } from 'src/app/model/User';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-organisation-details',
  templateUrl: './dialog-organisation-details.component.html',
  styleUrls: ['./dialog-organisation-details.component.scss']
})
export class DialogOrganisationDetailsComponent implements OnInit {

  // //auth0_token:string=""
  // //auth0ext_token:string = ""
  new_organisation: Organisation = new Organisation({status: ActiveStatus.Active})
  // roles:Auth0Role[]=[]
  createForm:FormGroup= this.fb.group({
    'id': [],
    'organisation_name':[,Validators.required],
    'organisation_code':[,Validators.required],
    'reference':[],
    'notes':[],
    'status':[],
    'is_supplier':[,Validators.required],
    'is_client':[,Validators.required],
  })

  isNew: boolean = true
  statusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]
  booleanOptions = [true,false]

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private fb: FormBuilder,
    private messageService: MessageService, public timesheetService:TimesheetService, private userService: UserService) {
      if (config.data && config.data.organisation) {
        this.new_organisation = this.config.data.organisation
        this.isNew = false
      }
      this.createForm.patchValue(this.new_organisation)
    }

  ngOnInit(): void {
    let canEdit = this.userService.checkPermission("Organisations","edit")
    if (!canEdit){this.createForm.disable()}

  }
  cancel(){
    this.ref.close();
  }

  onSubmit(form:Organisation){
    if (this.createForm.valid){
      this.new_organisation = form

      if (this.isNew){
        this.timesheetService.createOrganisation(form).subscribe(data=>{
          this.new_organisation=data
          this.ref.close(this.new_organisation);
        })
      }
      else {
        this.timesheetService.updateOrganisation(this.new_organisation).subscribe(data=> {
          //this.new_organisation=data
          this.ref.close(this.new_organisation);
        })
      }
    }
  }
    // for mandatory fields
    get organisation_name() { return this.createForm.get('organisation_name'); }
    get organisation_code() { return this.createForm.get('organisation_code'); }
    get reference() { return this.createForm.get('reference'); }
    get notes() { return this.createForm.get('notes'); }
    get is_client() { return this.createForm.get('is_client'); }
    get is_supplier() { return this.createForm.get('is_supplier'); }
}
