import { Component, OnInit } from '@angular/core';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { Assignment } from 'src/app/model/Assignment';
import { Organisation } from 'src/app/model/Organisation';
import { ImportLog } from 'src/app/model/ImportLog';
import { Project } from 'src/app/model/Project';
import { Task } from 'src/app/model/Task';
import { TimeEntry } from 'src/app/model/TimeEntry';
import { User } from 'src/app/model/User';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { ImportService } from 'src/app/services/import.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  listMsgs:string[] = []
  stepsItems: MenuItem[] = [
    {label:'Format'},
    {label:'Upload'},
    {label:'Preview'}
  ]
  table_names: MenuItem[] =[]
  current_table: MenuItem 
  uploadedFiles : Map<string,any> = new Map<string,any>()

  lastImportLogs:ImportLog[]=[]

  constructor(private messageService: MessageService, private breadcrumbService: BreadcrumbService,
    private importService:ImportService, private exportService:ExportService, private userService: UserService) {
      this.breadcrumbService.setItems([
          { label: 'Timesheets', routerLink:['timesheet/dashboard'] },
          { label: 'Import' }
      ]);
  }
  ngOnInit(): void {
    this.table_names = [{
      label:'Organisations',
      visible: this.userService.checkPermission("Organisations", "add")
    },{
      label:'Users',
      visible: this.userService.checkPermission("Users", "add")
    },{
      label:'Projects',
      visible: this.userService.checkPermission("Projects", "add")
    },{
      label:'Tasks',
      visible: this.userService.checkPermission("Tasks", "add")
    }, {
      label:'Assignments',
      visible: this.userService.checkPermission("Assignments", "add")
    }, {
      label:'Time Entries',
      visible: this.userService.checkAtLeastOnePermission(['Supervised Timesheets - Time Entries', 'Own Timesheets - Time Entries'], "add")
    }]
    this.current_table = this.table_names[0]
    this.getLastImportLogs()
    this.resetInfoMessage()
  }

  uploadFile(event,fileUpload){
    this.messageService.add({severity: 'success', summary: 'File Uploaded', detail: event.files[0].name});
    this.uploadedFiles.set(this.current_table.label,event.files[0])

    fileUpload.clear()
  }

  resetInfoMessage(){
    switch(this.current_table.label){
      case 'Users':
        this.listMsgs = [
          "Status can be 'Active' or 'Inactive'",
          "Employment Type is 'Casual', 'Contractor', 'Full-time' or 'Part-time'",
          "Cost Rate and Charge Rate must be numbers",
          "Suppliers and managers must have been created beforehand",
          "A list of comma separated role names must be supplied for the field roles"
        ]
        break;
      case 'Organisations':
        this.listMsgs = [
          "Status can be 'Active' or 'Inactive'",
          "is_supplier and is_client can be TRUE or FALSE",
          "The organisation code can include numbers and letters"
        ]
        break;
      case 'Projects':
        this.listMsgs = [
          "Status can be 'Active' or 'Inactive'",
          "Project managers and clients must have been created beforehand",
          "Chargeable can be TRUE or FALSE",
          "The project number can include numbers and letters",
          "Budget Hours must be a number",
        ]
        break;
      case 'Tasks':
        this.listMsgs = [
          "Status can be 'Active' or 'Inactive'",
          "The project must have been created beforehand",
          "Chargeable can be TRUE or FALSE",
          "Budget and Budget Hours must be numbers",
        ]
        break;
      case 'Assignments':
        this.listMsgs = [
          "Status can be 'Active' or 'Inactive'",
          "The project and the employee must have been created beforehand",
          "Cost Rate and Charge Rate must be numbers",
          "Start Date and End Date must be dates"
        ]
        break;
      case 'Time Entries':
        this.listMsgs = [
          "Status can be 'Pending', 'Submitted', 'Rejected' or 'Approved'",
          "The employee must have been assigned to the project beforehand",
          "If both hours and start and finish times are populated, the field hours will be taken into account",
          "Hours must be a number, start and finish time must be a timestamp"
        ]
        break;
    }
  }

  startImport(table_name){
    this.messageService.add({severity: 'success', summary: 'Import starting ...', detail: ''});
        this.importService.fileToJson(this.uploadedFiles.get(table_name), table_name).then(json=>{
          switch (table_name){
            case 'Users':
              this.importService.importUsersBulk(json).subscribe(data=>{
                //console.log(data)
                this.messageService.add({severity: 'success', summary: table_name + ' import successfully started', detail: ''});
                this.getLastImportLogs();  
              })
              break;
            case 'Organisations':
              this.importService.importOrganisationsBulk(json).subscribe(data=>{
                //console.log(data)
                this.messageService.add({severity: 'success', summary: table_name + ' import successfully started', detail: ''});
                this.getLastImportLogs();  
              })
              break;
            case 'Projects':
              this.importService.importProjectsBulk(json).subscribe(data=>{
                //console.log(data)
                this.messageService.add({severity: 'success', summary: table_name + ' import successfully started', detail: ''});
                this.getLastImportLogs();  
              })
              break;
            case 'Tasks':
              this.importService.importTasksBulk(json).subscribe(data=>{
                //console.log(data)
                this.messageService.add({severity: 'success', summary: table_name + ' import successfully started', detail: ''});
                this.getLastImportLogs();  
              })
              break;
            case 'Assignments':
              this.importService.importAssignmentsBulk(json).subscribe(data=>{
                //console.log(data)
                this.messageService.add({severity: 'success', summary: table_name + ' import successfully started', detail: ''});
                this.getLastImportLogs(); 
              })
              break;
            case 'Time Entries':
              this.importService.importTimeEntriesBulk(json).subscribe(data=>{
                //console.log(data)
                this.messageService.add({severity: 'success', summary: table_name + ' import successfully started', detail: ''});
                this.getLastImportLogs();  
              })
              break;
          }
        })
  }

  hasFile(){
    return this.uploadedFiles.get(this.current_table.label)!=undefined
  }

  getLastImportLogs(){
    this.lastImportLogs = []
    this.importService.getLastImportLog(this.current_table.label).subscribe(data=>{
      // //console.log("log table updated",data)
      this.lastImportLogs = data
    })
  }

  resetAll(){
    this.uploadedFiles = new Map<string,any>()
    this.current_table = this.table_names[0]
  }
  downloadTemplate(){
    let data:any
    switch(this.current_table.label){
      case 'Users':
        data = new User({
          name : "User 1",
          email: "user1@example.com",
          nickname : "Fred",
          job_title : "Developer",
          latest_location : "Brisbane",
          status: "Active",
          start_date : "01/01/2022",
          employment_type: "Contractor",
          // role_id : "Employee"
        })
        data.manager_email = "manager@example.com"
        data.supplier_name = "Supplier 1"
        data.roles = "Administrator, Timesheet Administrator"
        break;
      case 'Organisations':
        data = new Organisation({})
        break;
      case 'Projects':
        data = new Project({})
        data.client_name = null
        data.manager_email = null
        data.timesheet_approver_email = null
        break;
      case 'Tasks':
        data = new Task({})
        data.project_number = null
        break;
      case 'Assignments':
        data = new Assignment({})
        data.project_number = null
        data.employee_email = null
        delete data.employee_id
        break;
      case 'Time Entries':
        data = new TimeEntry({})
        delete data.task_id
        data.status = null
        data.task_name = null
        data.employee_email = null
        data.project_number = null
        break;
    }

    this.exportService.exportExcel([data], `template-${this.current_table.label.toLowerCase()}-data`)
  }


}
