import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {

  constructor(
    private breadcrumbService:BreadcrumbService) {
      this.breadcrumbService.setItems([
        { label: 'Account Settings'},
        { label: 'Tenant Settings',routerLink:['/admin/tenant-settings/general']},
        { label: 'System' }
      ]); }

  ngOnInit(): void {
  }

}
