import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { AppComponent } from 'src/app/app.component';
import { Auth0Role } from 'src/app/model/Auth0Role';
import { User } from 'src/app/model/User';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { DialogUserDetailsComponent } from '../dialog-user-details/dialog-user-details.component';
import { ActiveStatus } from 'src/app/model/ActiveStatus';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: User[]=[]
  selectedUsers: User[]=[]
  //roles: Auth0Role[]=[]
  new_user : User = new User({})
  auth0Ext_token: string=""
  auth0_token: string="";
  currentUser:User;
  ref:DynamicDialogRef;

  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  loading:boolean=false

  cols: any[]= [
    { field: 'name', header: 'Name', filter: true},
    { field: 'email', header: 'Email', filter: true },
    { field: 'job_title', header: 'Job Title', filter: true},
    { field: 'status', header: 'Status', filter: true},
    // { field: 'Role', header: 'Access Type', nestedField : 'label' },
  ];

  constructor(public tenantService: TenantService, public exportService:ExportService, private userService:UserService,
    public messageService:MessageService, public dialogService: DialogService, 
    private breadcrumbService:BreadcrumbService, public timesheetService:TimesheetService){
      this.breadcrumbService.setItems([
        { label: 'Account Settings'},
        { label: 'User Management',routerLink:['admin/users']},
        { label: 'Users' }
      ]); }

 ngOnInit(): void {
    this.canEdit = this.userService.checkPermission('Users', 'edit')
    this.canAdd = this.userService.checkPermission('Users', 'add')
    this.canDelete = this.userService.checkPermission('Users', 'delete')
    this.updateUsers()
    this.currentUser = this.userService.currentUser
  }

  updateUsers(){
    this.loading = true
    this.userService.getAllUsers().subscribe(data=>{
      this.users=data
      this.loading = false
      console.log(this.users)
    })
  }
  getCompleteFields(){
    return this.cols.map(el=>el.field)
  }

  addUser(){
    this.ref = this.dialogService.open(DialogUserDetailsComponent, {
      header: 'Add a User',
      width: '70%'
    });
    this.ref.onClose.subscribe((user: User) =>{
      if (user) {
        this.users.push(user)
        this.messageService.add({severity:'success', summary: 'User Created', detail: user.name});
      }
    });
  }

  editUser(user:User){
    //console.log("editing user",user)
    user.start_date = new Date(user.start_date)
    if (user.end_date) user.end_date = new Date(user.end_date)
    this.ref = this.dialogService.open(DialogUserDetailsComponent, {
      header: 'User Details',
      width: '70%',
      data: {
        user:user
      }
    });
    this.ref.onClose.subscribe((updated_user: User) =>{
      if (updated_user) {
        var index = this.users.indexOf(user);
        this.users[index]=updated_user;
        this.messageService.add({severity:'success', summary: 'User Updated', detail: user.name});
      }
    });
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.users, "Users")
}

  exportExcel() {
    this.exportService.exportExcel(this.users)
  }

  clear(table: Table) {
    table.clear();
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  setAsActive(){
    this.updateStatus(ActiveStatus.Active)
  }
  setAsInactive(){
    this.updateStatus(ActiveStatus.Inactive)
  }

  updateStatus(status:ActiveStatus){
    let promises = this.selectedUsers.map(user=>{
      user.status = status
      return new Promise(resolve => this.userService.updateUser(user).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Task(s) successfully updated'})) 
  }

  delete(){
    let promises = this.selectedUsers.map(user=>{
      return new Promise(resolve => this.userService.deleteUser(user).subscribe(data=>resolve(data)))
    })
    Promise.all(promises).then(data => this.messageService.add({severity: 'success', summary: 'Task(s) successfully deleted'})) 
  }
}
