// prod environment
const envName = "dev"
const apiUrl = `https://api-${envName}.ecs.app`
const loginDomain = "ecs-dev.au.auth0.com"
const audience =  "https://ecs-dev.au.auth0.com/api/v2/"
const clientId = "6XFhVrd6EA0d8AiVwPCDEykhxdG4zXFE"

export const environment = {
  apiUrl:apiUrl,
  production: false,
  auth: {
    domain:loginDomain,
    clientId: clientId,
    audience: audience,
    redirectUri: window.location.origin,
  },
  httpInterceptor: {
    allowedList: [{
      "uri": apiUrl+"*",
      "tokenOptions": {
        "audience": audience,
        "scope": ""
      }
    }],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.