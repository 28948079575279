import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Project } from 'src/app/model/Project';
import { Report } from 'src/app/model/Report';
import { ReportType } from 'src/app/model/ReportType';
import { TimesheetApproval, TimesheetApprovalStatus } from 'src/app/model/TimesheetApproval';
import { TimesheetTemplate } from 'src/app/model/TimesheetTemplate';
import { User, EmploymentType } from 'src/app/model/User';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-batch-print',
  templateUrl: './batch-print.component.html',
  styleUrls: ['./batch-print.component.scss']
})
export class BatchPrintComponent implements OnInit {

  reportType:ReportType = new ReportType({});
  report:Report = new Report({});
  dateFrom:string = '';
  dateTo:string = '';
  projects:Project[]=[]
  selectedProject:Project[]=[];
  staff:User[] = [];
  selectedStaff:User[] = [];
  staffTypes: EmploymentType[] = Object.values(EmploymentType)
  selectedStaffType: EmploymentType[] = null;
  currentUser:User;
  approvedTimesheetOnly:boolean = true;

  selectedTemplate: TimesheetTemplate;
  templates: TimesheetTemplate[]=[]

  // preview configuration
  compiledData=[]
  cols =[]
  groupBy:string=""
  groupMode: string = "subheader"
  sortBy:string=""
  tableByTsApproval:boolean=false;

  displayStaffDropdown:boolean = true
  displayStaffTypeDropdown:boolean = true
  displayProjectDropdown:boolean = true
  displayPreview:boolean = false
  
  constructor(public timesheetService:TimesheetService,public exportService:ExportService, public userService:UserService, 
    private router: Router, private breadcrumbService:BreadcrumbService, public tenantService:TenantService) {
    this.breadcrumbService.setItems([
        { label: 'Batch Print',routerLink: ['/batch-print'] }
      ]);
    }

  ngOnInit(): void {
    this.currentUser = this.userService.currentUser
    this.userService.getAllUsers().subscribe(data=>{
      // console.log(data);
      this.staff=data
    })
    this.timesheetService.getAllProjects().subscribe(data=>{
      this.projects=data
    })
    this.timesheetService.getAllTemplates().subscribe(data=>{
      this.templates = data
    })
    this.tableByTsApproval = true
    this.sortBy = "date"
    this.groupBy = ""
    this.groupMode = ""
    this.displayProjectDropdown = true
    this.cols = [
      {label:"Employee Id", field:"employee_id", toPrint:false},
      {label:"Project Id", field:"project_id", toPrint:false},
      {label:"Employee", field:"employee", toPrint:false},
      {label:"Project", field:"project", toPrint:false},
      {label:"Date", field:"date", toPrint:true},
      {label:"Hours", field:"hours", toPrint:true},
      {label:"Task", field:"Task", nestedField:"task_name", toPrint:true},
      {label:"Description", field:"description", toPrint:true}
    ]
  }

  calculateFields(tsApp:TimesheetApproval[]):any[]{
    let processedData = []
        
      //case 'Project Timesheet Summary':
        for (let ts of tsApp){
          let index = processedData.findIndex(el=>el.Project.id==ts.Assignment.project_id && el.Employee.id == ts.Assignment.employee_id)
          if (index==-1){
            ts.TimeEntries.map(te=>te.TimesheetApproval=ts)
            let new_data = {
              Employee:ts.Assignment.Employee,
              Project:ts.Assignment.Project,
              time_entries: ts.TimeEntries, 
              submitted_by: ts.Submitter.name,
              submitted_at: ts.submitted_at
            }
            processedData.push(new_data)
          } else {
            processedData[index].time_entries = [...processedData[index].time_entries,...ts.TimeEntries]
            if (ts.submitted_at>=processedData[index].submitted_at){
              processedData[index].time_entries.submitted_at = ts.submitted_at
              processedData[index].time_entries.submitted_by = ts.Submitter?ts.Submitter.name:""
            }
          }
        } 
    return processedData
  }

  getNbCols(){
    return this.cols.filter(col=>col.toPrint==true).length
  }

  getFooter(projEmp){
    let footer_db = projEmp.Project.TimesheetTemplate.footer
    return footer_db.replaceAll("[CompanyName]", this.tenantService.currentTenant.company)
  }

  updateData(){
    this.compiledData=[]
    this.timesheetService.getTimesheetsBetweenDates(moment(this.dateFrom),moment(this.dateTo)).subscribe(data=>{
      console.log("raw data",data)
      // filter
      let filteredData = data
      if (this.selectedProject.length>0){filteredData = filteredData.filter(tsApp=>this.selectedProject.some(proj=>proj.id==tsApp.Assignment.project_id))}
      if (this.selectedStaff.length>0){filteredData = filteredData.filter(tsApp=>this.selectedStaff.some(emp=>emp.id==tsApp.Assignment.employee_id))}
      if (this.approvedTimesheetOnly){filteredData = filteredData.filter(tsApp=>tsApp.status==TimesheetApprovalStatus.Approved)}
      if (this.selectedStaffType.length>0){filteredData = filteredData.filter(tsApp=>this.selectedStaffType.some(type=>tsApp.Assignment.Employee.employment_type==type))}
      let calculatedData = this.calculateFields(filteredData)
      this.compiledData = [ ...this.compiledData, ...calculatedData];
      console.log("compiled data",this.compiledData)
    })
  }

  exportPdf() {
    let exportColumns = this.cols.filter(col=> col.toPrint).map(col => ({title: col.label, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.compiledData)
  }

  display(){
    this.updateData()
    this.displayPreview = true;
  }

  exportExcel() {
    this.exportService.exportExcel(this.compiledData)
  }
}