<div class="grid dashboard">
    <div class="col-12 md:col-4 lg:col-4">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-folder"></i>
                <h6 class="m-0" [ngClass]="{'pl-2': !app.isRTL, 'pr-2': app.isRTL}">Assignments</h6>
                <div [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">
                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 100px">
                    <span class="mb-1 fs-large">{{currentAssignments.length}}</span>
                    <span class="overview-status p-1 teal-bgcolor fs-xsmall">{{numberClients}} client{{numberClients>1?'s':''}}</span>
                </div>
                <div class="flex align-items-end">
                    <p-chart type="bar" [data]="overviewChartAssignments" [options]="overviewChartOptions1" responsive="true" height="60px" width="350px"></p-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4 lg:col-4">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-clock"></i>
                <h6 class="m-0" [ngClass]="{'pl-2': !app.isRTL, 'pr-2': app.isRTL}">Total Hours</h6>
                <div [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">
                    <!-- <button pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" (click)="menu3.toggle($event)"></button>
                    <p-menu #menu3 [popup]="true" [model]="[{label: 'Update', icon: 'pi pi-fw pi-refresh'},{label: 'Edit', icon: 'pi pi-fw pi-pencil'}]"></p-menu> -->
                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 100px">
                    <span class="mb-1 fs-large">{{getTotalSum()}}h</span>
                    <span class="overview-status p-1 pink-bgcolor fs-xsmall">{{numberOfHoursArray[numberOfHoursArray.length-1]}}h this month</span>
                </div>
                <div class="flex align-items-end">
                    <p-chart type="bar" [data]="overviewChartHours" [options]="overviewChartOptions1" responsive="true" height="60px" width="350px"></p-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4 lg:col-4">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-clock"></i>
                <h6 class="m-0" [ngClass]="{'pl-2': !app.isRTL, 'pr-2': app.isRTL}">Last Notifications</h6>
            </div>
            <div class="flex justify-content-between mt-2 flex-wrap">
                <ul class="widget-list" *ngFor="let notif of timesheetNotifications">
                    <li class="flex align-items-center">
                        <div class="person-item flex align-items-center">
                            <div [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
                                <div>{{notif.type}}</div>
                                <small class="muted-text">{{notif.message.substring(0,70) + ' ...'}}</small>
                            </div>
                        </div>
                        <span class="person-tag blue-bgcolor p-1 fs-small" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">{{notif.date |date: tenantService.timesheetSettings.settings_json.date_format}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-12 lg:col-4">
        <div class="card height-100">
            <div class="card-header">
                <h5>Projects</h5>
                <div>
                    <!-- <button pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" (click)="menu5.toggle($event)"></button>
                    <p-menu #menu5 [popup]="true" [model]="[{label: 'New', icon: 'pi pi-fw pi-plus'},{label: 'Edit', icon: 'pi pi-fw pi-pencil'},{label: 'Delete', icon: 'pi pi-fw pi-trash'}]"></p-menu> -->
                </div>
            </div>

            <ul class="widget-list" *ngFor="let ass of allAssignments">
                <li class="flex align-items-center py-3">
                    <div class="person-item flex align-items-center">
                        <div [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
                            <div>{{ass.Project.project_number}} - {{ass.Project.project_name}}</div>
                            <small class="muted-text">{{ass.position_name}}</small>
                        </div>
                    </div>
                    <span *ngIf = "isAssignmentActive(ass)" class="person-tag green-bgcolor p-1 fs-small" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">Active</span>
                    <span *ngIf = "!isAssignmentActive(ass)" class="person-tag bluegrey-bgcolor p-1 fs-small" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}">Inactive</span>
                    <span *ngIf = "ass.end_date!=null" class="person-tag orange-bgcolor p-1 fs-small" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">{{ass.start_date |date: tenantService.timesheetSettings.settings_json.date_format}} - {{ass.end_date|date: tenantService.timesheetSettings.settings_json.date_format}}</span>
                    <span *ngIf = "ass.end_date==null" class="person-tag orange-bgcolor p-1 fs-small" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">{{ass.start_date |date: tenantService.timesheetSettings.settings_json.date_format}} - Current</span>
                </li>
            </ul>
        </div>
    </div>

    <div class="col-12 lg:col-8">
        <div class="card height-50">
            <div class="card-header">
                <h5>Hours</h5>
                <div>
                    <!-- <button pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" (click)="menu6.toggle($event)"></button>
                    <p-menu #menu6 [popup]="true" [model]="[{label: 'Update', icon: 'pi pi-fw pi-refresh'},{label: 'Edit', icon: 'pi pi-fw pi-pencil'}]"></p-menu> -->
                </div>
            </div>
            <p-chart type="line" [data]="ordersChart" [options]="ordersOptions" height="300px"></p-chart>
        </div>
    </div>
</div>