<div class="pages-body accessdenied-page flex flex-column">
    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>LICENSE REQUIRED</h2>
            </div>
            <div class="card mt-3 px-6">
                <img src="assets/layout/images/pages/error.png" alt=""/>
            </div>
            <div class="pages-detail pb-6">A license is required to access to this page. Please organisation your admnistrator.</div>
            <div *ngIf = "module" class="pages-detail pb-6">Module: {{module}}</div>
            <button pButton pRipple type="button" label="GO BACK TO DASHBOARD" [routerLink]="['/']" class="p-button-text"></button>
        </div>
    </div>
</div>
