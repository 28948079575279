import { DeclarationListEmitMode } from "@angular/compiler";
import { Assignment } from "./Assignment";
import { Task } from "./Task";
import { TimesheetApproval } from "./TimesheetApproval";

export class TimeEntry {
    id:number;
    task_id: number;
    // assignment_id: number;
    hours: number;
    date: Date;
    start_time: Date;
    finish_time: Date;;
    description: string;
    tenant_id: number;

    Task: Task;
    // Assignment:Assignment;

    timesheet_approval_id: number;
    TimesheetApproval:TimesheetApproval;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(timeEntry){
        this.date = timeEntry.date || null;
        this.task_id = timeEntry.task_id|| null;
        //this.assignment_id = timeEntry.assignment_id|| null;
        this.hours = timeEntry.hours || null;
        this.description = timeEntry.description || null;
        this.start_time = timeEntry.start_time || null;
        this.finish_time = timeEntry.finish_time || null;
    }
}