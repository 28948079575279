import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-billing-record-details',
  templateUrl: './dialog-billing-record-details.component.html',
  styleUrls: ['./dialog-billing-record-details.component.scss']
})
export class DialogBillingRecordDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
