import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table/table';
import { AppComponent } from 'src/app/app.component';
import { TimesheetTemplate } from 'src/app/model/TimesheetTemplate';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { DialogTemplateDetailsComponent } from 'src/app/components/timesheet/timesheet-settings/templates/dialog-template-details/dialog-template-details.component';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  templates: TimesheetTemplate[]=[]
  ref:DynamicDialogRef;
  canAdd: boolean = false;
  canEdit: boolean = false;
  loading: boolean = false;

  cols: any[]= [
    { field: 'name', header: 'Name', filter: true},
    { field: 'is_default', header: 'Is Default', filter: true },
    { field: 'createdAt', header: 'Created', filter: true, isDate:true },
  ];
 
  constructor(private timesheetService: TimesheetService,public messageService:MessageService,
    public dialogService: DialogService, public tenantService: TenantService, public exportService:ExportService, 
    private breadcrumbService:BreadcrumbService, private userService: UserService){
      this.breadcrumbService.setItems([
        { label: 'Timesheet Settings'},
        { label: 'Templates' }
      ]); }

  ngOnInit(): void {
      this.canEdit = this.userService.checkPermission('Timesheet Settings', 'edit')
      this.canAdd = this.userService.checkPermission('Timesheet Settings', 'add')
      this.updateTemplates()
      this.cols.forEach(el=>el.completeField = el.nestedField?el.field+'.'+el.nestedField:el.field)
    }

  updateTemplates(): void {
    //this.loading = true;
    this.timesheetService.getAllTemplates().subscribe(data=>{
      this.templates=data;
      this.loading = false;
    })
  }
  
  addTemplate(){
    this.ref = this.dialogService.open(DialogTemplateDetailsComponent, {
      header: 'Add a Template',
      width: '70%'
    });
    this.ref.onClose.subscribe((template: TimesheetTemplate) =>{
      if (template) {
        //if the template is set as default, we remove the default attribute from any other template
        if (template.is_default){
          this.templates.forEach(temp=>{
            if (temp.is_default){
              temp.is_default = false
              this.timesheetService.updateTemplate(temp).subscribe(data=>temp=data)
            }
          })
        }
        this.templates.push(template)
        this.messageService.add({severity:'info', summary: 'Template Created', detail: template.name});
      }
    });
  }

  editTemplate(template:TimesheetTemplate){
    this.ref = this.dialogService.open(DialogTemplateDetailsComponent, {
      header: 'Template Details',
      width: '70%',
      data: {
        template:template
      }
    });
    this.ref.onClose.subscribe((updated_template: TimesheetTemplate) =>{
      if (updated_template) {

         //if the template is set as default, we remove the default attribute from any other template
        if (template.is_default){
          this.templates.forEach(temp=>{
            if (temp.is_default && temp.id!=updated_template.id){
              temp.is_default = false
              this.timesheetService.updateTemplate(temp).subscribe(data=>temp=data)
            }
          })
        }
        var index = this.templates.indexOf(template);
        this.templates[index]=updated_template;
        this.messageService.add({severity:'info', summary: 'Template Updated', detail: template.name});
      }
    });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  exportPdf() {
    let exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.templates)
}

  exportExcel() {
    this.exportService.exportExcel(this.templates)
  }

  clear(table: Table) {
    table.clear();
  }

  getCompleteFields(){
    return this.cols.map(el=>el.completeField)
  }

}
