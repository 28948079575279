<p-table #dt [value]="licenses" [columns] = "cols" responsiveLayout="scroll" [loading]="loading" [globalFilterFields]="getCompleteFields()"
[rows]="20" [showCurrentPageReport]="true" [rowsPerPageOptions]="[20,30,50]"  [paginator]="true" 
currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
<!-- [rowGroupMode]="subheader" [groupRowsBy]="TenantLicenses"  -->

    <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
            <div style="flex-direction: row;display: flex;">
                <h3>Licenses</h3>
            </div>
            <p-toolbar >
                <ng-template pTemplate="left">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText class="w-full" type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
                    </span>
                    <!-- <p-selectButton [options]="tenantService.tenantLicenseStatusOptions" [(ngModel)]="activeFilter" (onChange)="filterByStatus(dt)" class="ml-2"></p-selectButton> -->
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton type="button" icon="pi pi-refresh" (click)="updateLicenses()" class="p-button-primary mr-2" pTooltip="Refresh" tooltipPosition="bottom"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" >
                <span>{{col.header}}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>               
            </th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
            <td>{{rowData.name}}</td>
            <td>{{(rowData.TenantLicenses && rowData.TenantLicenses.length)?rowData.TenantLicenses[0].nb_available_licenses:0}}</td>
            <td>{{(rowData.TenantLicenses && rowData.TenantLicenses.length)?rowData.TenantLicenses[0].nb_assigned_licenses:0}}</td>
            <td>
                <button *ngIf="rowData.TenantLicenses && rowData.TenantLicenses.length" pButton type="button" (click)="editRow(rowData)" icon="pi pi-user-edit" ></button>
                <button pButton type="button" (click)="buyLicense(rowData)" class="ml-2" icon="pi pi-shopping-cart" ></button>
                <button pButton type="button" (click)="moreInfo(rowData)" class="ml-2" icon="pi pi-info" ></button>
            </td>
        </tr>
    </ng-template>
</p-table>
