import { ActiveStatus } from "./ActiveStatus";
import { Organisation } from "./Organisation";
import { TimesheetTemplate } from "./TimesheetTemplate";
import { User } from "./User";

export class Project {
    id:number;
    client_id:number;
    project_name:string;
    project_number: string;
    manager_id: number ;
    timesheet_approver_id: number;
    tenant_id: number ;
    description:string;
    notes: string;
    budget_amount:number;
    chargeable: boolean;
    reference: string;
    status: ActiveStatus;
    Client:Organisation;
    ProjectManager: User;
    TimesheetApprover: User;
    TimesheetTemplate: TimesheetTemplate;
    timesheet_template_id: number;
    timesheet_approver_name: string;
    timesheet_approver_email: string;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
  
    constructor(project){
        this.project_name=project.project_name || null;
        this.project_number = project.project_number || null;
        this.description = project.description || null;
        this.notes=project.notes || null;
        this.budget_amount = project.budget_amount || null;
        this.chargeable = project.chargeable|| null;
        this.reference = project.reference || null;
        this.status = project.status || null;
    }
}