<p-table [value]="filteredTimesheetsApprovals" responsiveLayout="scroll" [loading]="loading"
#dt responsiveLayout="scroll" [(selection)]="selectedTimesheets" dataKey="id">
    <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
            <div style="flex-direction: row;display: flex;">
                <h3>Pending Authorisations</h3>
            </div>
            <p-toolbar>
                <ng-template pTemplate="left">
                    <p-dropdown [options]="weeksArray" [(ngModel)]="selectedWeek" placeholder="Period Start Date" (onChange)="updateTimesheets()" class="mr-2">
                        <ng-template pTemplate="selectedItem">
                            <div style="display: inline-flex;">
                                <div *ngIf="isCurrentWeek(selectedWeek)" style="margin-right: 10px;"><i  style="color:rgb(74, 119, 170)" class="pi pi-calendar"></i></div>
                                <div>{{selectedWeek | date:'dd MMM YYYY'}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-week pTemplate="item">
                            <div style="display: inline-flex;">
                                <div *ngIf="isCurrentWeek(week)" style="margin-right: 10px;"><i  style="color:rgb(74, 119, 170)" class="pi pi-calendar"></i></div>
                                <div>{{week | date:'dd MMM YYYY'}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <p-dropdown [options]="managers" [(ngModel)]="selectedManager" optionLabel="name" [filter]="true" filterBy="name, email" (onChange)="filterByProjectAndManager()"
                        placeholder="Manager" class="mr-2"></p-dropdown>
                    <p-dropdown [options]="projects" [(ngModel)]="selectedProject" optionLabel="project_name" [filter]="true" filterBy="project_name, project_number" (onChange)="filterByProjectAndManager()"
                    placeholder="Project" class="mr-2"></p-dropdown>                
                </ng-template>
                <ng-template pTemplate="right">
                    <p-menu #menu [popup]="true" [model]="tableMenuItems" appendTo="body"></p-menu>
                    <button type="button" pButton icon="pi pi-bolt" label = "Actions" (click)="menu.toggle($event)" class="p-button-primary mr-2" [disabled]="selectedTimesheets.length==0"></button>
                    <button pButton type="button" icon="pi pi-refresh" (click)="updateTimesheets()"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Status</th>
            <th>Employee</th>
            <th>Manager</th>
            <th>Project</th>
            <th>Total</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tsApp>
        <tr>
            <td>
                <p-tableCheckbox [value]="tsApp"></p-tableCheckbox>
            </td>
            <td>
                <span [class]="'timesheet-status-' + tsApp.status.toLowerCase()">{{tsApp.status}}</span>
            </td>
            <td>{{tsApp.Assignment.Employee.name}}</td>
            <td>
                {{tsApp.Assignment.Project.ProjectManager.name}}
            </td>
            <td>
                {{tsApp.Assignment.Project.project_name}}
            </td>
            <td>{{getSumByEmployee(tsApp)}}</td>
            <td>
                <button (click)="seeTimesheet(tsApp)" icon="pi pi-search" pButton class="p-button-rounded p-button-text"></button>
            </td>
        </tr>
    </ng-template>
</p-table>

<!-- <div>
    <button (click)="notifySelectedUsers()" pButton label="Send Reminder" class="p-button-raised p-button-success"></button> 
</div> -->

<p-dialog header="Notification Message" [(visible)]="displayNotificationMessage" [style]="{width: '50vw'}">
    <textarea [rows]="6" [cols]="30" pInputTextarea class="w-full" [(ngModel)]="notificationMessage"></textarea>
    <i>Dynamic fields: [PeriodStartDate], [ProjectName]</i>
    <ng-template pTemplate="footer">
        <button (click)="sendEmail()" pButton label="Send Email" class="p-button-raised p-button-success"></button> 
    </ng-template>
</p-dialog>

<p-dialog header="Rejection Comment" [(visible)]="displayRejectionDialog">
    <textarea [rows]="2" [cols]="30" pInputTextarea class="w-full" [(ngModel)]="rejectionComment"></textarea>
    <ng-template pTemplate="footer">
        <button (click)="rejectTimesheet()" pButton label="Confirm rejection" class="p-button-raised p-button-danger"></button> 
    </ng-template>
</p-dialog>