import { Report } from "./Report";

export class FavouriteReport {
    id:number;
    user_id: string;
    report_id: number;
    Report:Report
    tenant_id: number ;

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(favouriteReport){
        this.id = favouriteReport.id || null;
        this.report_id = favouriteReport.report_id || null;
        this.user_id=favouriteReport.user_id || null;
    }
}