import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActiveStatus } from 'src/app/model/ActiveStatus';
import { Auth0Role } from 'src/app/model/Auth0Role';
import { Organisation } from 'src/app/model/Organisation';
import { Project } from 'src/app/model/Project';
import { TimesheetTemplate } from 'src/app/model/TimesheetTemplate';
import { User } from 'src/app/model/User';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-project-details',
  templateUrl: './dialog-project-details.component.html',
  styleUrls: ['./dialog-project-details.component.scss']
})
export class DialogProjectDetailsComponent implements OnInit {

  new_project: Project = new Project({status: ActiveStatus.Active, chargeable: true})
  createForm:FormGroup= this.fb.group({
    'id': [],
    'project_name':[,Validators.required],
    'project_number':[,Validators.required],
    'client_id':[,Validators.required],
    'manager_id':[,Validators.required],
    'description':[],
    'notes':[],
    'reference':[],
    'budget_amount':[],
    'chargeable':[,Validators.required],
    'status':[,Validators.required],
    'timesheet_template_id':[,Validators.required],
    'timesheet_approver_name':[],
    'timesheet_approver_email':[]
  })
  isNew: boolean = true
  
  users:User[]=[]
  organisations:Organisation[]=[]
  templates:TimesheetTemplate[]=[]
  // filteredUsers:User[]=[]

  statusOptions = [ActiveStatus.Active,ActiveStatus.Inactive]
  booleanOptions = [true,false]

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private fb: FormBuilder,
    private messageService: MessageService, public timesheetService:TimesheetService, public userService:UserService) {
      if (config.data && config.data.project) {
        this.new_project = this.config.data.project
        this.isNew = false
      }
      this.createForm.patchValue(this.new_project)
    }

  ngOnInit(): void {
    this.userService.getUsersByCriteria(new User({status:ActiveStatus.Active})).subscribe(data=>this.users=data)
    this.timesheetService.getOrganisationsByCriteria(new Organisation({status:ActiveStatus.Active})).subscribe(data=>this.organisations=data)
    this.timesheetService.getTemplatesByCriteria(new TimesheetTemplate({status:ActiveStatus.Active})).subscribe(data=>this.templates=data.filter(temp=>temp.status=ActiveStatus.Active))
    let canEdit = this.userService.checkPermission("Projects","edit")
    if (!canEdit){this.createForm.disable()}
  }
  cancel(){
    this.ref.close();
  }

  onSubmit(form:Project){
    if (this.createForm.valid){
      this.new_project = form
      console.log(form)

      if (this.isNew){
        this.timesheetService.createProject(form).subscribe(data=>{
          this.new_project=data
          this.ref.close(this.new_project);
        })
      }
      else {
        this.timesheetService.updateProject(this.new_project).subscribe(data=> {
          this.new_project=data
          this.ref.close(this.new_project);
        })
      }
    }
  }

  updateTimesheetApprover(){
    if (this.createForm.value.manager_id && !this.createForm.value.timesheet_approver_name){
      let manager = this.users.find(user=>user.id==this.createForm.value.manager_id)
      this.createForm.patchValue({
        timesheet_approver_name: manager.name,
        timesheet_approver_email: manager.email
      })
    }
  }

    // for mandatory fields
    get project_name() { return this.createForm.get('project_name'); }
    get project_number() { return this.createForm.get('project_number'); }
    get manager_id() { return this.createForm.get('manager_id'); }
    get client_id() { return this.createForm.get('client_id'); }
    get timesheet_template_id() { return this.createForm.get('timesheet_template_id'); }
}
