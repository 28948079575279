import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Assignment } from 'src/app/model/Assignment';
import { Project } from 'src/app/model/Project';
import { Report } from 'src/app/model/Report';
import { ReportType } from 'src/app/model/ReportType';
import { TimesheetApproval, TimesheetApprovalStatus } from 'src/app/model/TimesheetApproval';
import { TimesheetTemplate } from 'src/app/model/TimesheetTemplate';
// import { Timesheet } from 'src/app/model/TimeEntry';
import { EmploymentType, User } from 'src/app/model/User';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExportService } from 'src/app/services/export.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.scss']
})
export class ReportPreviewComponent implements OnInit {
  
  reportType:ReportType = new ReportType({});
  report:Report = new Report({});
  dateFrom:string = '';
  dateTo:string = '';
  projects:Project[]=[]
  selectedProject:Project[]=[];
  staff:User[] = [];
  selectedStaff:User[] = [];
  staffTypes: EmploymentType[] = Object.values(EmploymentType)
  selectedStaffType: EmploymentType[] = [];
  currentUser:User;
  approvedTimesheetOnly:boolean = true;

  selectedTemplate: TimesheetTemplate;
  templates: TimesheetTemplate[]=[]

  // preview configuration
  compiledData=[]
  cols =[]
  groupBy:string=""
  groupMode: string = "subheader"
  sortBy:string=""
  tableByTsApproval:boolean=false;

  displayStaffDropdown:boolean = true
  displayStaffTypeDropdown:boolean = true
  displayProjectDropdown:boolean = true
  displayPreview:boolean = false
  
  constructor(public timesheetService:TimesheetService,public exportService:ExportService, public userService:UserService, 
    private router: Router, private breadcrumbService:BreadcrumbService, public tenantService:TenantService,
    public utilsService: UtilsService) {
    let navigation = this.router.getCurrentNavigation()
    if (navigation.extras.state && navigation.extras.state.report) this.report = navigation.extras.state.report
    else this.router.navigate(['/reports'])  
    this.breadcrumbService.setItems([
        { label: 'Reports',routerLink: ['/reports'] },
        { label: this.report.report_name }
      ]);
    }

  ngOnInit(): void {
    this.currentUser = this.userService.currentUser
    this.userService.getAllUsers().subscribe(data=>{
      // console.log(data);
      this.staff=data
    })
    this.timesheetService.getAllProjects().subscribe(data=>{
      this.projects=data
    })
    this.timesheetService.getAllTemplates().subscribe(data=>{
      this.templates = data
    })

    switch (this.report.report_name){
      case 'Staff Cost Report':
        this.groupBy = "user"
        this.groupMode = "rowspan"
        this.sortBy = "project"
        this.displayStaffDropdown = true
        this.cols = [
          {label:"Assignment Id", field:"assignment_id", toPrint:false},
          {label:"Employee", field:"user", toPrint:false},
          {label:"Project", field:"project", toPrint:true},
          {label:"Total Hours", field:"total_hours", toPrint:true},
          {label:"Charge Rate (ex GST)", field:"charge_rate", toPrint:true, isCurrency:true},
          {label:"Charge Amount (ex GST)", field:"charge_amount", toPrint:true, isCurrency:true},
          {label:"Cost Rate (ex GST)", field:"cost_rate", toPrint:true, isCurrency:true},
          {label:"Cost Amount (ex GST)", field:"cost_amount", toPrint:true, isCurrency:true},
          {label:"Gross Profit (ex GST)", field:"gross_profit", toPrint:true, isCurrency:true}
        ]
        break;
      case 'Staff Detail Cost Report':
        this.groupBy = "user"
        this.sortBy = "date"
        this.groupMode = "subheader"
        this.displayStaffDropdown = true
        this.cols = [
          {label:"Te Id", field:"te_id", toPrint:false},
          {label:"Employee", field:"user", toPrint:false},
          {label:"Date", field:"date", toPrint:true},
          {label:"Project", field:"project", toPrint:true},
          {label:"Hours", field:"hours", toPrint:true},
          {label:"Task", field:"task", toPrint:true},
          {label:"Description", field:"description", toPrint:true},
          {label:"Sale Value (ex GST)", field:"charge_amount", toPrint:true, isCurrency:true},
          {label:"Cost Value (ex GST)", field:"cost_amount", toPrint:true, isCurrency:true},
          {label:"Profit (ex GST)", field:"gross_profit", toPrint:true, isCurrency:true},
          {label:"Status", field:"status", toPrint:true},
        ]
        break;
      case 'Project Cost Report':
        this.sortBy = "user"
        this.groupBy = "project"
        this.groupMode = "subheader"
        this.displayProjectDropdown = true
        this.cols = [
          {label:"Assignment Id", field:"assignment_id", toPrint:false},
          {label:"Project", field:"project", toPrint:false},
          {label:"Employee", field:"user", toPrint:true},
          {label:"Total Hours", field:"total_hours", toPrint:true},
          {label:"Charge Rate (ex GST)", field:"charge_rate", toPrint:true, isCurrency:true},
          {label:"Charge Amount (ex GST)", field:"charge_amount", toPrint:true, isCurrency:true},
          {label:"Cost Rate (ex GST)", field:"cost_rate", toPrint:true, isCurrency:true},
          {label:"Cost Amount (ex GST)", field:"cost_amount", toPrint:true, isCurrency:true},
          {label:"Gross Profit (ex GST)", field:"gross_profit", toPrint:true, isCurrency:true}
        ]
        break;
      case 'Project Cost Detail Report':
        this.groupBy = "project"
        this.groupMode = "subheader"
        this.sortBy = "date"
        this.displayProjectDropdown = true
        this.cols = [
          {label:"Te Id", field:"te_id", toPrint:false},
          {label:"Project", field:"project", toPrint:false},
          {label:"Employee", field:"user", toPrint:true},
          {label:"Date", field:"date", toPrint:true},
          {label:"Hours", field:"hours", toPrint:true},
          {label:"Task", field:"task", toPrint:true},
          {label:"Description", field:"description", toPrint:true},
          {label:"Sale Value (ex GST)", field:"charge_amount", toPrint:true, isCurrency:true},
          {label:"Cost Value (ex GST)", field:"cost_amount", toPrint:true, isCurrency:true},
          {label:"Profit (ex GST)", field:"gross_profit", toPrint:true, isCurrency:true},
          {label:"Status", field:"status", toPrint:true},
        ]
        break;
      case 'Project Task Report':
          this.groupBy = "project"
          this.groupMode = "subheader"
          this.sortBy = "task"
          this.displayProjectDropdown = true
          this.cols = [
            {label:"Project Id", field:"project_id", toPrint:false},
            {label:"Task Id", field:"task_id", toPrint:false},
            {label:"Task", field:"task", toPrint:true},
            {label:"Hours", field:"hours", toPrint:true},
            {label:"Charge Amount (ex GST)", field:"charge_amount", toPrint:true, isCurrency:true},
            {label:"Cost Amount (ex GST)", field:"cost_amount", toPrint:true, isCurrency:true},
            {label:"Gross Profit (ex GST)", field:"gross_profit", toPrint:true, isCurrency:true},
            {label:"Status", field:"status", toPrint:true},
          ]
          break;
      case 'Timesheet Client Approval':
        this.tableByTsApproval = true
        this.sortBy = "date"
        this.groupBy = ""
        this.groupMode = ""
        this.displayProjectDropdown = true
        this.cols = [
          {label:"Employee Id", field:"employee_id", toPrint:false},
          {label:"Project Id", field:"project_id", toPrint:false},
          {label:"Employee", field:"employee", toPrint:false},
          {label:"Project", field:"project", toPrint:false},
          {label:"Date", field:"date", toPrint:true},
          {label:"Hours", field:"hours", toPrint:true},
          {label:"Task", field:"Task", nestedField:"task_name", toPrint:true},
          {label:"Description", field:"description", toPrint:true}
        ]
      }  
  }

  calculateFields(tsApp:TimesheetApproval[]):any[]{
    let processedData = []
    
    switch (this.report.report_name){
      case 'Staff Cost Report' :
      case 'Project Cost Report':
        for (let ts of tsApp){
            let index = processedData.findIndex(el=>el.assignment_id==ts.assignment_id)
            let hours = ts.TimeEntries.reduce((prev,cur)=>{return prev+cur.hours},0)
            if (index==-1){
              let new_data = {
                assignment_id: ts.assignment_id,
                user:ts.Assignment.Employee.name,
                project:ts.Assignment.Project.project_name,
                total_hours: hours,
                charge_rate:ts.Assignment.charge_rate,
                cost_rate:ts.Assignment.cost_rate,
                charge_amount:ts.Assignment.Project.chargeable?hours*ts.Assignment.charge_rate:0,
                cost_amount:hours*ts.Assignment.cost_rate,
              }
              new_data["gross_profit"] = new_data.charge_amount - new_data.cost_amount
              processedData.push(new_data)
            } else {
              processedData[index].total_hours+=hours
              processedData[index].charge_amount+=ts.Assignment.Project.chargeable?hours*ts.Assignment.charge_rate:0
              processedData[index].cost_amount+=hours*ts.Assignment.cost_rate
              processedData[index].gross_profit = processedData[index].charge_amount - processedData[index].cost_amount
            }
          // }
        }
        break;
      case 'Staff Detail Cost Report':
      case 'Project Cost Detail Report' :
        for (let ts of tsApp){
          for (let te of ts.TimeEntries){
              let new_data = {
                te_id:te.id,
                date:te.date,
                user:ts.Assignment.Employee.name,
                project:ts.Assignment.Project.project_name,
                hours: te.hours,
                task:te.Task.task_name,
                description: te.description,
                charge_amount:ts.Assignment.Project.chargeable?te.hours*ts.Assignment.charge_rate:0,
                cost_amount:te.hours*ts.Assignment.cost_rate,
                status: ts.status
              }
              new_data["gross_profit"] = new_data.charge_amount - new_data.cost_amount
              processedData.push(new_data)
          }
        }
        break;            
      case 'Project Task Report':
        for (let ts of tsApp){
          for (let te of ts.TimeEntries){
            let index = processedData.findIndex(el=>el.task_id==te.task_id)
            if (index==-1){
              let new_data = {
                project_id: te.Task.project_id,
                task_id:te.task_id,
                task:te.Task.task_name,
                hours: te.hours,
                charge_rate:ts.Assignment.charge_rate,
                cost_rate:ts.Assignment.cost_rate,
                charge_amount:  ts.Assignment.Project.chargeable?te.hours*ts.Assignment.charge_rate:0,
                cost_amount:  te.hours*ts.Assignment.cost_rate,
              }
              new_data["gross_profit"] = new_data.charge_amount - new_data.cost_amount
              processedData.push(new_data)
            } else {
              processedData[index].hours+=te.hours
              processedData[index].charge_amount+=te.hours*ts.Assignment.charge_rate
              processedData[index].cost_amount+=te.hours*ts.Assignment.cost_rate
              processedData[index].gross_profit = processedData[index].charge_amount - processedData[index].cost_amount
            }
          // }
          }
      }
      break;         
      case 'Timesheet Client Approval':
        for (let ts of tsApp){
          let index = processedData.findIndex(el=>el.Project.id==ts.Assignment.project_id && el.Employee.id == ts.Assignment.employee_id)
          if (index==-1){
            ts.TimeEntries.map(te=>te.TimesheetApproval=ts)
            let new_data = {
              Employee:ts.Assignment.Employee,
              Project:ts.Assignment.Project,
              time_entries: ts.TimeEntries, 
              submitted_by: ts.Submitter?ts.Submitter.name:"",
              submitted_at: ts.submitted_at
            }
            processedData.push(new_data)
          } else {
            processedData[index].time_entries = [...processedData[index].time_entries,...ts.TimeEntries]
            if (ts.submitted_at>=processedData[index].submitted_at){
              processedData[index].time_entries.submitted_at = ts.submitted_at
              processedData[index].time_entries.submitted_by = ts.Submitter?ts.Submitter.name:""
            }
          }
        } 
      default:
        console.log("no corresponding report name")
    }   
    return processedData
  }
  getNbCols(){
    let nbCols = this.cols.filter(col=>col.toPrint==true).length
    if (this.groupMode=="rowspan") nbCols++
    return nbCols
  }
  getFooter(projEmp){
    let footer_db = projEmp.Project.TimesheetTemplate.footer
    return footer_db.replaceAll("[CompanyName]", this.tenantService.currentTenant.company)
  }

  updateData(){
    this.compiledData=[]
    this.timesheetService.getTimesheetsBetweenDates(this.dateFrom?moment(this.dateFrom):null,this.dateTo?moment(this.dateTo):null).subscribe(data=>{
      console.log("raw data",data)
      // filter
      let filteredData = data
      if (this.selectedProject.length>0){filteredData = filteredData.filter(tsApp=>this.selectedProject.some(proj=>proj.id==tsApp.Assignment.project_id))}
      if (this.selectedStaff.length>0){filteredData = filteredData.filter(tsApp=>this.selectedStaff.some(emp=>emp.id==tsApp.Assignment.employee_id))}
      if (this.approvedTimesheetOnly){filteredData = filteredData.filter(tsApp=>tsApp.status==TimesheetApprovalStatus.Approved)}
      if (this.selectedStaffType.length>0){filteredData = filteredData.filter(tsApp=>this.selectedStaffType.some(type=>tsApp.Assignment.Employee.employment_type==type))}
      let calculatedData = this.calculateFields(filteredData)
      this.compiledData = [ ...this.compiledData, ...calculatedData];
      console.log("compiled data",this.compiledData)
    })
  }

  exportPdf() {
    let exportColumns = this.cols.filter(col=> col.toPrint).map(col => ({title: col.label, dataKey: col.field}));
    this.exportService.exportPdfWithLogo(exportColumns,this.compiledData)
  }

  display(){
    this.updateData()
    this.displayPreview = true;
  }
  exportExcel() {
    this.exportService.exportExcel(this.compiledData)
  }
}
